import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  Children,
} from "react";
import "./NewTraveler.css";
import * as PortalModels from "../../../Contexts/Models";
import { useConst } from "@fluentui/react-hooks";
import {
  createTheme,
  TextField,
  Stack,
  IStackProps,
  IStackStyles,
  Dropdown,
  mergeStyles,
  mergeStyleSets,
  IDropdownStyles,
  IDropdownOption,
  PrimaryButton,
  Text,
  Dialog,
  DialogType,
  DialogFooter,
  Callout,
  DefaultButton,
  DatePicker,
  defaultDatePickerStrings,
  IDatePicker,
  MaskedTextField,
  Label,
  ITextFieldProps,
  ThemeProvider,
  IStackTokens,
  IButtonStyles,
  IIconStyles,
  IconButton,
  initializeIcons,
  Toggle,
  IComboBoxOption,
  ComboBox,
  ResponsiveMode,
  TooltipHost,
  IComboBoxStyles,
  Separator,
  StackItem,
  IDatePickerStrings,
  KeyCodes,
  SelectableOptionMenuItemType,
} from "@fluentui/react";
import { Console, debug } from "console";
import { debuglog, isBoolean } from "util";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { CountryOptions, CountryISO3 } from "./CountryOptions";
import PassportImage from "./DisplayImage";
import { FrequentFlyPanel } from "../FrequentPanel";
import { AirlineDetailsList } from "../FrequentList";

import moment, { Moment } from "moment-timezone";
const { DateTime } = require("luxon");

//to create new profiles

export interface INewTravelerProps {
  onSaveTraveler(traveler: PortalModels.TravelerProfiles): void;
  page: number;
  onIncreasePage: any;
  onDecreasePage: any;
  editTraveler?: PortalModels.TravelerProfiles;
  mealGuestOptions?: IComboBoxOption[];
  wheelchairGuestOptions?: IComboBoxOption[];
  frequentGuestOptions?: IComboBoxOption[];
  autoTimerStart: boolean;
}

export interface IFlyerPanelProps {
  token: string;
}

export interface FrequentFlyPanelState {
  isFlyerOpen: boolean;
  openFlyerAsAdd: boolean;
}

export interface INewTravelerState {
  isCalloutVisible: boolean;
}
export interface ITextFieldCustomRenderExampleState {
  isCalloutVisible: boolean;
}

const travelPanelTheme = createTheme({
  fonts: {
    small: {
      fontSize: "10px",
      fontWeight: "semibold",
    },
    medium: {
      fontSize: "17px",
      fontWeight: "semibold",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },

  palette: {
    themePrimary: "#454545",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#b39e51",
    themeDark: "#918e2a",
    themeDarker: "#004578",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#323130",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    bodyText: "#323130",
  },
});

const onFormatDate = (date?: any): string => {
  if (!date) {
    return date;
  } else {
    return (
      date?.getMonth() + 1 + "/" + date?.getDate() + "/" + date?.getFullYear()
    );
  }
};

const onFormatPassDate = (date?: any): string => {
  if (!date) {
    return "";
  } else {
    return date?.getMonth() + 1 + "/" + date?.getFullYear();
  }
};

initializeIcons();

const stackTokens: IStackTokens = {
  childrenGap: 4,
  maxWidth: 300,
};

const labelCalloutStackStyles: Partial<IStackStyles> = {
  root: { padding: 20 },
};
const iconButtonStyles: Partial<IButtonStyles> = {
  root: { marginBottom: -3, color: "blue" },
};
const iconProps = { iconName: "Font" };

const classNames = mergeStyleSets({
  deepSkyBlue: [{ color: "#b39e51" }],
});
const CustomLabel = (props: ITextFieldProps): JSX.Element => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const descriptionId: string = useId("description");
  const iconButtonId: string = useId("iconButton");

  return (
    <>
      <ThemeProvider theme={travelPanelTheme}>
        <Stack horizontal verticalAlign="center" tokens={stackTokens}>
          <Label id={props.id}>
            {props.label}
            {props.required ? (
              <span style={{ color: "rgb(164, 38, 44)" }}>*</span>
            ) : (
              ""
            )}
          </Label>
          <IconButton
            id={iconButtonId}
            iconProps={iconProps}
            title="Format"
            ariaLabel="Format"
            onClick={toggleIsCalloutVisible}
            className={classNames.deepSkyBlue}
          />
        </Stack>
        {isCalloutVisible && (
          <Callout
            target={"#" + iconButtonId}
            setInitialFocus
            onDismiss={toggleIsCalloutVisible}
            ariaDescribedBy={descriptionId}
            role="alertdialog"
          >
            <Stack
              tokens={stackTokens}
              horizontalAlign="start"
              styles={labelCalloutStackStyles}
            >
              <span id={descriptionId}>
                Name must match passport. If your surname consists of multiple
                names, please enter all of them.
                <p />
              </span>
              <DefaultButton onClick={toggleIsCalloutVisible}>
                Close
              </DefaultButton>
            </Stack>
          </Callout>
        )}
      </ThemeProvider>
    </>
  );
};

const NewTraveler = (props: INewTravelerProps & ITextFieldProps) => {
  const [enteredTravelerInfo, setTravelerInfo] =
    useState<PortalModels.TravelerProfiles>(
      props.editTraveler
        ? {
            ...props.editTraveler,
            dateOfBirth: props.editTraveler?.dateOfBirth
              ? new Date(props.editTraveler?.dateOfBirth)
              : undefined,
            passportExpiration: props.editTraveler?.passportExpiration
              ? new Date(props.editTraveler?.passportExpiration)
              : undefined,
            mealOther: props.editTraveler?.mealOther ?? undefined,
            wheelchair: props.editTraveler?.wheelchair ?? undefined,
            frequentGuestInfo: props.editTraveler?.frequentGuestInfo ?? [],
          }
        : {
            id: "",
            firstName: "",
            middleName: "",
            lastName: "",
            dateOfBirth: undefined,
            gender: undefined,
            email: "",
            telephone: "",
            citizenship: "",
            passportNumber: "",
            passportIssuingCountry: "",
            passportExpiration: undefined,
            globalEntry: "",
            redressNumber: "",
            knownTraveler: "",
            tsaPrecheck: "",
            preferredAirport: "",
            preferredClassOfService: undefined,
            preferredSeating: undefined,
            seatingRequests: "",
            meals: undefined,
            mealOther: "",
            wheelchair: "",
            preferredRoomType: "",
            preferredBedType: "",
            htlSpecialRequests: "",
            carTypePreference: "",
            carSpecialRequests: "",
            frequentGuestInfo: [],
            dateOfBirthText: "",
          }
    );

    //styles and formats
  const stackStyles: Partial<IStackStyles> = {
    root: [{ width: 700, height: "100%", padding: 25, display: "block" }],
  };

  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 2 },
    styles: { root: [{ width: "80%", padding: 2 }] },
  };

  const flightTokens: Partial<IStackProps> = {
    tokens: { childrenGap: 10 },

    styles: { root: [{ width: "80%", padding: 10 }] },
  };
  const buttonSpacing: IStackTokens = {
    childrenGap: "20%",
    padding: "m 70px",
  };

  const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 } };
  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 200 },
  };

  const labelId: string = useId("label");

  const options: IDropdownOption[] = [
    { key: 1, text: "Male" },
    { key: 2, text: "Female" },
    { key: 283240002, text: "Unspecified" },
    { key: 283240001, text: "Undisclosed" },
  ];

  const maskFormat: { [key: string]: RegExp } = {
    "9": /[0-9]/,
  };


  //auto timer  
  const [autoSubmitTimer, setAutoSubmitTimer] = useState(false);

  const autoSubmitFunc = (props: INewTravelerProps) => {
    setAutoSubmitTimer(true);
    props.autoTimerStart = autoSubmitTimer;
  };

  //handlers

  const handleChange = (event: any) => {
    console.log(`${event.target.name}: ${event.target.value}`);

    setTravelerInfo({
      ...enteredTravelerInfo,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  const handleSubmit = (event: any) => {
    event?.preventDefault(); //prevents the submit button from refreshing the page
    setAutoSubmitTimer(true);
    setTravelerInfo({
      id: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: undefined,
      gender: undefined,
      email: "",
      telephone: "",
      citizenship: "",
      passportNumber: "",
      passportIssuingCountry: "",
      passportExpiration: undefined,
      globalEntry: "",
      redressNumber: "",
      knownTraveler: "",
      tsaPrecheck: "",
      preferredAirport: "", //default?
      preferredClassOfService: undefined, //default?
      preferredSeating: undefined, //default?
      seatingRequests: "",
      meals: undefined, //default?
      mealOther: "",
      wheelchair: "",
      preferredRoomType: "",
      preferredBedType: "",
      htlSpecialRequests: "",
      carTypePreference: "",
      carSpecialRequests: "",
      frequentGuestInfo: [],
    });
  };

  var ScreenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  var screencheck = ScreenWidth <= 600 ? ScreenWidth - 30 : "";

  const handleIncreasePage = () => {
    props.onIncreasePage();
  };
  const handleDecreasePage = () => {
    props.onDecreasePage();
  };
  

  
  //country

  const [isToggled, { setTrue: showISOCode, setFalse: showCountryName }] =
    useBoolean(false);

  const getCountry = (): IComboBoxOption[] => {
    if (!isToggled) {
      return CountryOptions ?? [];
    } else if (CountryISO3) {
      return CountryISO3.map((x) => {
        return { key: x.key as string, text: x.text as string };
      });
    } else return [];
  };

 //airline info
  const [enteredAirline, setEnteredAirline] = useState<any | null>(null);
  const [enteredAirNumber, setEnteredAirNumber] = useState("");
  const [isInvalidInput, setIsInvalidInput] = useState(false);
  const [selectedAirline,setSelectedAirline]=useState(null);
  const [inputValue, setInputValue] = useState<string>('');

  const newHeader={ key: 'Header1', text: '', itemType: SelectableOptionMenuItemType.Divider }

  const frequentGuestOptions = props.frequentGuestOptions ? [newHeader, ...props.frequentGuestOptions] : [newHeader];

  const airlineChangeHandler = (event: any, index: any, option:any) => {
    if (!option){
      setEnteredAirline('');
      setInputValue('');
    }
    else{setEnteredAirline(index.key);}
    
  };

  const airNumberChangeHandler = (event: any, text: any) => {
    setEnteredAirNumber(text.trim());
  };

  const handlePendingValueChanged = (option: any) => {
    console.log(option, "option pending")
    if (!option || typeof getCarriers === 'string') {
      
      setIsInvalidInput(true);
    } else {
      setIsInvalidInput(false);
      setSelectedAirline(option);
    }
    setInputValue(option as string)
  };

  const handleFocus = (index: any) => {
    console.log(index, "index handleFocus")
    setEnteredAirline(index.key);
    setInputValue('');
  };

  const onClickKeyHandler= (option:any)=>{
    console.log(option, "option selected key")
    setEnteredAirline(option)
  }

  const onAddHandler = (event: any) => {
    event.preventDefault();
    setIsInvalidInput(false);
    if (enteredTravelerInfo) {
      var traveler = { ...enteredTravelerInfo };
      if (traveler.frequentGuestInfo) {
        traveler.frequentGuestInfo = [];
      }

      traveler.frequentGuestInfo = enteredTravelerInfo.frequentGuestInfo.concat(
        {
          chainCode: enteredAirline,
          guestNumber: enteredAirNumber,
          id: "",
        }
      );

      setTravelerInfo(traveler);
    }
    setEnteredAirNumber("");
    setEnteredAirline(null);

    //reset fields. add them in a list underneath.
  };

  const onRemoveHandler = (selectedItem: PortalModels.FFInfo) => {
    var traveler = { ...enteredTravelerInfo };
    traveler.frequentGuestInfo = enteredTravelerInfo.frequentGuestInfo.filter(
      (x) =>
        x.chainCode != selectedItem.chainCode &&
        x.guestNumber != selectedItem.guestNumber
    );
    setTravelerInfo(traveler);
  };

  const airlineIsValid = enteredAirline !== (null&&''&&undefined);
  const airNumberIsVaild = enteredAirNumber?.trim() !== "";
  let airIsValid = false;

  if (airlineIsValid && airNumberIsVaild) {
    airIsValid = true;
  }

  const [flyToggled, { setTrue: showCCode, setFalse: showAirline }] =
    useBoolean(false);

  const getCarriers = (): IComboBoxOption[] => {
    
    if (!flyToggled) {
      return frequentGuestOptions ?? [];
    } else if (frequentGuestOptions) {
      return frequentGuestOptions.map((x) => {
        //{ key: 'Header1', text: 'Select Airline', itemType: SelectableOptionMenuItemType.Header }
        return { key: x.key as string, text: x.key as string };
      });
    } else return [];
  };

  function flyOnChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    console.log("toggle is " + (checked ? "checked" : "not checked"));
    if (!checked) {
      showAirline();
    } else {
      showCCode();

      
    }
  }

  function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    console.log("toggle is " + (checked ? "checked" : "not checked"));
    if (!checked) {
      showCountryName();
    } else {
      showISOCode();
    }
  }

  //meals

  const newMealsHeader={ key: ' ', text: 'NONE'}

  const mealGuestOptions = props.mealGuestOptions ? [newMealsHeader, ...props.mealGuestOptions] : [newMealsHeader];
  const meals = (): IComboBoxOption[] => {
    return (
      mealGuestOptions?.map((x) => {
        return { key: x.key as string, text: x.text as string };
      }) ?? []
    );
  };

  //wheelchairs
  const newWheelsHeader={ key: ' ', text: 'None'}

  const wheelchairGuestOptions = props.wheelchairGuestOptions ? [newWheelsHeader, ...props.wheelchairGuestOptions] : [newWheelsHeader];

  const wheelies = (): IComboBoxOption[] => {
    return (
      wheelchairGuestOptions?.map((x) => {
        return { key: x.key as string, text: x.text as string };
      }) ?? []
    );
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Save Traveler Profile",
    closeButtonAriaLabel: "Close",
    subText: "Do you want to save and add Traveler Profile?",
  };
  const styles = mergeStyleSets({
    root: { selectors: { "> *": { marginBottom: 15 } } },
    control: { minWidth: 200, maxWidth: 200, marginBottom: 15 },
    display: "flex",
  });

  
  const [selectedPage, setSelectedPage] = useState(1);
  
  const ButtonIco = (): JSX.Element => {
    return (
      <>
        <IconButton
          iconProps={{ iconName: "ChromeBack" }}
          onClick={(e) => {
            if (selectedPage == 2) {
              setSelectedPage(selectedPage - 1);
            } else {
              return;
            }
          }}
          style={{ cursor: "pointer" }}
          disabled={selectedPage == 1}
        />
        <Label
          disabled={selectedPage == 1}
          style={{ cursor: "pointer", textDecorationLine: "underline" }}
          onClick={(e) => {
            if (selectedPage == 2) {
              setSelectedPage(selectedPage - 1);
            } else {
              return;
            }
          }}
        >
          Profile Information
        </Label>
        <Label
          disabled={selectedPage == 2}
          style={{ cursor: "pointer", textDecorationLine: "underline" }}
          onClick={(e) => {
            if (selectedPage == 1) {
              setSelectedPage(selectedPage + 1);
            } else {
              return;
            }
          }}
        >
          Frequent Flyer
        </Label>
        <IconButton
          iconProps={{ iconName: "ChromeBackMirrored" }}
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            if (selectedPage == 1) {
              setSelectedPage(selectedPage + 1);
            } else {
              return;
            }
          }}
          disabled={selectedPage == 2}
        />
        <br />
        <br />
      </>
    );
  };
  const [showCalendar, { toggle: toggleShowCalendar, setFalse: hideCalendar }] =
  useBoolean(false);
  const onSelectDate = React.useCallback(
    (date: Date, dateRangeArray: Date[]): void => {
      setSelectedDate(date);
      hideCalendar();
    },
    [hideCalendar]
  );

  
  
 //dates and passports
    //set to date to string then send it that way
    //parsedate from string on the datepicker
  


  
  const handleDateChange = (date: Date | null | undefined): void => {
    const userTimeZone = moment.tz.guess();
    const gmtOffset = 0;
    const timeZoneOffset = moment.tz.zone(userTimeZone)?.utcOffset(Date.now())??0;
    const offsetHours = date? date?.getUTCHours():undefined;
    var adjustedDate= date;
    const offsetOperator = timeZoneOffset <= 0 ? '+' : '-';
    if (offsetOperator=='+'){

      // if (timeZoneOffset== -60){
      //   adjustedDate= moment(date).add((date? Math.abs(timeZoneOffset/60):undefined), 'hours').toDate();;
      // } else {
      adjustedDate = moment(date).add((date? Math.abs(timeZoneOffset/60):undefined), 'hours').toDate();
      //}
    } else if ( offsetOperator=='-'){
      
      
      adjustedDate= date;
      //adjustedDate = moment(date).subtract((date? Math.abs(timeZoneOffset/60):undefined), 'hours').toDate();
    } 
    
    if (date){
      
      const dateHandleString = date.toISOString().split('T')[0];
      setTravelerInfo({
        ...enteredTravelerInfo,
          dateOfBirth:  adjustedDate ?? undefined,
      }) // Update state with the selected date
    } else {
      setTravelerInfo({
        ...enteredTravelerInfo,
          dateOfBirth:  undefined,
      }) // Update state with the selected date


    }
    
  };


 const getErrorMessage = (value: string): string => {
  return "Please check date entered.";
  };

  const datePickerRef = React.useRef<IDatePicker>(null);

  const [selectedDate, setSelectedDate] = React.useState<Date>();

  const dobStrings: IDatePickerStrings = useConst(() => ({
    ...defaultDatePickerStrings,
    isOutOfBoundsErrorMessage: "Please check Date of Birth.",
    invalidInputErrorMessage: "Please check Date of Birth.",
  }));

  const passString: IDatePickerStrings = useConst(() => ({
    ...defaultDatePickerStrings,
    isOutOfBoundsErrorMessage: "Please check Passport Expiration Field.",
    invalidInputErrorMessage: "Please check Passport Expiration Field.",
  }));
  



  const [passExpErrState, setPassExpErrState] = useState(false);

  const passExpError = (value: string): string => {
    var year = parseInt(new Date().getFullYear().toString());
    var day = parseInt(new Date().getDay().toString());
    var month = parseInt(new Date().getMonth().toString());

    if (1 > value.length) {
      setPassExpErrState(true);
      return "Field cannot be blank.";
    } else {
      var exMonth = parseInt(value?.split("/")[0]);
      var exDay = parseInt(value?.split("/")[1]);
      var exYear = parseInt(value?.split("/")[2]);

      if (
        year > exYear ||
        (year === exYear &&
          month >= exMonth &&
          month === exMonth &&
          day >= exDay) ||
        value?.split("/")[0] == "____" ||
        value?.split("/")[1] == "____" ||
        parseInt(value?.split("/")[0]) > 12 ||
        parseInt(value?.split("/")[0]) == 0
      ) {
        setPassExpErrState(true);
        return "Please enter a valid date.";
      } else {
        setPassExpErrState(false);
        return "";
      }
    }
  };

  const dateStringParse = (v:number) => {
    if (isNaN(v)) return;
  
    let parsedDate = new Date(v);
    let adjustedDate = new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000);
  
    return adjustedDate;
  };

  const parseDateFromString = (s: string): Date | null => {
    if (!s) {
      return null;
    }
    const parsedDate = new Date(s);
    // Convert to UTC
    return new Date(Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()));
  };

  
  
  const onPassportDateChange = (date: Date | null | undefined): void => {
    const userTimeZone = moment.tz.guess();
   
    const timeZoneOffset = moment.tz.zone(userTimeZone)?.utcOffset(Date.now())??0;
    
    var adjustedDate= date;
    
    const offsetOperator = timeZoneOffset <= 0 ? '+' : '-'
    if (offsetOperator=='+'){
      adjustedDate = moment(date).add((date? Math.abs(timeZoneOffset/60):undefined), 'hours').toDate();
       
    } else if ( offsetOperator=='-'){
      adjustedDate= date;
      // adjustedDate = moment(date).subtract((date? Math.abs(timeZoneOffset/60):undefined), 'hours').toDate();
      
    } 

    
    
    if (date){
      
      
      const dateHandleString = date.toISOString().split('T')[0];
      
      setTravelerInfo({
        ...enteredTravelerInfo,
        passportExpiration:  adjustedDate ?? undefined,
      }) // Update state with the selected date
    } else {
      setTravelerInfo({
        ...enteredTravelerInfo,
        passportExpiration:  undefined,
      }) // Update state with the selected date

    }
   
  };
  const initialPickerDate: Date | undefined =
    enteredTravelerInfo.dateOfBirth instanceof Date
      ? enteredTravelerInfo.dateOfBirth
      : undefined;

  const valueDOB: string | undefined =
    enteredTravelerInfo.dateOfBirth instanceof Date
      ? enteredTravelerInfo.dateOfBirth.toISOString()
      : enteredTravelerInfo.dateOfBirth;

  // const entDobIsValid =
  // enteredTravelerInfo.dateOfBirth !== undefined &&
  // 120 >=
  //   new Date().getFullYear() -
  //     enteredTravelerInfo.dateOfBirth.getFullYear() &&
  // 0 < new Date().getTime() - enteredTravelerInfo.dateOfBirth.getTime();

  // const passExpIsValid =
  //   ((enteredTravelerInfo.passportNumber?.trim() ?? "") == "" &&
  //     enteredTravelerInfo.passportExpiration == undefined) ||
  //   (enteredTravelerInfo.passportExpiration != undefined &&
  //     enteredTravelerInfo.passportExpiration.getFullYear() >=
  //       new Date().getFullYear() &&
  //     enteredTravelerInfo.passportExpiration.getTime() > new Date().getTime());


  //validation
  const firstNameIsValid = enteredTravelerInfo.firstName?.trim() !== "";
  const lastNameIsValid = enteredTravelerInfo.lastName?.trim() !== "";
  const entGenderIsValid = enteredTravelerInfo.gender !== undefined;


  const entDobIsValid= enteredTravelerInfo.dateOfBirth != undefined && isValidDate (enteredTravelerInfo.dateOfBirth);
  function isValidDate(date:string|Date): boolean {
    if (date instanceof Date){
      return true;
    }
    const parsedDate = new Date (date);
    return !isNaN(parsedDate.getTime())
    
  }

  const passExpIsValid =
  (
    (enteredTravelerInfo.passportNumber?.trim() ?? "") === "" &&
    enteredTravelerInfo.passportExpiration === undefined
  ) ||
  (
    enteredTravelerInfo.passportExpiration !== undefined &&
    isValidDate(enteredTravelerInfo.passportExpiration) &&
    (enteredTravelerInfo.passportExpiration instanceof Date
      ? enteredTravelerInfo.passportExpiration.getFullYear() >= new Date().getFullYear()
      : false) &&
    (enteredTravelerInfo.passportExpiration instanceof Date
      ? enteredTravelerInfo.passportExpiration.getTime() > new Date().getTime()
      : false)
  );



    const passCountryisBlank =  (((enteredTravelerInfo.passportNumber?.trim() ??"") == "") && (enteredTravelerInfo.passportIssuingCountry?.trim() ?? "") == "" )? true:false;
    const passCountryisFilled = (!((enteredTravelerInfo.passportNumber?.trim() ?? "") == "") && !((enteredTravelerInfo.passportIssuingCountry?.trim() ?? "") == ""))? true: false;  
    const passCountIsValid= passCountryisBlank || passCountryisFilled;
    

  

  const passPortBlank =
    (enteredTravelerInfo.passportExpiration != undefined &&
      (enteredTravelerInfo.passportNumber?.trim() ?? "") == "") ||
    (enteredTravelerInfo.passportIssuingCountry == "" &&
      (enteredTravelerInfo.passportNumber?.trim() ?? "") == "");

  const passportIsValid =
    ((enteredTravelerInfo.passportNumber?.trim() ?? "") == "" &&
      enteredTravelerInfo.passportExpiration == undefined) ||
    ((enteredTravelerInfo.passportNumber?.trim() ?? "") == "" &&
    (enteredTravelerInfo.passportIssuingCountry?.trim() ?? "") == ""  &&
    passCountIsValid &&
      passExpIsValid && 
      passCountIsValid) ||
    (enteredTravelerInfo.passportNumber?.trim() !== "" &&
      //enteredTravelerInfo.passportExpiration !== undefined &&
      !passPortBlank)
      ? true
      : false;

  const redressIsValid =
    ((enteredTravelerInfo.redressNumber?.trim() ?? "") !== "" && (enteredTravelerInfo.redressNumber?.trim().length==7) &&
      (enteredTravelerInfo.citizenship?.trim() ?? "") !== "") ||
    (enteredTravelerInfo.redressNumber?.trim() ?? "") == ""
      ? true
      : false;

  const knownIsValid =
    ((enteredTravelerInfo.knownTraveler?.trim() ?? "") !== "" &&
      (enteredTravelerInfo.citizenship?.trim() ?? "") !== "") && (enteredTravelerInfo.knownTraveler?.trim().length==9)||
    (enteredTravelerInfo.knownTraveler?.trim() ?? "") == ""
      ? true
      : false;

  // const globalIsValid =
  //   ((enteredTravelerInfo.globalEntry?.trim() ?? "") !== "" &&
  //     (enteredTravelerInfo.citizenship?.trim() ?? "") !== "") && (enteredTravelerInfo.globalEntry?.trim().length==9) ||
  //   (enteredTravelerInfo.globalEntry?.trim() ?? "") == ""
  //     ? true
  //     : false;
  let formIsValid = false;

  

  const citizenIsValid = ((enteredTravelerInfo.citizenship?.trim() ?? "") !== "" ||(
    (enteredTravelerInfo.redressNumber?.trim() !== ""  ||
    enteredTravelerInfo.knownTraveler?.trim() !== "" ||
    // enteredTravelerInfo.globalEntry?.trim() !== "" ||
    enteredTravelerInfo.passportNumber?.trim() !== "" ) &&
      (enteredTravelerInfo.citizenship?.trim() ?? "") !== "" )||
    ((enteredTravelerInfo.redressNumber?.trim() ?? "") === "" &&
      (enteredTravelerInfo.knownTraveler?.trim() ?? "") === "" &&
      // (enteredTravelerInfo.globalEntry?.trim() ?? "") === "" &&
      (enteredTravelerInfo.passportNumber?.trim() ?? "") === ""))
      ? true
      : false;

  if (
    firstNameIsValid &&
    lastNameIsValid &&
    entGenderIsValid &&
    entDobIsValid &&
    passportIsValid &&
    knownIsValid &&
    // globalIsValid &&
    citizenIsValid &&
    redressIsValid &&(
    (((enteredTravelerInfo.passportNumber?.trim() ??"") == "") && 
    // ((enteredTravelerInfo.globalEntry?.trim()??"")=="") && 
    ((enteredTravelerInfo.knownTraveler?.trim()??"")==""))||
    !((enteredTravelerInfo.citizenship?.trim() ?? "") == "" ) )&&
    passCountIsValid &&
    (passExpIsValid) &&
      ((((enteredTravelerInfo.passportNumber?.trim() ??"") == "") && 
        (enteredTravelerInfo.passportIssuingCountry?.trim() ?? "") == "" ) ||
        !((enteredTravelerInfo.passportIssuingCountry?.trim() ?? "") == "" ))
  ) {
    formIsValid = true;
  }

  

  return (
    <ThemeProvider theme={travelPanelTheme}>
      <>
        <form onSubmit={handleSubmit}>
          <Stack
            horizontal
            horizontalAlign="space-around"
            tokens={columnProps.tokens}
            style={{ width: screencheck }}
            // disableShrink
            // wrap
          >
            <Stack {...columnProps}>
              <Stack horizontal horizontalAlign="space-around">
                {/* {ButtonIco()} */}
              </Stack>
              <div hidden={props.page != 1}>
                <br />
                {/* <Label style={{fontSize: "20px", fontWeight: "semibold"}}>
            Travel Profile
          </Label> */}

                <Label style={{ fontSize: "15px", fontWeight: "semibold" }}>
                  Please ensure the name matches the passport. <br />
                </Label>
                <TextField
                  label="First Name"
                  theme={travelPanelTheme}
                  name="firstName"
                  autoComplete="given-name"
                  required
                  maxLength={85}
                  onChange={handleChange}
                  className="payment-info-field"
                  value={enteredTravelerInfo.firstName ?? ""}
                />

                <TextField
                  label="Middle Name"
                  theme={travelPanelTheme}
                  name="middleName"
                  maxLength={85}
                  autoComplete="additional-name"
                  onChange={handleChange}
                  className="payment-info-field"
                  value={enteredTravelerInfo.middleName ?? ""}
                />

                <TextField
                  label="Last Name"
                  aria-labelledby={labelId}
                  theme={travelPanelTheme}
                  name="lastName"
                  autoComplete="family-name"
                  required
                  maxLength={85}
                  onChange={handleChange}
                  className="payment-info-field"
                  value={enteredTravelerInfo.lastName ?? ""}
                  onRenderLabel={(props) => (
                    <CustomLabel id={labelId} {...props} />
                  )}
                  description="For format, click icon"
                />

                <Dropdown
                  label="Gender"
                  placeholder="Select an option"
                  theme={travelPanelTheme}
                  required
                  onChange={(e, v) =>
                    setTravelerInfo({
                      ...enteredTravelerInfo,
                      gender: v?.key as number,
                    })
                  }
                  options={options}
                  styles={dropdownStyles}
                  className="payment-info-field"
                  responsiveMode={ResponsiveMode.unknown}
                  selectedKey={enteredTravelerInfo.gender ?? undefined}
                />

                {/* <div className="dob"><span className="star">Date of Birth</span> </div>  */}
                <Label>
                  {" "}
                  Date of Birth<span className="star">*</span>
                </Label>
                <DatePicker
                  placeholder="MM/DD/YYYY"
                  openOnClick={false}
                  allowTextInput
                  formatDate={onFormatDate}
                  initialPickerDate={
                    enteredTravelerInfo.dateOfBirth ?? undefined
                  }
                  showGoToToday={false}
                  showMonthPickerAsOverlay={true}
                  aria-autocomplete="inline"
                  parseDateFromString={(s) => {
                    return s ? new Date(s) : null;
                  }}
                  value={enteredTravelerInfo.dateOfBirth ?? undefined}
                  onInvalid={undefined}
                  textField={{
                    validateOnFocusOut: true,
                    
                  }}
                  onSelectDate={handleDateChange}
                  style={styles}
                  //formatDate={(date) => (date ? new Date(date).toISOString() : '')}
                  // initialPickerDate={
                  //   enteredTravelerInfo.dateOfBirth instanceof Date?
                  //   enteredTravelerInfo.dateOfBirth :  undefined
                  // }
                  //parseDateFromString={parseDateFromString}
                  // parseDateFromString={(value: string | undefined): Date | null => {
                  //   return value ? new Date(value) : null;
                  // }}
                  // value={valueDOB}
                  // textField={{
                  //   validateOnFocusOut: true,
                  //   placeholder: onFormatDate(enteredTravelerInfo.dateOfBirth) ?? '',
                  // }}
                  // onSelectDate={(e) =>
                  //   setTravelerInfo({
                  //     ...enteredTravelerInfo,
                  //     dateOfBirth: e ?? undefined,
                  //   })
                  // }
                  //strings={dobStrings}
                />

                <StackItem
                  style={{ color: "red", padding: 1, fontSize: 12 }}
                  hidden={
                      enteredTravelerInfo.dateOfBirth == undefined ||
                      (typeof enteredTravelerInfo.dateOfBirth === 'string' ||
                        (120 >=
                          new Date().getFullYear() -
                            (enteredTravelerInfo.dateOfBirth as Date).getFullYear() &&
                          new Date().getFullYear() >=
                            (enteredTravelerInfo.dateOfBirth as Date).getFullYear() &&
                          0 <
                            new Date().getTime() -
                              (enteredTravelerInfo.dateOfBirth as Date).getTime()))
                }
                  
                >
                  Please check Date of Birth.
                </StackItem>


                <MaskedTextField
                  label="Phone Number"
                  theme={travelPanelTheme}
                  className="payment-info-field"
                  name="telephone"
                  mask="(999) 999 - 9999"
                  autoComplete="tel-national"
                  title="A 10 digit number"
                  maskFormat={maskFormat}
                  onChange={handleChange}
                  value={enteredTravelerInfo.telephone ?? ""}
                />
                <TextField
                  label="Personal Email"
                  theme={travelPanelTheme}
                  placeholder="Optional"
                  className="payment-info-field"
                  maxLength={85}
                  autoComplete="email"
                  name="email"
                  onChange={handleChange}
                  value={enteredTravelerInfo.email ?? ""}
                />
                {/* <TextField 
          theme={travelPanelTheme} 
          placeholder="Optional"
          className="payment-info-field" 
          label="Secondary Email" 
          onChange={handleChange} /> */}
                <br />

                <PassportImage
                  setPassportImage={(
                    n: string | undefined | null,
                    t: string | undefined | null,
                    r: string | undefined | null
                  ) => {
                    setTravelerInfo({
                      ...enteredTravelerInfo,
                      PassportScan: r,
                      passportScanFileName: n,
                      passportScanMimeType: t,
                    });
                  }}
                />

                <TextField
                  label="Passport Number"
                  theme={travelPanelTheme}
                  placeholder="Optional"
                  className="payment-info-field"
                  name="passportNumber"
                  autoComplete="passport"
                  onChange={handleChange}
                  value={enteredTravelerInfo.passportNumber?.trim() ?? ""}
                />
                <StackItem
                  style={{ color: "red", padding: 1, fontSize: 12 }}
                  hidden={                  
                    (((enteredTravelerInfo.passportIssuingCountry?.trim() ?? "") == "" ) && (passExpIsValid)) || (!((enteredTravelerInfo.passportNumber?.trim() ??"") == "") )
                  }
                >
                  Please check Passport Number.
                </StackItem>
                <DatePicker
                label="Passport Expiration"
                  placeholder="MM/DD/YYYY"
                  openOnClick={false}
                  allowTextInput
                  formatDate={onFormatDate}
                  initialPickerDate={
                    enteredTravelerInfo.passportExpiration ?? undefined
                  }
                  showGoToToday={false}
                  aria-autocomplete="inline"
                  showMonthPickerAsOverlay={true}
                  parseDateFromString={(s) => {
                    return s ? new Date(s) : null;
                  }}
                  onInvalid={undefined}
                  id="passExp"
                  textField={{
                    validateOnFocusOut: true,
                  }}
                  
                  isRequired={
                    (enteredTravelerInfo.passportNumber?.trim() ?? "") !== ""
                  }
                  value={enteredTravelerInfo.passportExpiration ?? undefined}
                  onSelectDate={onPassportDateChange}
                  style={styles}
                  //minDate={new Date()}
                  //maxDate={new Date()}
                  // onSelectDate={(v) =>
                  //   setTravelerInfo({
                  //     ...enteredTravelerInfo,
                  //     passportExpiration: v ?? undefined,
                  //   })
                  // }
                  
                  //strings={passString}
                />

                <StackItem
                  style={{ color: "red", padding: 1, fontSize: 12 }}
                  hidden={
                    enteredTravelerInfo.passportExpiration == undefined ||
                    passExpIsValid
                  }
                >
                  Please check Passport Expiration.
                </StackItem>

                <br />
                <Toggle
                  onText="Search By Country Code"
                  offText="Search By Country Name"
                  label=""
                  onChange={_onChange}
                />

                <ComboBox
                  label="Passport Country"
                  theme={travelPanelTheme}
                  options={getCountry()}
                  placeholder="Required with Passport"
                  className="payment-info-field"
                  onChange={(e, v) =>
                    setTravelerInfo({
                      ...enteredTravelerInfo,
                      passportIssuingCountry: v?.key as string,
                    })
                  }
                  allowFreeform
                  autoComplete="on"
                  dropdownWidth={300}
                  styles={{ optionsContainerWrapper: { height: 200 } }}
                  selectedKey={enteredTravelerInfo.passportIssuingCountry ?? ""}
                  calloutProps={{ doNotLayer: false }}
                  required={
                    (enteredTravelerInfo.passportNumber?.trim() ?? "") !== ""
                  }
                />
                <StackItem
                  style={{ color: "red", padding: 1, fontSize: 12 }}
                  hidden={
                    (((enteredTravelerInfo.passportNumber?.trim() ??"") == "") && (enteredTravelerInfo.passportIssuingCountry?.trim() ?? "") == "" ) ||
                    !((enteredTravelerInfo.passportIssuingCountry?.trim() ?? "") == "" )
                  }
                >
                  Please check Passport Country.
                </StackItem>

                <ComboBox
                  label="Citizenship"
                  theme={travelPanelTheme}
                  options={getCountry()}
                  placeholder="Required with Passport/Redress/KTN"
                  className="payment-info-field"
                  onChange={(e, v) =>
                    setTravelerInfo({
                      ...enteredTravelerInfo,
                      citizenship: v?.key as string,
                    })
                  }
                  allowFreeform
                  autoComplete="on"
                  dropdownWidth={300}
                  calloutProps={{ doNotLayer: false }}
                  styles={{ optionsContainerWrapper: { height: 200 } }}
                  selectedKey={enteredTravelerInfo.citizenship ?? ""}
                  required={
                    (enteredTravelerInfo.passportNumber?.trim() ?? "") !== "" ||
                    (enteredTravelerInfo.knownTraveler?.trim() ?? "") !== "" ||
                    // (enteredTravelerInfo.globalEntry?.trim() ?? "") !== "" ||
                    (enteredTravelerInfo.redressNumber?.trim() ?? "") !== ""
                  }
                />
                <StackItem
                  style={{ color: "red", padding: 1, fontSize: 12 }}
                  hidden={ citizenIsValid
                  }
                >
                  Please check Citizenship.
                </StackItem>


                {/* <TextField
                  label="Global Entry Number"
                  theme={travelPanelTheme}
                  placeholder="Optional"
                  className="payment-info-field"
                  name="globalEntry"
                  autoComplete="off"
                  onChange={handleChange}
                  value={enteredTravelerInfo.globalEntry ?? ""}
                />
                <StackItem
                  style={{ color: "red", padding: 1, fontSize: 12 }}
                  hidden={(((enteredTravelerInfo.globalEntry?.trim() ??"") == "") || (!((enteredTravelerInfo.globalEntry?.trim()??"")=="") && (enteredTravelerInfo.globalEntry?.trim().length==9)))}
                >
                  Please confirm nine-digit Global Entry Number.
                </StackItem> */}

                <TextField
                  label="Redress Number"
                  theme={travelPanelTheme}
                  placeholder="Optional"
                  className="payment-info-field"
                  name="redressNumber"
                  autoComplete="off"
                  onChange={handleChange}
                  value={enteredTravelerInfo.redressNumber ?? ""}
                  
                />
                <StackItem
                  style={{ color: "red", padding: 1, fontSize: 12 }}
                  hidden={(((enteredTravelerInfo.redressNumber?.trim() ??"") == "") || (!((enteredTravelerInfo.redressNumber?.trim()??"")=="") && (enteredTravelerInfo.redressNumber?.trim().length==7)))}
                >
                  Please confirm seven-digit Redress Number.
                </StackItem>
                <TextField
                  label="Known Traveler Number"
                  placeholder="Optional"
                  theme={travelPanelTheme}
                  className="payment-info-field"
                  name="knownTraveler"
                  autoComplete="off"
                  onChange={handleChange}
                  value={enteredTravelerInfo.knownTraveler ?? ""}
                  
                />
                <StackItem
                  style={{ color: "red", padding: 1, fontSize: 12 }}
                  hidden={(((enteredTravelerInfo.knownTraveler?.trim() ??"") == "") || (!((enteredTravelerInfo.knownTraveler?.trim()??"")=="") && (enteredTravelerInfo.knownTraveler?.trim().length==9)))}
                >
                  Please confirm nine-digit Known Traveler Number.
                </StackItem>
                <ComboBox
                  label="Wheelchair Needs"
                  theme={travelPanelTheme}
                  options={wheelies()}
                  placeholder="Optional"
                  className="payment-info-field"
                  onChange={(e, v) =>
                    setTravelerInfo({
                      ...enteredTravelerInfo,
                      wheelchair: (v?.key as string) ?? undefined,
                    })
                  }
                  allowFreeform={false}
                  calloutProps={{ doNotLayer: true }}
                  dropdownWidth={550}
                  selectedKey={enteredTravelerInfo.wheelchair ?? undefined}
                />
                <ComboBox
                  label="Special Meal Request"
                  theme={travelPanelTheme}
                  options={meals()}
                  placeholder="Optional"
                  className="payment-info-field"
                  onChange={(e, v) =>
                    setTravelerInfo({
                      ...enteredTravelerInfo,
                      mealOther: (v?.key as string) ?? undefined,
                    })
                  }
                  allowFreeform={false}
                  onFocus={meals}
                  calloutProps={{ doNotLayer: false }}
                  styles={{ optionsContainerWrapper: { height: 200 } }}
                  dropdownWidth={400}
                  selectedKey={enteredTravelerInfo.mealOther ?? undefined}
                />
                <br />

                <Stack horizontalAlign="center" id="sectionmain">
                  <div style={{ alignContent: "center" }}>
                    <PrimaryButton
                      text={"Add Frequent Flyer"}
                      style={{
                        textSizeAdjust: "auto",
                        wordWrap: "normal",
                        whiteSpace: "nowrap",
                      }}
                      onClick={(e) => {
                        //if(props.page==1){
                        handleIncreasePage();
                        //else{return}
                      }}
                    />
                  </div>
                  <br />
                  <Label style={{ fontSize: "auto" }}>
                    <b>Note:</b> Click 'Submit Profiles' on the main page to
                    transmit this data.
                    <br />
                  </Label>
                </Stack>

                <br></br>

                <Stack horizontal horizontalAlign="center" disableShrink wrap>
                  <PrimaryButton
                    text={
                      props.editTraveler ? "Update Traveler" : "Add Traveler"
                    }
                    theme={travelPanelTheme}
                    disabled={!formIsValid}
                    onClick={() => {
                      enteredTravelerInfo.firstName =
                        enteredTravelerInfo.firstName?.trim() ?? "";
                      enteredTravelerInfo.middleName =
                        enteredTravelerInfo.middleName?.trim() ?? "";
                      enteredTravelerInfo.lastName =
                        enteredTravelerInfo.lastName?.trim() ?? "";
                      enteredTravelerInfo.email =
                        enteredTravelerInfo.email?.trim() ?? "";
                      enteredTravelerInfo.passportNumber =
                        enteredTravelerInfo.passportNumber?.trim() ?? "";
                      // enteredTravelerInfo.globalEntry =
                      //   enteredTravelerInfo.globalEntry?.trim() ?? "";
                      enteredTravelerInfo.redressNumber =
                        enteredTravelerInfo.redressNumber?.trim() ?? "";
                      enteredTravelerInfo.knownTraveler =
                        enteredTravelerInfo.knownTraveler?.trim() ?? "";
                      //enteredTravelerInfo.dateOfBirth= enteredTravelerInfo.dateOfBirth?;
                      enteredTravelerInfo.id =
                        enteredTravelerInfo.id.trim() !== ""
                          ? enteredTravelerInfo.id
                          : Math.random().toString(36).substring(2, 9);

                      props.onSaveTraveler(enteredTravelerInfo);
                      handleSubmit(null);
                    }}
                  />

                  {/* <PrimaryButton
              theme={travelPanelTheme} 
              disabled={!formIsValid}
              text={"Add FF Info"}
              onClick={(e) => {
                //set event handler for this?
                props.frequentFlyPanel(isFlyerOpen: true, openFlyerAsAdd: true);
                
              }} 
            /> */}
                  {/* <FrequentFlyPanel
            frequentGuestOptions={this.state.frequentCarrierOptions}
            /> */}
                </Stack>

                <Stack
                  style={{ color: "red", padding: 1 }}
                  tokens={{ childrenGap: 2 }}
                >
                  <br />
                  <StackItem
                    style={{ color: "red", padding: 1, fontSize: 15 }}
                    hidden={firstNameIsValid}
                  >
                    Please check First Name field.
                  </StackItem>
                  <StackItem
                    style={{ color: "red", padding: 1, fontSize: 15 }}
                    hidden={lastNameIsValid}
                  >
                    Please check Last Name field.
                  </StackItem>
                  <StackItem
                    style={{ color: "red", padding: 1, fontSize: 15 }}
                    hidden={entGenderIsValid}
                  >
                    Please check Gender field.
                  </StackItem>
                  <StackItem
                    style={{ color: "red", padding: 1, fontSize: 15 }}
                    hidden={entDobIsValid}
                  >
                    Please check Date of Birth field.
                  </StackItem>
                  <StackItem
                    style={{ color: "red", padding: 1, fontSize: 15 }}
                    hidden={(((enteredTravelerInfo.passportIssuingCountry?.trim() ?? "") == "" ) && (passExpIsValid)) || (!((enteredTravelerInfo.passportNumber?.trim() ??"") == "") )}
                  >
                    Please check Passport Number field.
                  </StackItem>
                  <StackItem
                    style={{ color: "red", padding: 1, fontSize: 15 }}
                    hidden={passExpIsValid}
                  >
                    Please check Passport Expiration field.
                  </StackItem>
                  <StackItem
                    style={{ color: "red", padding: 1, fontSize: 15 }}
                    hidden={(((enteredTravelerInfo.passportNumber?.trim() ??"") == "") && (enteredTravelerInfo.passportIssuingCountry?.trim() ?? "") == "" ) ||
                    !((enteredTravelerInfo.passportIssuingCountry?.trim() ?? "") == "" )}
                  >
                    Please check Passport Country field.
                  </StackItem>

                  <StackItem
                    style={{ color: "red", padding: 1, fontSize: 15 }}
                    hidden={(((enteredTravelerInfo.passportNumber?.trim() ??"") == "") && ((enteredTravelerInfo.globalEntry?.trim()??"")=="") && ((enteredTravelerInfo.knownTraveler?.trim()??"")=="")) && ((enteredTravelerInfo.redressNumber?.trim()??"")=="")||
                    !((enteredTravelerInfo.citizenship?.trim() ?? "") == "" )}
                  >
                    Please check Citizenship field.
                  </StackItem>
                  {/* <StackItem
                    style={{ color: "red", padding: 1, fontSize: 15 }}
                    hidden={(((enteredTravelerInfo.globalEntry?.trim() ??"") == "") || (!((enteredTravelerInfo.globalEntry?.trim()??"")=="") && (enteredTravelerInfo.globalEntry?.trim().length==9)))}
                  >
                    Please confirm nine-digit Global Entry Number.
                  </StackItem> */}
                  <StackItem
                    style={{ color: "red", padding: 1, fontSize: 15 }}
                    hidden={(((enteredTravelerInfo.redressNumber?.trim() ??"") == "") || (!((enteredTravelerInfo.redressNumber?.trim()??"")=="")&& (enteredTravelerInfo.redressNumber?.trim().length==7)))}
                  >
                    Please confirm seven-digit Redress Number.
                  </StackItem>

                  <StackItem
                    style={{ color: "red", padding: 1, fontSize: 15 }}
                    hidden={(((enteredTravelerInfo.knownTraveler?.trim() ??"") == "") || (!((enteredTravelerInfo.knownTraveler?.trim()??"")=="") && (enteredTravelerInfo.knownTraveler?.trim().length==9)))}
                  >
                    Please confirm nine-digit Known Traveler Number.
                  </StackItem>

                </Stack>
              </div>
              <div hidden={props.page != 2}>
                <Stack
                  //tokens={flightTokens}
                  horizontalAlign="center"
                  verticalAlign="center"
                >
                  <Separator />
                  <Stack
                    //tokens={flightTokens}
                    horizontalAlign="space-evenly"
                  >
                    <Toggle
                      label=""
                      onText="Search by Carrier Code"
                      offText="Search by Airline"
                      onChange={flyOnChange}
                    />
                    <Separator />
                    <Stack
                      horizontal
                      style={{ padding: 10 }}
                      horizontalAlign="space-evenly"
                      verticalAlign="center"
                      //tokens={columnProps}
                    >
                      <StackItem style={{ padding: 20, border: 30 }}>
                        <ComboBox
                          label="Airline"
                          allowFreeform={true}
                          autoComplete="on"
                          calloutProps={{ doNotLayer: false }}
                          options={getCarriers()}
                          style={{ maxWidth: 300 }}
                          onFocus={handleFocus}
                          styles={{ optionsContainerWrapper: { height: 200 } }}
                          onChange={airlineChangeHandler}
                          onPendingValueChanged={handlePendingValueChanged}
                          placeholder="Enter Airline"
                          onClick={onClickKeyHandler}
                          //selectedKey={selectedKeyHandler}
                          selectedKey={enteredAirline? enteredAirline : null}
                          dropdownWidth={300}
                          text={inputValue}
                        />
                      </StackItem>
                      {/* </Stack>  
              <Stack horizontal 
                styles={stackStyles}
                //tokens={flightTokens}
                > */}
                      <StackItem>
                        <TextField
                          onChange={airNumberChangeHandler}
                          value={enteredAirNumber}
                          label="Number"
                        />
                      </StackItem>
                    </Stack>

                    <Separator />
                    <Stack
                      theme={travelPanelTheme}
                      tokens={buttonSpacing}
                      style={{ alignItems: "center" }}
                    >
                      <PrimaryButton
                        disabled={!airIsValid}
                        onClick={onAddHandler}
                        text="Add"
                        style={{ width: 200, alignContent: "center" }}
                      />
                    </Stack>
                  </Stack>
                  <Separator />
                  <Stack tokens={columnProps} style={{ padding: 5 }}>
                    <div>
                      {/* Marquee Selection so that it can delete mistyped info. */}
                      <AirlineDetailsList
                        flyerInfo={enteredTravelerInfo?.frequentGuestInfo}
                        removeFF={onRemoveHandler}
                      />
                    </div>
                  </Stack>

                  <br />
                  <br />
                </Stack>
                <Stack
                  horizontal
                  horizontalAlign="center"
                  disableShrink
                  wrap
                  style={{
                    textSizeAdjust: "auto",
                    wordWrap: "normal",
                    whiteSpace: "nowrap",
                  }}
                >
                  <a href="#sectionmain">
                    <PrimaryButton
                      text={"Save & Close"}
                      onClick={(e) => {
                        //if(props.page==2){
                        handleDecreasePage();

                        //}
                        //else{return}
                      }}
                    />
                  </a>
                </Stack>
              </div>
            </Stack>
          </Stack>
        </form>
      </>
    </ThemeProvider>
  );
};

export default NewTraveler;
