import * as React from 'react';
import { DefaultButton, PrimaryButton, Label } from '@fluentui/react';
import { Panel, PanelType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Dialog, DialogFooter, DialogType, DetailsList, createTheme } from '@fluentui/react';
import './Panel.scss';
import CrmService from '../../Contexts/CrmService';
import * as PortalModels from '../../Contexts/Models';
import * as PortalEnums from '../../Contexts/Enums';
import { ThemeProvider } from '@fluentui/react';
import { TravelerSearch } from '../../Contexts/Models';
import { useState, useCallback} from 'react';
import SearchTraveler from './Form/SearchTraveler';

//might set up a ternery expression for the panel. 
// const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

const travelPanelTheme = createTheme({
  fonts: {
    small: {
      fontSize: "12px",
    },
    medium: {
      fontSize: "15px",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },
  palette: {
    themePrimary: "#454545",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#b39e51",
    themeDark: "#918e2a",
    themeDarker: "#004578",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#323130",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    bodyText: "#323130",
  
  },
});
const dialogContentProps = {
  type: DialogType.normal,
  title: 'Are you sure you want to close the panel?',
};
const dialogModalProps = {
  isBlocking: true,
  styles: { main: { maxWidth: 450 } },
};

export interface ISearchPanelProps{
  crmService:CrmService;
  isSearchOpen:boolean;
  openSearchAsAdd:boolean;
  agencyId:string;
  agentId:string;
  addTravelers(profiles: PortalModels.TravelerProfiles[]):void;
  closeSearchPanel():void;
  autoTimerStart2:boolean;
}


export const SearchPanel: React.FunctionComponent<ISearchPanelProps> = (props:ISearchPanelProps) => {
  // const [isOpen, {setTrue:openPanel, setFalse:dismissPanel}]= useBoolean(false);
  const [isSearchOpen, setIsSearchOpen] = useState (false);
  const [isDialogVisible, setIsDialogVisible] = React.useState(false);
  const openSearchPanel = useCallback(()=> setIsSearchOpen(false),[])
  const hideDialog = React.useCallback(() => setIsDialogVisible(false), []);
  const hideDialogAndPanel = React.useCallback(() => {
    props.closeSearchPanel();
    setIsDialogVisible(false);
  }, []);
  
  

  const onDismiss = React.useCallback((ev?: React.SyntheticEvent | KeyboardEvent) => {  //useCallback mostly used when you don't want a function to get un-necessarily created each time on every render and subsequent re-renders of the component
    if (ev) {
      // Instead of closing the panel immediately, cancel that action and show a dialog
      ev.preventDefault();
      setIsSearchOpen(false);
      setIsDialogVisible(true);
    }
  }, []);
 
  return (
    
    <div>
      <ThemeProvider theme={travelPanelTheme}>
      <Panel
        headerText="Search for Traveler"
        isOpen={props.isSearchOpen}
        onDismiss={onDismiss}
        type={PanelType.medium}
        closeButtonAriaLabel="Close"// You MUST provide this prop! 
        >
        <Label className="payment-info-field">This tool is to locate previous travelers that have been booked by you before. </Label>

        <SearchTraveler onSearchTraveler={(t: PortalModels.TravelerProfiles) => {
            console.log(`PANEL Adding: ${t}`);
            props.addTravelers([t]);
            props.closeSearchPanel();
            setIsDialogVisible(false);
          } } agentId={props.agentId} agencyId={props.agencyId}/>
                
      </Panel>
      </ThemeProvider>
      <Dialog
        hidden={!isDialogVisible}
        onDismiss={hideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={dialogModalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={hideDialogAndPanel} text="Yes" />
          <DefaultButton onClick={hideDialog} text="No" />
        </DialogFooter>
      </Dialog>
      
    </div>
    
  );
};