import {
  DefaultButton,
  Dropdown,
  DropdownMenuItemType,
  FontWeights,
  IButtonStyles,
  IDropdownOption,
  IDropdownStyles,
  IStackStyles,
  IStackTokens,
  IconButton,
  Label,
  Modal,
  PrimaryButton,
  Stack,
  StackItem,
  ThemeProvider,
  createTheme,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import * as PortalModels from "../../Contexts/Models";
import { Button, Select, useId } from "@fluentui/react-components";
import { useBoolean } from "@fluentui/react-hooks";
import { IIconProps, Icon } from "@fluentui/react/lib/Icon";
import { CopyModal } from "./CopyModal";
import { group } from "console";
import { ItineraryQuote, ItineraryQuotedFare } from "../../Contexts/Enums";
import CopyToClipboard from "react-copy-to-clipboard";
import axios from "axios";

export interface IResultsProps {
  responseData: PortalModels.ShoppingResponse | null;
  frequentGuestOptions?: PortalModels.FrequentCarrier;
}



const headingStackTokens: IStackTokens = { childrenGap: 30 };

const airPanelTheme = createTheme({
  fonts: {
    small: {
      fontSize: "13px",
      fontWeight: "semibold",
    },
    medium: {
      fontSize: "15px",
      fontWeight: "semibold",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },

  palette: {
    themePrimary: "#b39e51",
    themeLighterAlt: "#070603",
    themeLighter: "#1d190d",
    themeLight: "#362f18",
    themeTertiary: "#6b5e30",
    themeSecondary: "#9d8a47",
    themeDarkAlt: "#baa65e",
    themeDark: "#c5b373",
    themeDarker: "#d4c693",
    neutralLighterAlt: "#282828",
    neutralLighter: "#272727",
    neutralLight: "#252525",
    neutralQuaternaryAlt: "#232323",
    neutralQuaternary: "#212121",
    neutralTertiaryAlt: "#202020",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralSecondaryAlt: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#282828",
  },

  // semanticColors: {
  //   bodyText: "#323130",
  // },
});
const confirmPanelTheme = createTheme({
  fonts: {
    small: {
      fontSize: "13px",
      fontWeight: "semibold",
    },
    medium: {
      fontSize: "15px",
      fontWeight: "semibold",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },

  palette: {
    themePrimary: "#454545",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#b39e51",
    themeDark: "#918e2a",
    themeDarker: "#004578",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#323130",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    bodyText: "#323130",
  },
});
const cancelIcon: IIconProps = { iconName: "Cancel" };

const theme = getTheme();

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  header: [
    theme.fonts.large,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: "inherit",
    margin: "0",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});


const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackStyles: IStackStyles = {
  root: [
    {
      justifyContent: "space-around",
      padding: 20,
      alignContent: "space-around",
    },
  ],
};
const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

export const OutboundDisplay: React.FC<IResultsProps> = ({ responseData }) => {
  const [showData, setShowData] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const toggleShowData = (index: any) => {
    console.log("toggleclicked");
    setShowData(!showData);
  };

  //Modal stuff

  const [selectedSegment, setSelectedSegment] = useState<
    PortalModels.ShoppedSegment[] | null
  >(null);
  const [selectedQuote, setSelectedQuote] =
    useState<PortalModels.ItineraryQuote | null>(null);
  const [selectedFare, setSelectedFare] =
    useState<PortalModels.ItineraryQuotedFare | null>(null);
  const [selectedItinerary, setSelectedItinerary] =
    useState<PortalModels.SelectedItineraryAndQuote | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const copyData = (
    segment: PortalModels.ShoppedSegment[],
    quote: PortalModels.ItineraryQuote,
    fare: PortalModels.ItineraryQuotedFare
  ) => {
    setSelectedSegment(segment);
    setSelectedQuote(quote);
    setSelectedFare(fare);

    const selectedData: PortalModels.SelectedItineraryAndQuote = {
      segments: segment.map((selectedSegment) => {
        return {
          operatingFlightNumber: selectedSegment.operatingEquipment?.name,
          operatingChain: selectedSegment.operatingChain,
          marketingChain: selectedSegment.marketingChain,
          marketingFlightNumber: selectedSegment.marketingFlightNumber,
          origin: selectedSegment.origin,
          destination: selectedSegment.destination,
          departureDateTime: selectedSegment.departureDateTime,
          arrivalDateTime: selectedSegment.arrivalDateTime,
          operatingEquipment: selectedSegment.operatingEquipment,
          duration: selectedSegment.duration,
          miles: selectedSegment.miles,
          classOfService: selectedSegment.classOfService,
          cabin: selectedSegment.cabin,
          fareBasisCode: selectedSegment.fareBasisCode,
        };
      }),
      quotes: [
        {
          quotedCity: selectedQuote?.quotedCity,
          isNDC: selectedQuote?.isNDC,
          totalItin: selectedQuote?.totalItin,
          fares: quote.fares.map((fare) => ({
            totalTax: selectedFare?.totalTax,
            bags: selectedFare?.bags,
            systemId: selectedFare?.systemId,
            offerItemId: selectedFare?.offerItemId,
            pricePerPassengerTypes: Array.isArray(fare.pricePerPassengerTypes)
              ? fare.pricePerPassengerTypes.map((price) => ({
                  passengerType: price.passengerType,
                  totalPrice: price.totalPrice,
                }))
              : [],
          })),
        },
      ],
    };

    if (selectedData) {
      setSelectedItinerary(selectedData);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedItinerary(null);
    setIsModalOpen(false);
  };
  
  
  


  const ItineraryModal: React.FC<PortalModels.ItineraryModalProps> = ({
    selectedData,
    isOpen,
    onClose,
  }) => {
    const { segments, quotes } = selectedData || { segments: [], quotes: [] };
    const [copyValue, setCopyValue] = useState('');
    const [copied,setCopied]=useState(false);
    const onCopyChange = React.useCallback(({target: {value}}) => {
      setCopyValue(value);
      setCopied(true);
      
    }, [])
    
    const onCopy = React.useCallback(() => {
      setCopied(true);
    }, [])

    
      
      const copyAllData = () => {
        // Combine HTML content of all selected itineraries
        const htmlSegs=  segments.map((x, copyIndex) => {
            return (
              `<div key=${copyIndex}>
                <p>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <strong style="color:#b39e51;">Airline</strong>
                            <br />
                            ${x.marketingChain }
                          </p>
                        </td>
                        <td>
                          <p>
                            <strong style="color:#b39e51;">Flight Number</strong>
                            <br />
                            ${x.marketingFlightNumber}
                          </p>
                        </td>
                        <td>
                          <p>
                            <strong style="color:#b39e51;">Class:</strong>
                            <br />
                            ${x.classOfService == "none provided"
                              ? x.cabin
                              : x.classOfService}
                          </p>
                        </td>
                        <td>
                          <p>
                            <strong style="color:#b39e51;">From:</strong>
                            <br />
                            ${x.origin}
                          </p>
                        </td>
                        <td>
                          <p>
                            <strong style="color:#b39e51;">Leaving:</strong>
                            <br />
                            ${x.departureDateTime}
                          </p>
                        </td>
                        <td>
                          <p>
                            <strong style="color:#b39e51;">Destination:</strong>
                            <br />
                            ${x.destination}
                          </p>
                        </td>
                        <td>
                          <p>
                            <strong style="color:#b39e51;">Arriving:</strong>
                            <br />
                            ${x.arrivalDateTime}
                          </p>
                        </td>
                        <td>
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td>
                                  <p>
                                    <strong style="color:#b39e51;">Type:</strong>
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    ${x.operatingEquipment?.aircraftCode =="".trim()? x.operatingEquipment.name :x.operatingEquipment?.aircraftCode}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>
                                    <strong style="color:#b39e51;">Operated By:</strong>
                                  </p>
                                </td>
                                <td>
                                  <p>${x.operatingChain}</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>
                                    <strong style="color:#b39e51;">Flying Time:</strong>
                                  </p>
                                </td>
                                <td>
                                  <p>${x.duration} </p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>
                                    <strong style="color:#b39e51;">Stops:</strong>
                                  </p>
                                </td>
                                <td>
                                  <p>${segments.length -1}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </p>
              </div>`
            );
          });

        const copyPrice=quotes.map((w, priceIndex) =>{ 
          return (
        `
        <div key=${priceIndex}> 
        <p>
          The following is available at $${w.fares.map((t)=> t.pricePerPassengerTypes.map((r)=>r.totalPrice))} + $ per person
        </p>
        </div>
        `
        )
        });

           
          let combinedHTML =  htmlSegs.join(`<br/>`) ;
          let combinedPriceHTML = copyPrice + '<br/>' +combinedHTML;  
        
    
        // Create a temporary textarea to copy the HTML content
        //const textarea = document.createElement('textarea');
        //textarea.innerHTML = combinedHTML;
        //document.body.appendChild(textarea);
        
        //Copy the HTML content to the clipboard
        //textarea.select();
        //window.top?.navigator.clipboard.writeText(combinedHTML)
        let navi=navigator;
        let doc=document;
        let p = parent;
        while (p&&doc.hasFocus()==false){
          navi=p.navigator;
          doc=p.document;
          p=p.parent;
        }
        const blob = new Blob([combinedPriceHTML],{'type':'text/html'});
        const item = new ClipboardItem({['text/html']: blob});
        navi.clipboard.write([item])
        //navi.clipboard.writeText(combinedHTML)
        .then(() => {
          console.log('Text successfully copied to clipboard');
        })
        .catch((err) => {
          console.error('Unable to copy text to clipboard', err);
        });
    
        // Remove the temporary textarea
         //document.body.removeChild(textarea);
      };



    const titleId = useId("Selected Itinerary");

    return (
      <>
        <ThemeProvider theme={confirmPanelTheme}>
          <Modal
            isOpen={isOpen}
            onDismiss={onClose}
            theme={confirmPanelTheme}
            isBlocking={true}
            containerClassName={contentStyles.container}
          >
            <>
              <div className={contentStyles.header}>
                <Label style={{ textSizeAdjust: "xxLarge" }} id={titleId}>
                  Selected Itinerary
                </Label>
              </div>

              {/* Render itinerary and quote details */}
              <Stack verticalAlign="center" horizontalAlign="center" style={{padding:20}}>
                <div id='copyArea'> 
                {segments.map((x, segIndex) => {
                  return (
                    <div key={segIndex}>
                      <p>
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td>
                                <p>
                                  <strong>Airline</strong>
                                  <br />
                                  {x.marketingChain }
                                </p>
                              </td>
                              <td>
                                <p>
                                  <strong>Flight Number</strong>
                                  <br />
                                  {x.marketingFlightNumber}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <strong>Class:</strong>
                                  <br />
                                  {x.classOfService == "none provided"
                                    ? x.cabin
                                    : x.classOfService}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <strong>From:</strong>
                                  <br />
                                  {x.origin}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <strong>Leaving:</strong>
                                  <br />
                                  {x.departureDateTime}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <strong>Destination:</strong>
                                  <br />
                                  {x.destination}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <strong>Arriving:</strong>
                                  <br />
                                  {x.arrivalDateTime}
                                </p>
                              </td>
                              <td>
                                <table width="100%">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <p>
                                          <strong>Type:</strong>
                                        </p>
                                      </td>
                                      <td>
                                        <p>
                                          {x.operatingEquipment?.aircraftCode =="".trim()? x.operatingEquipment.name :x.operatingEquipment?.aircraftCode}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>
                                          <strong>Operated By:</strong>
                                        </p>
                                      </td>
                                      <td>
                                        <p>{x.operatingChain}</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>
                                          <strong>Flying Time:</strong>
                                        </p>
                                      </td>
                                      <td>
                                        <p>{x.duration} </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>
                                          <strong>Stops:</strong>
                                        </p>
                                      </td>
                                      <td>
                                        <p>{segments.length -1}</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </p>
                    </div>
                  );
                })}

               </div> 

                {/* {quotes.map((y,selQuote)=>{ return(
          <>
          <div key={selQuote}>
            <ul>
            {y.fares.map((fare, fareIndex)=>(
              <div key={fareIndex}>
              <p>{fare.pricePerPassengerTypes}</p>
                </div>
            ))}
              
            </ul>
          </div>
          </>
          )
        })}
         */}

                <Stack horizontalAlign='center' horizontal={true} tokens={{childrenGap: 30, maxWidth:300, padding:10}}>
                {copied ? <Label style={{color: 'red'}}>Copied</Label> : null}
                 <CopyToClipboard
                  onCopy={onCopy}
                  options={{message:'copy!'}}
                  text={copyValue}
                  >
                  <PrimaryButton onClick={copyAllData} text="Copy Grid"/>
                  </CopyToClipboard>
                <DefaultButton onClick={onClose} text="Close" />
                </Stack>
              </Stack>
            </>
          </Modal>
        </ThemeProvider>
      </>
    );
  };

  //nested quotes
  const [showNestedArrays, setShowNestedArrays] = useState(
    responseData?.itineraries?.map((x) => false) ?? []
  );

  const toggleNestedArrays = (index: number) => {
    setShowNestedArrays((prev: any) => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    });
  };
  const [sortBy, setSortBy] = useState("lowestNumber");
  const handleSortChange = (event: any) => {
    setSortBy(event.target.value);
  };
  // const processList = (list: any) => {
  //   list.forEach((item: any) => {
  //     item.lowestNumber = getLowestFare(item.totalItin);
  //   });
  // };

  const getLowestFare = (totalItin: number[]) => {
    return Math.min(...totalItin);
  };

  //sort Options

  const sortOptions: IDropdownOption[] = [
    { key: "lowestFare", text: "Lowest Fare" },
    // { key: "shortestTime", text: "Fastest Trip" },
    // { key: "leastStops", text: "Least Stops" },
  ];




  //fix date

  const formatCustomDate=(dateInput?: string | null):string=> {
    if (!dateInput){
      return ''
    }
    const [datePart, timePart] = dateInput.split('T');
    if (!datePart || !timePart) {
      return ''
    }
    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute] = timePart.split(':').map(Number);

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    
    
    const formattedDate = new Date(year, month - 1, day, hour, minute).toLocaleString('en-US', options);
    return formattedDate;
  }

  //airline banner


  const [airlineListPack, setAirlineListPack ]=useState<{ frequentCarrier: PortalModels.FrequentCarrier[] } | null>(null);

  // useEffect(() => {
  //   const fetchAirlineListPack = async ()=>{
  //     try{
  //       const response = await axios.get("https://prod-190.westus.logic.azure.com:443/workflows/a6a60b42c4164cb892117afccc6cb843/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=oi86GsVstDozeJT3ivgjyJZIu1V-OYkbtx17-VrdC2Q");
  //       setAirlineListPack(response.data.frequentCarrier);
  //     } catch (error) {
  //     console.log("error")
  //   }
  //   };
  //   fetchAirlineListPack();
  // }, []);
  
  const airlineKeyToText  = (key: string | null | undefined): string | null | undefined  => {
    if (!key) {
      return key; // Return null or undefined if the input is null or undefined
    }
    
    const matchingCarrier = airlineListPack?.frequentCarrier.find(carrier => carrier.key === key);
    return matchingCarrier?.text || key; // Return the text if a match is found, otherwise return the original key
  };
  

  // const airlineBannerFirster = (firstAirline?: string|null)=>{
  
  //   var allAirTypes = responseData?.itineraries;
  //   var airTypes= allAirTypes?.map((u)=>{u.segments.map((y)=> {y.marketingChain})})
    
  //   const marketingChainsToFind: string[] = airlineListPack; 
  //   const firstItemsByMarketingChain: Record<string, data | undefined> = {};

  //   marketingChainsToFind.forEach(marketingChain => {
  //     const firstMatchingItem = airTypes.find(item => item.marketingChain === marketingChain);
  //     firstItemsByMarketingChain[marketingChain] = firstMatchingItem;
  //   });


  //   return firstItemsByMarketingChain;
  // }

  

  //grouping for round an multi
  const groupedItineraries: Record<string, PortalModels.ShoppedItinerary[]> =
    {};

  // responseData?.itineraries.forEach((itinerary) => {

  //   const key = `${itinerary.segments[0].origin}-${itinerary.segments[0].destination}`;

  //   if (!groupedItineraries[key]) {
  //     groupedItineraries[key] = [];
  //   }

  //   groupedItineraries[key].push(itinerary);
  // });

 

  // const loadMoreItineraries = () => {
  //   setVisibleItineraries((prevVisible) => prevVisible + itinerariesPerPage);
  // };

  // const switchGroup = (group:any) => {
  //   setCurrentGroup(group);
  //   setVisibleItineraries(itinerariesPerPage); // Reset visible itineraries when switching groups
  // };



  // const ButtonIcons = (): JSX.Element => {
  //   return (
  //   <>
  //     <IconButton
  //       iconProps={{ iconName: "ChevronLeft" }}
  //       onClick={(e) => setCurrentGroup(Math.max(0, currentGroup.length - 1))}
  //       disabled={selectedIndex == 0}
  //     ></IconButton>
  //     <IconButton
  //       iconProps={{ iconName: "ChevronRight" }}
  //       onClick={(e) =>
  //         setCurrentGroup(
  //           Math.min(
  //             groupedItineraries.key.length - 1, currentGroup + 1)
  //         )
  //       }
  //       disabled={currentGroup.length == groupedItineraries.key.length - 1}
  //     ></IconButton>
  //   </>
  //   );

  //   // <Stack horizontal horizontalAlign="center">
  //   //   {ButtonIcons()}
  //   // </Stack>
  // }

  // {groupedItineraries[currentGroup]
  //   .map((itinerary, index) => (
  //     <li key={index}>
  //       {/* itinerary details here. maybe toss the rest of the display here.  */}
  //       {itinerary.segments[0].origin} to {itinerary.segments[0].destination}
  //     </li>
  //   ))}

  


  return (
    <>
      <div>
        <ThemeProvider theme={airPanelTheme}>
          {/* <pre>{JSON.stringify(responseData)}</pre> */}

          <>

            <Stack>
            <StackItem style={{ paddingBottom: 5 }}>
              <Dropdown
                options={sortOptions}
                defaultSelectedKey="lowestFare"
                placeholder="Sort By"
                styles={dropdownStyles}
              />
             </StackItem>
            
            <Stack
            style={{
              border: "solid",
              overflow: "visible",
              borderWidth: 1,
              borderColor: "#baa65e",
              padding: 0,
            }}
            tokens={{ childrenGap: 5 }}>
              Airline Box 

              {responseData?.itineraries?.map((airline, airlineIndex)=> (
                
                <div key={airlineIndex}>
                {airline.segments.map((airlineBox, airlineBoxIndex) => (
                  <div key={airlineBoxIndex}>
                            <Stack
                              horizontal 
                              style={{ padding: 5 }}
                            >
                              
                              {airlineBox.marketingChain}{responseData?.itineraries.map((pricey)=>{pricey.quotes.map((mark)=>{mark.fares.map((pass)=>{pass.pricePerPassengerTypes.totalPrice})})})}
                            </Stack>
                  </div>

                ))}

                </div>
              ))}

              
            </Stack>

            <Stack
            style={{
              border: "solid",
              overflow: "visible",
              borderWidth: 1,
              borderColor: "#baa65e",
              padding: 0,
            }}
            tokens={{ childrenGap: 5 }}
            >
            Filter box

            
            <StackItem>
              Filter By Airlines
              

            </StackItem>
            </Stack>  
            
            

            {responseData &&
            responseData.itineraries &&
            responseData.itineraries.length > 0 ? (
              responseData?.itineraries?.map((itinerary, itineraryIndex) => (
                <>
                  <Stack
                    style={{
                      border: "solid",
                      overflow: "visible",
                      borderWidth: 1,
                      borderColor: "#baa65e",
                      padding: 0,
                    }}
                    tokens={{ childrenGap: 5 }}
                  >
                    <div key={itineraryIndex}>
                      {/* itinerary details here */}
                      <h4>Itinerary {itineraryIndex + 1}</h4>
                      {/* Map and display segments */}
                      {itinerary.segments.map((segment, segmentIndex) => (
                        <div key={segmentIndex}>
                          <Stack
                            styles={stackStyles}
                            horizontal
                            horizontalAlign="space-around"
                            verticalAlign="space-evenly"
                          >
                            <Stack
                              verticalAlign="center"
                              style={{ padding: 5 }}
                            >
                              <StackItem style={{ color: "#9d8a47" }}>
                                Airline:
                              </StackItem>
                              <br />{" "}
                              {airlineKeyToText(segment.marketingChain.toString())}
                              {/* {responseData.itineraries[itineraryIndex].segments
                                .map((x) => x.marketingChain)
                                .join(" ")
                                .trim()
                                .replace(" ", "/")
                                .toString()} */}
                            </Stack>
                            <Stack
                              verticalAlign="center"
                              style={{ padding: 5 }}
                            >
                              <StackItem style={{ color: "#9d8a47" }}>
                                Flight Number:
                              </StackItem>{" "}
                              <br /> {segment.marketingChain.toString()}{segment.marketingFlightNumber.toString()}
                            </Stack>
                            <Stack
                              verticalAlign="center"
                              style={{ padding: 5 }}
                            >
                              <Stack style={{ color: "#9d8a47" }}>From: </Stack>
                              <br />
                              {segment.origin.toString()}
                            </Stack>
                            <Stack
                              verticalAlign="center"
                              style={{ padding: 5 }}
                            >
                              <Stack style={{ color: "#9d8a47" }}>
                                Departure Time:{" "}
                              </Stack>
                              <br />{" "}
                              {formatCustomDate(segment.departureDateTime?.toString())}
                            </Stack>
                            <Stack
                              verticalAlign="center"
                              style={{ padding: 5 }}
                            >
                              <Stack style={{ color: "#9d8a47" }}>
                                Destination:{" "}
                              </Stack>
                              <br /> {segment.destination.toString()}
                            </Stack>
                            <Stack
                              verticalAlign="center"
                              style={{ padding: 5 }}
                            >
                              <Stack style={{ color: "#9d8a47" }}>
                                Arrival Time:{" "}
                              </Stack>
                              <br /> {formatCustomDate(segment.arrivalDateTime?.toString())}
                            </Stack>

                            <Stack
                              verticalAlign="center"
                              style={{
                                textSizeAdjust: "auto",
                                wordWrap: "normal",
                                whiteSpace: "nowrap",
                                padding: 5,
                              }}
                            >
                              <Stack horizontal>
                                <StackItem style={{ color: "#9d8a47" }}>
                                  Type: &nbsp;
                                </StackItem>
                                <StackItem style={{ color: "white" }}>
                                  {" "}
                                  {segment.operatingEquipment?.name?.toString()}
                                </StackItem>
                              </Stack>
                              <Stack horizontal>
                                <StackItem style={{ color: "#9d8a47" }}>
                                  Operated By: &nbsp;
                                </StackItem>{" "}
                                <StackItem style={{ color: "white" }}>
                                  {" "}
                                  {segment.operatingChain?.toString()}
                                </StackItem>
                              </Stack>
                              <Stack horizontal>
                                <StackItem style={{ color: "#9d8a47" }}>
                                  Duration:&nbsp;
                                </StackItem>
                                <StackItem style={{ color: "white" }}>
                                  {segment.duration?.toString()}
                                </StackItem>
                              </Stack>
                              <Stack horizontal>
                                <StackItem style={{ color: "#9d8a47" }}>
                                  Miles: &nbsp;
                                </StackItem>
                                <StackItem style={{ color: "white" }}>
                                  {segment.miles?.toString()}
                                </StackItem>
                              </Stack>
                              <Stack horizontal>
                                <StackItem style={{ color: "#9d8a47" }}>
                                  Class of Service: &nbsp;
                                </StackItem>
                                <StackItem style={{ color: "white" }}>
                                  {segment.classOfService == "none provided"
                                    ? segment.cabin
                                    : segment.classOfService}
                                </StackItem>
                              </Stack>
                              <Stack horizontal>
                                <StackItem style={{ color: "#9d8a47" }}>
                                  Fare Basis Code: &nbsp;
                                </StackItem>
                                <StackItem style={{ color: "white" }}>
                                  {segment.fareBasisCode?.toString()}
                                </StackItem>
                              </Stack>
                            </Stack>
                          </Stack>
                        </div>
                      ))}

                      <PrimaryButton
                        onClick={(e) => {
                          {
                            toggleNestedArrays(itineraryIndex);
                          }
                        }}
                      >
                        {showNestedArrays[itineraryIndex]
                          ? "Hide Fares"
                          : "Show Fares"}{" "}
                        &nbsp;
                        <Icon iconName="ChevronDown" />
                      </PrimaryButton>

                      {showNestedArrays[itineraryIndex] && (
                        <ul key={itineraryIndex}>
                          {itinerary.quotes
                            .sort((a: any, b: any) => a.totalItin - b.totalItin)
                            .map((quote, quoteIndex) => (
                              <Stack
                                horizontal
                                style={{ padding: 10 }}
                                horizontalAlign="space-around"
                                verticalAlign="space-evenly"
                              >

                                <div key={quoteIndex}>
                                  {/* Display quote details here */}
                                  <Stack
                                    
                                    style={{ padding: 10 }}
                                    
                                    verticalAlign="space-evenly"
                                  >
                                    <Stack horizontal
                                    style={{ padding: 10 }}
                                    horizontalAlign="space-around"
                                    verticalAlign="space-evenly">
                                    <StackItem style={{ padding: 10 }}>
                                      <Label style={{ color: "#9d8a47" }}>
                                        Quoted City:
                                      </Label>{" "}
                                      {quote.quotedCity?.toString()}
                                    </StackItem>
                                    <StackItem style={{ padding: 10 }}>
                                      <Label style={{ color: "#9d8a47" }}>
                                        Total Itin:{" "}
                                      </Label>{" "}
                                      ${quote.totalItin.toFixed(2)}
                                      </StackItem>
                                      <StackItem style={{ padding: 10 }}>
                                      <Label style={{ color: "#9d8a47" }}>
                                        Is NDC:
                                      </Label>{" "}
                                      {quote.isNDC ? "Yes" : "No"}
                                    </StackItem>

                                    {quote.fares.map((fares, fareIndex) => (
                                      <div key={fareIndex}>
                                        {/* Display fare details here */}
                                        <Stack horizontal
                                          style={{ padding: 10 }}
                                          horizontalAlign="space-around"
                                          verticalAlign="space-evenly">
                                          <StackItem>
                                          <Label style={{ color: "#9d8a47" }}>
                                            Bags:{" "}
                                          </Label>
                                          {fares.bags.toString()}
                                          </StackItem>
                                        
                                        <StackItem>
                                          <Label style={{ color: "#9d8a47" }}>
                                            Price Per Passenger:{" "}
                                          </Label>
                                          {Array.isArray(
                                            fares.pricePerPassengerTypes
                                          ) ? (
                                            fares.pricePerPassengerTypes.map(
                                              (passenger, passengerIndex) => (
                                                <div key={passengerIndex}>
                                                  <>
                                                    {passenger.passengerType ==
                                                    "ADT"
                                                      ? "Adult"
                                                      : passenger.passengerType ==
                                                        "CHD"
                                                      ? "Child"
                                                      : "Infant"}
                                                    : $
                                                    {passenger.totalPrice.toFixed(
                                                      2
                                                    )}
                                                  </>
                                                </div>
                                              )
                                            )
                                          ) : (
                                            <div>
                                              <p>
                                                Passenger Type:{" "}
                                                {fares.pricePerPassengerTypes
                                                  .passengerType == "ADT"
                                                  ? "Adult"
                                                  : fares.pricePerPassengerTypes
                                                      .passengerType == "CHD"
                                                  ? "Child"
                                                  : "Infant"}
                                                , Total Price: $
                                                {fares.pricePerPassengerTypes.totalPrice.toFixed(
                                                  2
                                                )}
                                              </p>
                                            </div>
                                          )}
                                        </StackItem>
                                        
                                        {/*                                         
                                        <StackItem>
                                          <Label style={{ color: "#9d8a47" }}>
                                            Total Tax:{" "}
                                          </Label>
                                          ${fares.totalTax.toString()}
                                        </StackItem> */}

                                        </Stack>
                                        
                                        
                                        {/* <Stack
                                          style={{
                                            width: 300,
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <Label style={{ color: "#9d8a47" }}>
                                            System ID:{" "}
                                          </Label>
                                          {fares.systemId == "not found"
                                            ? "In Sabre GDS"
                                            : fares.systemId.toString()}
                                        </Stack> */}
                                        <br/><br/>
                                        <Stack tokens={{childrenGap: 40, maxWidth:200}}>
                                         <PrimaryButton
                                            text="Price & Save"
                                            wrap="false"
                                            style={{}}
                                            iconProps={{ iconName: "Copy" }}
                                            onClick={(e) => {
                                              copyData(
                                                itinerary.segments,
                                                quote,
                                                fares
                                              );
                                            }}
                                          />
                                        </Stack>
                                      </div>
                                    ))}
                                    </Stack>
                                  </Stack>
                                </div>
                              </Stack>
                            ))}
                        </ul>
                      )}
                    </div>
                  </Stack>
                </>
              ))
            ) : (
              <>
                <div>No itineraries available.</div>
                <br />
                <PrimaryButton
                  onClick={(e) => {
                    window.location.reload();
                  }}
                  text="Continue?"
                />
              </>
            )}

            </Stack>



          </>

          {selectedItinerary && (
            <ItineraryModal
              selectedData={selectedItinerary}
              isOpen={isModalOpen}
              onClose={handleCloseModal}
            />
          )}
        </ThemeProvider>
      </div>
    </>
  );
};
