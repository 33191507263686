export enum ePaymentType {
  Visa = "VI",
  MasterCard = "CA",
  Discover = "DS",
  DinersClub = "DC",
  AmericanExpress = "AX",
}
export enum eCardChoice {
  "AX" = 283240000,
  "VI" = 283240001,
  "CA" = 283240002,
  "DC" = 283240003,
  "DS" = 283240004,
  "NS" = 0,
}
export enum eTravelerType {
  Adult = "ADT",
  Child = "CHI",
  Infant = "INF",
}
export enum eGender {
  Male = "M",
  Female = "F",
  Unspecified = "X",
  Undisclosed = "U",
}

export enum eColumnType {
  readOnly = 0,
  twoOption = 1,
  text = 2,
  date = 3,
  picklist = 4,
  lookup = 5,
  secure = 6,
  signature = 7,
}
export enum eGridOperation {
  add,
  delete,
  update,
  cancel,
}

export enum eMealCode {
  NoPreference = 283240000,
  GlutenFree = 283240001,
  Vegetarian = 283240002,
  NonDairy = 283240003,
  Other = 283240004,
  None = 283240005,
}

export enum ePreferredClassOfService {
  Economy = 283240000,
  PremiumEconomy = 283240001,
  Business = 283240002,
  First = 283240003,
}

export enum ePreferredSeating {
  Window = 283240000,
  Aisle = 283240001,
  NoPreference = 283240002,
}

export enum eSearchStatus {
  NotSearching, 
  Searching, 
  NoResults, 
  Results, 
  ServerError
}

export enum PreferLevelType {
  Only,
  Unacceptable,
  Preferred
}

export enum FlightTypeType {
  Nonstop,
  Direct,
  Connection
}

export enum eAirSearchStatus{
  NotSearching, 
  Searching, 
  NoResults, 
  Results, 
  ServerError, 
  Loading
}

export interface ShoppingRequest {
  tripType:TripType;
  travelers:TravelerTypes;
  preferredCarriers?:{[key:string]:PreferenceLevel}|null;
  itin:ItineraryRequest[];
}

export interface TravelerTypes
    {
        aDT?: number|null;
        cHD?: number|null;
        iNF?: number|null;
    }
    export interface ItineraryRequest{
        classOfService?:ClassOfService|null;
        orig:string;
        dest:string;
        departureDate:string|Date;
        departureTime?:string|null;
        departureWindow?:string|null;
        maxConnections?:number|null;
        preferredConnections?:{[key:string]:PreferenceLevel}|null;
    }
    export interface ClassOfService
    {
        premiumFirst?:PreferenceLevel|null;
        first?:PreferenceLevel|null;
        premiumBusiness?:PreferenceLevel|null;
        business?:PreferenceLevel|null;
        premiumEconomy?:PreferenceLevel|null;
        economy?:PreferenceLevel|null;
        y?:PreferenceLevel|null;
        s?:PreferenceLevel|null;
        c?:PreferenceLevel|null;
        j?:PreferenceLevel|null;
        f?:PreferenceLevel|null;
        p?:PreferenceLevel|null;
    }
    export enum TripType
    {
        RoundTrip = 0,
        OneWay = 1,
        MultiCity = 2
    }
    export enum PreferenceLevel
    {
        None = 0,
        Preferred = 1,
        Only = 2
    }

    export interface ShoppingResponse{
      errorMessage?:string|null;
      itineraries:ShoppedItinerary[];
  }

  export interface ShoppedItinerary{
      operatingFlightNumber:string;
      operatingChain:string;
      origin:string;
      destination:string;
      departureDateTime?:Date|null;
      arrivalDateTime?:Date|null;
      operatingEquipment:any;
      duration?:string|null;
      miles?:string|null;
      emissions?:string|null;
      quotes:ItineraryQuote[];
  }
  export interface ItineraryQuote{
      quotedCity:string;
      isNdc?:boolean|null;
      totalItin:string;
      fares:ItineraryQuotedFare[];

  }
  export interface ItineraryQuotedFare{
      marketingFlightNumber:string;
      marketingChain:string;
      marketingEquipment?:any;
      TotalFarePerPax:string;
      TotalTax:string;
      Cabin:string;
      classOfService:string;
      bags?:number|null;
      systemId:string;

  }
