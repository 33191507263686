import {
  addDays,
  addMonths,
  Checkbox,
  ComboBox,
  DatePicker,
  
  IComboBox,
  IComboBoxOption,
  IComboBoxStyles,
  IDatePicker,
  IDatePickerStyles,
  IDropdownOption,
  ILabelStyles,
  IStackStyles,
  IStackTokens,
  IStyleSet,
  Label,
  Pivot,
  PivotItem,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  Dropdown,
  StackItem,
  createTheme,
  ThemeProvider,
  ITextFieldProps,
  IconButton,
  mergeStyleSets,
  Callout,
  DefaultButton,
} from "@fluentui/react";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import "./Booking.css";
import { eAirSearchStatus, eSearchStatus } from "../../Contexts/Enums";
import axios, { AxiosResponse } from "axios";
import * as PortalModels from "../../Contexts/Models";
import { ComboboxProps, Portal } from "@fluentui/react-components";
import { useBoolean, useId } from "@fluentui/react-hooks";
import FuzzyComboBox from "./fuzzyAirList";
import DateSelector from "./DateSelector";
import OneWayDateSelector from "./OneWayDate";
import citiesData from "./CitiesData";

const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: { maxWidth: 200, background: "white", fill: "white" },
};
const headingStackTokens: IStackTokens = { childrenGap: 30 };

const datePickerStyles: Partial<IDatePickerStyles> = {
  root: { maxWidth: 300, marginTop: 15 },
};

const customComboBoxStyles:Partial<IComboBoxStyles> = {
  root: {
    color: "#ffffff", 
    maxWidth:200,
  },
  
  input: {
    color: '#ffffff', // Set your desired text color here
  },
  optionsContainer:{
    color:'#ffffff', 
  }
  
};
const stackTokens: IStackTokens = {
  childrenGap: 4,
  maxWidth: 300,
};

const classOfServiceTheme = createTheme({
  fonts: {
    small: {
      fontSize: "10px",
      fontWeight: "semibold",
    
    },
    medium: {
      fontSize: "17px",
      fontWeight: "semibold",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },

  palette: {
    themePrimary: "#b39e51",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#b39e51",
    themeDark: "#918e2a",
    themeDarker: "#004578",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#918e2a",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#b39e51",
    neutralPrimaryAlt: "#ffffff",
    neutralPrimary: "#ffffff",
    neutralDark: "#ffffff",
    black: "#000000",
    white: "#282828",
  },
  
 
});

export interface IOneWayProps {
  airportLocations: IComboBoxOption[];
  //onUpdateItinerary (updatedItinerary: PortalModels.ItineraryRequest): void;
  //onCountChange: (counts: PortalModels.TravelerTypes) => void;
}

export interface OnewaySearchProps {
  airportLocations: IComboBoxOption[];
  onTripInfoChange: (tripInfo: PortalModels.ShoppingRequest) => void;
  onSubmitSearch: any;
}

// export interface ShoppingRequest {
//   tripType: PortalModels.TripType;
//   itin: PortalModels.ItineraryRequest[];
//   travelerCounts: PortalModels.TravelerTypes;
//   preferredCarriers?: { [key: string]: PreferenceLevel } | null;
// }

// export interface ClassOfService {
//   premiumFirst?: PreferenceLevel | null;
//   first?: PreferenceLevel | null;
//   premiumBusiness?: PreferenceLevel | null;
//   business?: PreferenceLevel | null;
//   premiumEconomy?: PreferenceLevel | null;
//   economy?: PreferenceLevel | null;
//   y?: PreferenceLevel | null;
//   s?: PreferenceLevel | null;
//   c?: PreferenceLevel | null;
//   j?: PreferenceLevel | null;
//   f?: PreferenceLevel | null;
//   p?: PreferenceLevel | null;
// }
// export enum TripType {
//   RoundTrip = 0,
//   OneWay = 1,
//   MultiCity = 2,
// }
// export enum PreferenceLevel {
//   None = 0,
//   Preferred = 1,
//   Only = 2,
// }
// export interface IOneWayState {
//   searchStatus: eAirSearchStatus;
//   onSearchOneWay: OnewaySearchProps;
// }
export interface CitiesResponse {
  key: string;
  text: string;
}

const onFormatDate = (date?: any): string => {
  if (!date) {
    return "";
  } else {
    return (
      date?.getMonth() + 1 + "/" + date?.getDate() + "/" + date?.getFullYear()
    );
  }
};

const OneWaySearch = (
  props: PortalModels.ShoppingRequest & OnewaySearchProps
) => {
  const [itinerary, setItinerary] = useState<PortalModels.ItineraryRequest>({
    orig: "",
    dest: "",
    departureDate: "",
    departureWindow: null,
    maxConnections: null,
    departureTime: null,
    preferredConnections: null,
    classOfService: null,
    
  });
  const [travCount, setTravCount] = useState<PortalModels.TravelerTypes>({
    aDT: 1,
    cHD: 0,
    iNF: 0,
  });

  //classOfService
  const labelId: string = useId("label");
  const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: "#b39e51" }],
  });
  const labelCalloutStackStyles: Partial<IStackStyles> = {
    root: { padding: 20, color: 'white' },
    
  };
  const iconProps = { iconName: "Info" };
  const CustomLabel = (props: ITextFieldProps): JSX.Element => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
      useBoolean(false);
    const descriptionId: string = useId("description");
    const iconButtonId: string = useId("iconButton");
  
    return (
      <>
        
          <Stack horizontal verticalAlign="center" horizontalAlign="center" tokens={stackTokens}>
            <Label id={props.id}>
              Class of Service
            </Label>
            <IconButton
              id={iconButtonId}
              iconProps={iconProps}
              title="Format"
              ariaLabel="Format"
              onClick={toggleIsCalloutVisible}
              className={classNames.deepSkyBlue}
            />
          </Stack>
          {isCalloutVisible && (
            <Callout
              target={"#" + iconButtonId}
              setInitialFocus
              onDismiss={toggleIsCalloutVisible}
              ariaDescribedBy={descriptionId}
              role="alertdialog"
            >
              <Stack
                tokens={stackTokens}
                horizontalAlign="start"
                styles={labelCalloutStackStyles}
                
              >
                <span id={descriptionId}>
                 Max number of class selections is 3. 
                 If you want all classes of Service, leave blank 
                 and it will return all. 
                </span>
                <DefaultButton onClick={toggleIsCalloutVisible}>
                  Close
                </DefaultButton>
              </Stack>
            </Callout>
          )}
        
      </>
    );
  };

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedClassOfService, setSelectedClassOfService] = useState<PortalModels.ClassOfService>({
    premiumFirst: null,
    first: null,
    premiumBusiness: null,
    business: null,
    premiumEconomy: null,
    economy: null,
    y: null,
    s: null,
    c: null,
    j: null,
    f: null,
    p: null,
  });
  
  const classOfServiceOptions: IComboBoxOption[] = [
    { key: 'premiumFirst', text: 'Premium First' },
    { key: 'first', text: 'First' },
    { key: 'premiumBusiness', text: 'Premium Business' },
    { key: 'business', text: 'Business' },
    { key: 'premiumEconomy', text: 'Premium Economy' },
    { key: 'economy', text: 'Economy' },
    { key: 'y', text: 'Y' },
    { key: 's', text: 'S' },
    { key: 'c', text: 'C' },
    { key: 'j', text: 'J' },
    { key: 'f', text: 'F' },
    { key: 'p', text: 'P' },
  ];

  const handleSelectionChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {

    if (option) {
      const selectedKeys = option.selected 
      ? [...selectedOptions, option.key as string] : 
      selectedOptions.filter(key => key !== option.key);
      //setSelectedOptions(selectedKeys);
    // Update selectedClassOfService based on selectedOptions

    if (selectedKeys.length <= 3) {
      setSelectedOptions(selectedKeys);

    const updatedClassOfService: PortalModels.ClassOfService = {
      premiumFirst: null,
      first: null,
      premiumBusiness: null,
      business: null,
      premiumEconomy: null,
      economy: null,
      y: null,
      s: null,
      c: null,
      j: null,
      f: null,
      p: null,
    };

    selectedKeys.forEach(key => {
      updatedClassOfService[key] = selectedKeys.length === 1 ? PortalModels.PreferenceLevel.Only : PortalModels.PreferenceLevel.Preferred;
    });

    setSelectedClassOfService(updatedClassOfService);
    setItinerary({
      ...itinerary,
      classOfService:updatedClassOfService
    })
  }
  
    console.log(`${selectedClassOfService}: Class`)
  }

  
    
    // const preferredClass: { [key: string]: PortalModels.PreferenceLevel } = {};

    // if (selectedOptions.length === 1) {
    //   // If one is selected, set to Only for that preference level
    //   updatedClassOfService[selectedOptions[0]] = PortalModels.PreferenceLevel.Only;
     
    // } else if (selectedOptions.length>1){
    //   // If more than one is selected, set all to Preferred
    //   selectedOptions.forEach((key) => {
    //     updatedClassOfService[key] = PortalModels.PreferenceLevel.Preferred;
    //   });
    // }
    
    // Object.keys(selectedClassOfService).forEach(key => {
    //   if (!updatedClassOfService[key]) {
    //     updatedClassOfService[key] = null;
    //   }
    // });

  };
  const handleSelect = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (selectedOptions.length >= 3) {
    
      event.preventDefault();
    }
  };
  
  const isPreferenceDisabled = selectedOptions.length >= 3;

  //maxConnections stuff max of 4 set set as dropdown

  const maxConnections: IDropdownOption[] = [
    { key: 0, text: "Direct Only" },
    { key: 1, text: "1 Stop" },
    { key: 2, text: "2 Stops"},
    { key: 3, text: "3 Stops"},
    { key: 4, text: "4 Stops" },
    
  ];



  //date stuff

  const handleSelectDepartureDate = (selectedDate: Date | null | undefined) => {
    console.log("Selected Departure Date:", selectedDate);
    let isoDate = selectedDate?.toISOString();

    let formatDate = isoDate?.substring(0, 10);

    setItinerary({
      ...itinerary,
      departureDate: formatDate as string,
      departureTime: "17:00",
      departureWindow: "00002359",
    });
  };

  //handlers

  const handleItineraryRequestChange = (updatedItineraryRequest: PortalModels.ItineraryRequest) => {
    setItinerary(updatedItineraryRequest);
  };

  const handleTravelerCountsChange = (updatedTravelerCounts: PortalModels.TravelerTypes
  ) => {
    setTravCount(updatedTravelerCounts);
  };

  useEffect(() => {
    const tripInfo: PortalModels.ShoppingRequest = {
      tripType: PortalModels.TripType.OneWay,
      preferredCarriers: {},
      itin: [...(props.itin ?? []), itinerary],
      travelers: travCount,
      
    };
    props.onTripInfoChange(tripInfo);
  }, [itinerary, travCount, props.onTripInfoChange]);

  //airports

  //airports
  // const getOrigin = (): IComboBoxOption[]=>{
  //   return props.airportLocations.map((x)=>{return { key: x.key as string, text: x.text as string}}) ?? [];
  // }
  // const [enteredOrigin, setEnteredOrigin] = useState<any|null>(null);

  // const originHandler = (index:any)=>{
  //   setEnteredOrigin(index.key);

  // }

  // const getdestination = (): IComboBoxOption[]=>{
  //   return props.airportLocations.map((x)=>{return { key: x.key as string, text: x.text as string}}) ?? [];
  // }
  // const [enteredDest, setEnteredDest] = useState<any|null>(null);

  // const destHandler = (index:any)=>{
  //   setEnteredDest(index.key);

  // }

  const [filter,setFilter]=useState ('');
  const [filteredItems, setFilteredItems] = useState<CitiesResponse[]>([]);
  useEffect(() => {
    // Make backend API call with the filter value
    const fetchData = async () => {
      try {
        
        const response = await fetch(`https://prod-190.westus.logic.azure.com:443/workflows/a6a60b42c4164cb892117afccc6cb843/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=oi86GsVstDozeJT3ivgjyJZIu1V-OYkbtx17-VrdC2Q${filter}`);
        const data = await response.json();
        setFilteredItems(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (filter.length >= 3) {
      fetchData();
    } else {
      // Reset the filtered items when the filter is less than 3 characters
      setFilteredItems([]);
    }
  }, [filter]);

  const airports = (): CitiesResponse[] => {
    return (
      filteredItems?.map((x) => {
        return { key: x.key as string, text: x.text as string };
      }) ?? []
    );
  };

  const handleOriginSelect = (event: any) => {
    console.log(`${event.target.name}: ${event.target.value}`);

    setItinerary({
      ...itinerary,
      orig: event.target.value.slice(0, 3),
    });
  };

  const handleDestinationSelect = (event: any) => {
    console.log(`${event.target.name}: ${event.target.value}`);
    setItinerary({
      ...itinerary,
      dest: event.target.value.slice(0, 3),
    });
  };
  const handleCountChange = (onCountChange: PortalModels.TravelerTypes) => {
    setTravCount((prevCounts) => ({
      ...prevCounts,
      travelerType: onCountChange,
    }));

    // console.log(travelerType,countChange)
  };

  const handleMaxConnections= (event:any)=>{
    console.log(`${event.target.key}:${event.target.value}`)
    setItinerary({
      ...itinerary,
      maxConnections:event.target.value,
    })
  }

  //passengers

  const PassengerSelector = () => {
    const totalTravelers = Object.values(travCount).reduce(
      (total, count) => total + (count ?? 0),
      0
    );

    return (
      <>
        <Stack style={{ padding: 5 }} horizontal>
          <PrimaryButton
            text="-"
            onClick={() => {
              //setTravCount({aDT:(travCount.aDT??1)-1,cHD:travCount.cHD??0,iNF:travCount.iNF??0})
              setTravCount({ ...travCount, aDT: (travCount.aDT ?? 0) - 1 });
            }}
            disabled={travCount.aDT == 0}
            style={{ height: 15, width: 15, minWidth: 15, maxWidth: 15 }}
          />
          &nbsp;Adult: {travCount.aDT} &nbsp;
          <PrimaryButton
            text="+"
            type="button"
            onClick={() =>
              setTravCount({ ...travCount, aDT: (travCount.aDT ?? 0) + 1 })
            }
            disabled={totalTravelers == 9}
            style={{
              height: 15,
              width: 10,
              minWidth: 10,
              maxWidth: 10,
              textAlign: "center",
              textAnchor: "middle",
            }}
          />
        </Stack>

        <Stack horizontal>
          <PrimaryButton
            text="-"
            type="button"
            onClick={() =>
              setTravCount({ ...travCount, cHD: (travCount.cHD ?? 0) - 1 })
            }
            disabled={travCount.cHD == 0}
            style={{ height: 15, width: 15, minWidth: 15, maxWidth: 15 }}
          />
          &nbsp;Children:{travCount.cHD} &nbsp;
          <PrimaryButton
            text="+"
            type="button"
            onClick={() =>
              setTravCount({ ...travCount, cHD: (travCount.cHD ?? 0) + 1 })
            }
            disabled={totalTravelers == 9}
            style={{ height: 15, width: 15, minWidth: 15, maxWidth: 15 }}
          />
        </Stack>

        <Stack style={{ padding: 5 }} horizontal>
          <PrimaryButton
            text="-"
            type="button"
            onClick={() =>
              setTravCount({ ...travCount, iNF: (travCount.iNF ?? 0) - 1 })
            }
            disabled={travCount.iNF == 0}
            style={{ height: 15, width: 15, minWidth: 15, maxWidth: 15 }}
          />
          &nbsp;Infants: {travCount.iNF} &nbsp;
          <PrimaryButton
            text="+"
            type="button"
            onClick={() =>
              setTravCount({ ...travCount, iNF: (travCount.iNF ?? 0) + 1 })
            }
            disabled={totalTravelers == 9}
            style={{ height: 15, width: 15, minWidth: 15, maxWidth: 15 }}
          />
        </Stack>

        <Stack>Total Passengers = {totalTravelers}</Stack>
      </>
    );
  };

  return (
    <>
      <Stack verticalAlign="center">
        <Stack horizontalAlign="center">
          <PassengerSelector />
        </Stack>

        <Stack wrap enableScopedSelectors horizontal horizontalAlign="center">
          <Stack verticalAlign="center">
            {/* <hr style={{ height: 0.5, visibility: "hidden" }} /> */}

            <StackItem style={{ padding: 20 }}>
              <Label>Origin</Label>
               <FuzzyComboBox
                onSelect={handleOriginSelect}
                valueProps={itinerary.orig}
                airports={airports()}
                //airports={filteredItems.map(item => ({ key: item.key, text: item.text }))}
                //options={filteredItems.map(item => ({ key: item.key, text: item.text }))}
                //onInputChange={(value:string) => setFilter(value)}
                label="Origin"
              />
            </StackItem>
          </Stack>

          <Stack>
            <hr style={{ height: 0.5, visibility: "hidden" }} />
            <StackItem style={{ padding: 20 }}>
              <Label>Destination</Label>

              <FuzzyComboBox
                onSelect={handleDestinationSelect}
                airports={airports()}
                //airports={filteredItems.map(item => ({ key: item.key, text: item.text }))}
                //options={filteredItems.map(item => ({ key: item.key, text: item.text }))}
                //onInputChange={(value:string) => setFilter(value)}
                valueProps={itinerary.dest}
                label="Destination"
              />
            </StackItem>
          </Stack>

          <StackItem style={{ padding: 20 }}>
            <OneWayDateSelector
              onSelectDepartureDate={handleSelectDepartureDate}
              value={itinerary.departureDate}
            />
          </StackItem>

          <StackItem style={{ padding: 20 }}>
          <hr style={{ height: 0.5, visibility: "hidden" }} />

          <Dropdown
            label="Max Connections"
            options={maxConnections}
            styles={{dropdown:{width:150}}}
            onChange={(e, v) =>{
              console.log(`${v?.key} : maxconnect`)
              setItinerary({
                ...itinerary,
                maxConnections: v?.key as number,
              })
            }}
            
          />

          </StackItem>
            <ThemeProvider theme={classOfServiceTheme}>
          <StackItem style={{ padding: 20 }}>
            <hr style={{ height: 0.5, visibility: "hidden" }} />
            <ComboBox
              multiSelect
              label="Select Class of Service"
              aria-labelledby={labelId}
              allowFreeform={false}
              styles={customComboBoxStyles}
              options={classOfServiceOptions}
              onChange={handleSelectionChange}
              onSelect={handleSelect}
              onRenderLabel={(props) => (
                <CustomLabel id={labelId} {...props} />
              )}/>
            <StackItem style={{color: "black", padding:1, fontSize:15}} hidden={selectedOptions.length !=3}>
            Only 3 classes of services can be selected. Please leave blank if you want to search all. 
            </StackItem>
          </StackItem>
          </ThemeProvider>
        </Stack>

        <Stack horizontalAlign="center">
          <PrimaryButton
            text="Search"
            styles={{ root: { width: 200 } }}
            disabled={travCount.aDT==0 || itinerary.departureDate== null || itinerary.orig=="".trim()|| itinerary.dest=="".trim()}
            onClick={() => {
              console.log("one way search button clicked");
              handleItineraryRequestChange(itinerary);
              handleTravelerCountsChange(travCount);
              props.onSubmitSearch();
              // props.onCountChange(travCount);
              // props.onUpdateItinerary(itinerary);
              console.log(itinerary);
            }}
          />
        </Stack>

        {/* <Stack horizontalAlign="center">
        <StackItem style={{ padding: 20 }}>
          <a
            className="more-link"
            style={{
              color: "#E3E3E3",
              cursor: "pointer",
              textDecorationLine: "underline",
            }}
            onClick={() => setMoarOptions(!moaroptions)}
          >
            {" "}
            {linkName}
          </a>{" "}
          {moaroptions && extraSettings}
        </StackItem>
      </Stack> */}
      </Stack>
    </>
  );
};

export default OneWaySearch;
