
import { eCardChoice } from '../Contexts/Enums';

export function CreditCardType(cc: string | undefined) {
  if (!cc) return undefined;
  cc=cc.replaceAll(" ","");
  
  let amex = new RegExp('^3[47][0-9]{13}$');
  let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');

  let mastercard = new RegExp('^5[1-5][0-9]{14}$');
  let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

  let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

  let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');


  if (visa.test(cc)) {
    return eCardChoice.VI;
  } else if (amex.test(cc)) {
    return eCardChoice.AX;
  } else if (mastercard.test(cc) || mastercard2.test(cc)) {
    return eCardChoice.CA;
  } else if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    return eCardChoice.DS;
  } else if (diners.test(cc)) {
    return eCardChoice.DC;
  } else {return eCardChoice.NS;}
  
}
export function CcCheksum(ccNum: string | undefined): boolean {
  if (!ccNum) return false;
  ccNum = ccNum.replace(/\D/g, '');
  if (ccNum.length === 0) return false;
  if (!CreditCardType(ccNum)) {
    return false;
  }
  let isOddLength = ccNum.length % 2 != 0;
  let checksum = 0;
  for (let i = ccNum.length - 1; i >= 0; i--) {
    if ((isOddLength && i % 2 == 0) || (!isOddLength && i % 2 != 0)) {
      checksum += parseInt(`${ccNum.charAt(i)}`);
    }
    else
      {
        var t = parseInt(`${ccNum.charAt(i)}`) * 2;
        var j = 0;
        while (t >= 10) {
          ++j;
          t -= 10;
        }
        checksum += j + t;
      }
    }
    return checksum % 10 == 0;
  }
//AMEX length 15
//diners Club 14 or 16
//discover 16 or 19
//mastercard 16
//visa 13 or 16 or 19

