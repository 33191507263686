import * as React from "react";
import { useId, useBoolean } from "@fluentui/react-hooks";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  IIconProps,
  IStackProps,
  createTheme,
  ThemeProvider,
  Stack,
  Text,
  StackItem,
  Label,
  Separator,
  IStackTokens,
  IStackStyles,
  IComboBoxOption,
} from "@fluentui/react";
import {
  DefaultButton,
  IconButton,
  IButtonStyles,
  PrimaryButton,
} from "@fluentui/react/lib/Button";
import * as PortalModels from "../Contexts/Models";
import { TravelerProfilesComponent } from "./TravelerProfilesComponent";
import { profile } from "console";
import { useState } from "react";

export interface IConfirmationProps {
  profiles: PortalModels.TravelerProfiles[];
  submitInfo(): void;
  isModalOpen: boolean;
  hideModal(): void;
  meals:IComboBoxOption[];
  wheelchair:IComboBoxOption[];

}

const stackTokens: Partial<IStackTokens> = { childrenGap: 12 };

const travelPanelTheme = createTheme({
  fonts: {
    small: {
      fontSize: "15px",
    },
    medium: {
      fontSize: "17px",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },
  palette: {
    themePrimary: "#454545",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#b39e51",
    themeDark: "#918e2a",
    themeDarker: "#004578",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#323130",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    bodyText: "#323130",
  },
});

const ProfileList = (props: IConfirmationProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const ButtonIcons = (): JSX.Element => {
    return (
    <>
      <IconButton
        iconProps={{ iconName: "ChevronLeft" }}
        onClick={(e) => setSelectedIndex(Math.max(0, selectedIndex - 1))}
        disabled={selectedIndex == 0}
      ></IconButton>
      <IconButton
        iconProps={{ iconName: "ChevronRight" }}
        onClick={(e) =>
          setSelectedIndex(
            Math.min(props.profiles.length - 1, selectedIndex + 1)
          )
        }
        disabled={selectedIndex == props.profiles.length - 1}
      ></IconButton>
    </>
    );
    // if (selectedIndex > 0 && selectedIndex < props.profiles.length - 1) {
    //   return (
    //     <>
    //       <IconButton
    //         iconProps={{ iconName: "ChevronLeft" }}
    //         onClick={(e) => setSelectedIndex(Math.max(0, selectedIndex - 1))}
    //         disabled={selectedIndex == 0}
    //       ></IconButton>
    //       <IconButton
    //         iconProps={{ iconName: "ChevronRight" }}
    //         onClick={(e) =>
    //           setSelectedIndex(
    //             Math.min(props.profiles.length - 1, selectedIndex + 1)
    //           )
    //         }
    //         disabled={selectedIndex == props.profiles.length - 1}
    //       ></IconButton>
    //     </>
    //   );
    // } else if (selectedIndex ==0) {
    //   return (
    //     <>
    //     <StackItem/>
    //       <IconButton
    //         iconProps={{ iconName: "ChevronRight" }}
    //         onClick={(e) =>
    //           setSelectedIndex(
    //             Math.min(props.profiles.length - 1, selectedIndex + 1)
    //           )
    //         }
    //         disabled={selectedIndex == props.profiles.length - 1}
    //       ></IconButton>
    //     </>
    //   );
    // } else {
    //   return (
    //     <>
    //       <IconButton
    //         iconProps={{ iconName: "ChevronLeft" }}
    //         onClick={(e) => setSelectedIndex(Math.max(0, selectedIndex - 1))}
    //         disabled={selectedIndex == 0}
    //       ></IconButton>
    //     </>
    //   );
    // }
  };
  return (
    <Stack>
      {props.profiles.map((x, i) => {
        if (i != selectedIndex) {
          return <></>;
        } else {
          return (
            <>
              <StackItem>
                <Label id={"name" + i}>
                  Name: <Text variant="small">{x.lastName}/{x.firstName} {x.middleName ?? ""}</Text>
                </Label>
              </StackItem>
              <StackItem>
                <Label id={"dob" + i}>
                  Date of Birth:<Text variant="small">{" "}
                  {x.dateOfBirth
                    ? new Date(x.dateOfBirth).toLocaleDateString("en-US")
                    : new Date().toLocaleDateString("en-US")}</Text>
                </Label>
              </StackItem>
              <StackItem>
                <Label id={"gender" + i}>
                  Gender:<Text variant="small">{" "}
                  {x.gender == 1
                    ? "M"
                    : x.gender == 2
                    ? "F"
                    : x.gender == 283240002
                    ? "X"
                    : "U"}</Text>
                </Label>
              </StackItem>
              <StackItem>
                <Label id={"email" + i}>Email: <Text variant="small">{x.email} </Text></Label>
              </StackItem>
              <StackItem>
                <Label id={"phone" + i}>
                  Phone Number: <Text variant="small">{x.telephone?.trim() ?? ""}</Text>
                </Label>
              </StackItem>
              <StackItem>
                <Label id={"citizen" + i}>Citizenship: <Text variant="small">{x.citizenship}</Text></Label>
              </StackItem>
              <StackItem>
                <Label id={"citnum" + i}>
                  Passport Number: <Text variant="small">{x.passportNumber}</Text>
                </Label>
                <Label id={"issue" + i}>
                  Passport Issuing Country: <Text variant="small">{x.passportIssuingCountry}</Text>
                </Label>
                <Label id={"expir" + i}>
                  Passport Expiration: <Text variant="small">{x.passportExpiration
                    ? new Date(x.passportExpiration).toLocaleDateString("en-US")
                    : ""}</Text>
                </Label>
              </StackItem>
              <StackItem>
                <Label id={"Global" + i}>Global Entry: <Text variant="small">{x.globalEntry}</Text></Label>
              </StackItem>
              <StackItem>
                <Label id={"Redress" + i}>
                  Redress Number: <Text variant="small">{x.redressNumber}</Text>
                </Label>
              </StackItem>
              <StackItem>
                <Label id={"Known" + i}>
                  Known Traveler: <Text variant="small">{x.knownTraveler}</Text>
                </Label>
              </StackItem>
              <StackItem>
                <Label id={"TSA" + i}>TSA Precheck: <Text variant="small">{x.tsaPrecheck}</Text></Label>
              </StackItem>
              <StackItem>
                <Label id={"Wheelchair Needs" + i}>Wheelchair Needs: <Text variant="small">{props.wheelchair.find(t => t.key == x.wheelchair)?.text}</Text></Label>
              </StackItem>
              <StackItem>
                <Label id={"Meal Preference" + i}>Meal Preference: <Text variant="small">{props.meals.find(t => t.key == x.mealOther)?.text}</Text></Label>
              </StackItem>
              <StackItem>
                <Label id={"FF" + i}>Frequent Flyer:</Label>
                <Stack horizontal wrap tokens={stackTokens}>
                  {" "}
                  {x.frequentGuestInfo.map((y) => {
                    return (
                      <StackItem>
                        <Text variant="small">
                          {y.chainCode} {y.guestNumber}
                        </Text>
                      </StackItem>
                    );
                  })}
                </Stack>
              </StackItem>
            </>
          );
        }
      })}
      <Stack horizontal horizontalAlign="center">
        {ButtonIcons()}
      </Stack>
    </Stack>
  );
};

const stackStyles: IStackStyles = {
  root: {
      width: '30em',
    
  },
};

export const ConfirmationModal = (props: IConfirmationProps) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(
    props.isModalOpen
  );

  const titleId = useId("title");

  return (
    <ThemeProvider theme={travelPanelTheme}>
      <Modal
        theme={travelPanelTheme}
        titleAriaId={titleId}
        isOpen={props.isModalOpen}
        onDismiss={hideModal}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <Text variant={"xxLarge"} id={titleId}>
            Confirm Info
          </Text>
          {/* <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close modal"
            onClick={hideModal}
          /> */}
        </div>
        <div className={contentStyles.body}>
          <Stack wrap styles={stackStyles}><Text variant={"medium"}>
            Please confirm that all the information is accurate. Traveler will not be able to be removed after submission. Please contact
            your Global First Travel representative for any changes needed after
            submission. <b>Note: </b>Adding Profile does not book the trip. 
          </Text></Stack>
          <Separator />
          <div className={contentStyles.body}>
            <ProfileList
              profiles={props.profiles}
              submitInfo={props.submitInfo}
              isModalOpen={props.isModalOpen}
              hideModal={props.hideModal}
              meals={props.meals}
              wheelchair={props.wheelchair}
            />
          </div>
          <Separator />

          <Stack
            horizontal
            theme={travelPanelTheme}
            horizontalAlign="center"
            tokens={stackTokens}
          >
            <PrimaryButton
              theme={travelPanelTheme}
              onClick={(e) => {
                props.submitInfo();
              }}
              text="Confirm"
            />
            <DefaultButton
              theme={travelPanelTheme}
              onClick={props.hideModal}
              text="Cancel"
            />
          </Stack>
        </div>
      </Modal>
    </ThemeProvider>
  );
};

const cancelIcon: IIconProps = { iconName: "Cancel" };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  header: [
    theme.fonts.large,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
