import * as React from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  ContextualMenu,
  Toggle,
  Modal,
  IDragOptions,
  IIconProps,
  Stack,
  IStackProps,
  mergeStyles,
  FontIcon,
  createTheme,
  Label,
  Stylesheet,
  initializeIcons,
  Separator,
  IStyleSet,
  IStyle
} from '@fluentui/react';
import { DefaultButton, IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import TravelerPro1 from './../Components/HelpPics/TravelerPro1.png';
import TravelerPro1five from './../Components/HelpPics/TravelerPro1five.png';
import TravelerPro2 from './../Components/HelpPics/TravelerPro2.png';
import TravelerPro2five from './../Components/HelpPics/TravelerPro2five.png';
import TravelerPro3 from './../Components/HelpPics/TravelerPro3.png';
import TravelerPro3five from './../Components/HelpPics/TravelerPro3five.png';
import TravelerPro4 from './../Components/HelpPics/TravelerPro4.png';
import TravelerPro4five from './../Components/HelpPics/TravelerPro4five.png';
import TravelerPro5 from './../Components/HelpPics/TravelerPro5.png';
import TravelerPro5five from './../Components/HelpPics/TravelerPro5five.png';
import TravelerPro6 from './../Components/HelpPics/TravelerPro6.png';
import TravelerPro6five from './../Components/HelpPics/TravelerPro6five.png';
import TravelerPro7 from './../Components/HelpPics/TravelerPro7.png';
import TravelerPro7five from './../Components/HelpPics/TravelerPro7five.png';
import { useState } from 'react';


const iconClass = mergeStyles({
    fontSize: 25,
    height: 25,
    width: 25,
    margin: '0 25px',
    position: 'fixed',
    alignItems: 'auto',
    justifyContent: 'flex-start',

});

const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: '#c5b373' }, iconClass],
});

export const TravelerHelp: React.FunctionComponent = (props) => {
  const [isModalOpen, {setTrue: showModal, setFalse: hideModal}] = useBoolean(false);
  initializeIcons();
  const titleId = useId('title');
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const theme = getTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [
      
      theme.fonts.large,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    heading: {
      color: theme.palette.neutralPrimary,
      fontWeight: FontWeights.semibold,
      fontSize: 'inherit',
      margin: '0',
    },
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      color: theme.palette.black,
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });
  const stackProps: Partial<IStackProps> = {
    horizontal: true,
    tokens: { childrenGap: 40 },
    styles: { root: { marginBottom: 20 } },
  };
  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };
  const iconClass = mergeStyles({
    
    fontSize: 200,
    height: 200,
    width: 200,
    margin: '0 25px',
    alignItems: 'end',
    justifyContent: 'flex-end',
    position: 'absolute',
    
  });

  const classNames = mergeStyleSets({
    helpIcon: [{color:theme.palette.themePrimary,iconClass}]
  })

  const [selectedPage,setSelectedPage] = useState(1);
  const ButtonIco = (): JSX.Element =>{
    return(
      <>
      <IconButton
        iconProps={{ iconName: "ChevronLeft" }}
        onClick={(e) => setSelectedPage(selectedPage - 1)}
        disabled={selectedPage == 1}
      ></IconButton>
      <IconButton
        iconProps={{ iconName: "ChevronRight" }}
        onClick={(e) =>
          setSelectedPage(selectedPage + 1)
        }
        disabled={selectedPage == 6}
      ></IconButton>
    </>
    );
    
  }


   return (
    <div>
    <Stack 
      horizontalAlign='center'
      
      styles={{
        root: {
          width: '95vw',
          margin: '0 auto',
          textAlign: 'center',
          color: '#605e5c',
          padding: '2vh 2vw 2vh 2vw'
        },
      }}>

        <div >
          <Label style={{color: '#E3E3E3', cursor:'pointer', textDecorationLine:'underline'}} onClick={showModal}>Need Assistance?</Label>
        </div>

    </Stack>
    <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <h2 className={contentStyles.heading} id={titleId}>
            Help Info
          </h2>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <Stack style={{maxHeight:'max-content', minHeight:'fit-content'}}>
        <div hidden={selectedPage!=1}>
        <Stack verticalAlign='center'>
          <img src={TravelerPro1} alt="Portal 1" style={{maxWidth: '100%', height:'auto'}}/>
          {/* <img src={TravelerPro1five} alt="Portal 1five" style={{maxWidth: '100%', height:'auto'}}/> */}
        </Stack>
          <br/>
        </div>
        <div hidden={selectedPage!=2}>
        <Stack verticalAlign='center'>
          <img src={TravelerPro2} alt="Portal 2" style={{maxWidth: '100%', height:'auto'}}/>
          {/* <img src={TravelerPro2five} alt="Portal 2five" style={{maxWidth: '100%', height:'auto'}}/> */}
        </Stack>
          <br/>
        </div>
        <div hidden={selectedPage!=3}>
        <Stack verticalAlign='center'>
          <img src={TravelerPro3} alt="Portal 3" style={{maxWidth: '100%', height:'auto'}}/>
          {/* <img src={TravelerPro3five} alt="Portal 3five" style={{maxWidth: '100%', height:'auto'}}/> */}
        </Stack>
          <br/>
        </div>
        <div hidden={selectedPage!=4}>
        <Stack verticalAlign='center'>
          <img src={TravelerPro4} alt="Portal 4" style={{maxWidth: '100%', height:'auto'}}/>
          {/* <img src={TravelerPro4five} alt="Portal 4five" style={{maxWidth: '100%', height:'auto'}}/> */}
        </Stack>
          <br/>
        </div>
        <div hidden={selectedPage!=5}>
        <Stack verticalAlign='center'>
          <img src={TravelerPro5} alt="Portal 5" style={{maxWidth: '100%', height:'auto'}}/>
          {/* <img src={TravelerPro5five} alt="Portal 5five" style={{maxWidth: '100%', height:'auto'}}/> */}
        </Stack>
          <br/>
        </div>
        <div hidden={selectedPage!=6}>
        <Stack verticalAlign='center'>
          <img src={TravelerPro6} alt="Portal 6" style={{maxWidth: '100%', height:'auto'}}/>
          {/* <img src={TravelerPro6five} alt="Portal 6five" style={{maxWidth: '100%', height:'auto'}}/> */}
        </Stack>
          <br/>  
        </div>
        {/* <div hidden={selectedPage!=7}>
        <Stack verticalAlign='center'>
          <img src={TravelerPro7} alt="Portal 7" style={{maxWidth: '100%', height:'auto'}}/>
          {/* <img src={TravelerPro7five} alt="Portal 7five" style={{maxWidth: '100%', height:'auto'}}/> */}
        {/* </Stack> */}
        {/* <br/>   */}
        {/* </div> */} 
        <Stack horizontal horizontalAlign='center'>
          {ButtonIco()}
        </Stack>
        </Stack>
        </div>
      </Modal>
    </div>
  );
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const PaymentTableTheme = createTheme({
    fonts: {
      small: {
        fontSize: "15px",
        
      },
      medium: {
        fontSize: "17px",
        
      },
      large: {
        fontSize: "20px",
        fontWeight: "semibold",
        
      },
      xLarge: {
        fontSize: "25px",
        fontWeight: "semibold",
        
        
      },
    },
    palette: {
      themePrimary: "#c5b373",
      themeLighterAlt: "#070603",
      themeLighter: "#1d190d",
      themeLight: "#362f18",
      themeTertiary: "#6b5e30",
      themeSecondary: "#9d8a47",
      themeDarkAlt: "#baa65e",
      themeDark: "#736a62",
      themeDarker: "#d4c693",
      neutralLighterAlt: "#282828",
      neutralLighter: "#272727",
      neutralLight: "#252525",
      neutralQuaternaryAlt: "#c8c8c8",
      neutralQuaternary: "#605E5C",
      neutralTertiaryAlt: "#b39e51",
      neutralTertiary: "#c8c8c8",
      neutralSecondary: "#d0d0d0",
      neutralPrimaryAlt: "#dadada",
      neutralPrimary: "#ffffff",
      neutralDark: "lightgray",
      black: "#f8f8f8",
      white: "#282828",
    },
  });
const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
   
    theme.fonts.large,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: 'inherit',
    margin: '0',
  },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    color: PaymentTableTheme.palette.themePrimary,
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
const stackProps: Partial<IStackProps> = {
  horizontal: true,
  tokens: { childrenGap: 40 },
  styles: { root: { marginBottom: 20 } },
};
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

