import { PaymentInfo } from './Models';
import * as CryptoJS from 'crypto-js';
import * as PortalModels from '../Contexts/Models';
import * as PortalEnums from '../Contexts/Enums';
import { rejects } from 'assert';
export default class PaymentInfoService {
  private a: CryptoJS.lib.WordArray;
  private b: CryptoJS.lib.WordArray;
  private c: CryptoJS.lib.WordArray;
  constructor(a:string, b:string, c:string) {
    this.a = CryptoJS.enc.Utf8.parse(a);
    this.b = CryptoJS.enc.Utf8.parse(b);
    this.c = CryptoJS.enc.Utf8.parse(c);
  }
   public encryptFull = (data: PortalModels.PortalPayment): string => {
     
  
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.a, { keySize: 128 / 8, iv: this.b,
      mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
      }).ciphertext.toString(CryptoJS.enc.Base64);
  }
  public encryptCasePayment = (data: PortalModels.CasePayment): string => {
     
  
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.a, { keySize: 128 / 8, iv: this.b,
      mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
      }).ciphertext.toString(CryptoJS.enc.Base64);
  }
  public immediateEncrypt = (newItem: PortalModels.PaymentInfoUpdate, parentInfo: PortalModels.ClientPortalPayment, index:number): void => {
    // const encrypted: PaymentInfo = {
    //   id: `${index}`,
    //   gridDataType: 'new',
    //   paymentType: newItem,
    //   paymentNumber: ({text: `${newItem.paymentType.value} ${newItem.paymentNumber.substr(0, 2)}-XXXX-${newItem.paymentNumber.substr(newItem.paymentNumber.length - 4)}`,
    //     key: newItem.paymentNumber}),
    //   paymentCode: ({text: 'XXXX', key: newItem.paymentCode}),
    //   expiration: newItem.expiration,
    //   holderName: newItem.holderName,
    //   billingStreet: newItem.billingStreet,
    //   billingCity: newItem.billingCity,
    //   billingState: newItem.billingState,
    //   billingCountry: newItem.billingCountry,
    //   billingZip: newItem.billingZip,
    //   billingPhone: newItem.billingPhone,
    //   billingEmail: newItem.billingEmail,
    //   keepOnFile: newItem.keepOnFile,
    //   customerSignature: newItem.customerSignature
    // };
    
    if(newItem.cardType && newItem.cardNo) {
      parentInfo.fopName = PortalEnums.eCardChoice[newItem.cardType]+newItem.cardNo.substr(0,2)+"-XXXX-"+newItem.cardNo.substr(newItem.cardNo.length-4);
    }
    newItem.cardCode =
    CryptoJS.AES.encrypt(newItem.cardCode as string, this.c, { keySize: 128 / 8, iv: this.a,
      mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
      }).ciphertext.toString(CryptoJS.enc.Base64);
    newItem.cardNo = CryptoJS.AES.encrypt(
      newItem.cardNo as string, this.b, { keySize: 128 / 8, iv: this.c,
      mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
      }).ciphertext.toString(CryptoJS.enc.Base64);
    //return paymentInfo;
  }
  
  // getKeyVaultSecret = (secretName:string): Promise<CryptoJS.lib.WordArray> => {

  //   var client = new SecretClient(this.KEY_VAULT_URL,new AzureIdentity.DefaultAzureCredential());

  //   return client.getSecret(secretName).then(result=>{
  //     return CryptoJS.enc.Utf8.parse(result.value??"");
  //   });   
  // }
  
}