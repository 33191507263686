import React, { useEffect, useState } from 'react';
import { ComboBox, IComboBoxOption, Label} from '@fluentui/react';
import Fuse, { FuseSearchOptions } from 'fuse.js';
import citiesData from './CitiesData'
import {
  Combobox,
  makeStyles,
  Option,
  shorthands,
  useId,
} from "@fluentui/react-components";
import type { ComboboxProps } from "@fluentui/react-components";



const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});

const useStylesVisual = makeStyles({
  listbox: {
    maxHeight: "250px",
  },
  option: {
    height: "32px",
  },
});

export interface CitiesResponse {
  key: string;
  text: string;
}

interface FuzzyComboBoxProps {
  onSelect: (selectedItems: IComboBoxOption[]) => void;
  label: string;
  valueProps: any;
  airports: IComboBoxOption[];
 //options: { key: string; text: string }[];
 //onChanged?: (option?: { key: string; text: string } | undefined, index?: number | undefined, value?: string | undefined) => void;
 //onInputChange?: (input: string, option?: { key: string; text: string } | undefined) => void;
}




const FuzzyComboBox: React.FC<ComboboxProps & FuzzyComboBoxProps>=(props: Partial<ComboboxProps> & FuzzyComboBoxProps)  => {
  // const [filter,setFilter]=useState ('');
  // const [filteredItems, setFilteredItems] = useState<CitiesResponse[]>([]);
  // useEffect(() => {
  //   // Make backend API call with the filter value
  //   const fetchData = async () => {
  //     try {
        
  //       const response = await fetch(`https://prod-190.westus.logic.azure.com:443/workflows/a6a60b42c4164cb892117afccc6cb843/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=oi86GsVstDozeJT3ivgjyJZIu1V-OYkbtx17-VrdC2Q${filter}`);
  //       //fetch for airports
  //       const data = await response.json();
  //       setFilteredItems(data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   if (filter.length >= 3) {
  //     fetchData();
  //   } else {
  //     // Reset the filtered items when the filter is less than 3 characters
  //     setFilteredItems([]);
  //   }
  // }, [filter]);
  
  
  const options=props.airports;

  const [matchingOptions, setMatchingOptions] = React.useState([...options]);
  const [customSearch, setCustomSearch] = React.useState<string | undefined>();
  const styles = useStyles();
  
  const onChange: ComboboxProps["onChange"] = (event) => {
    const value = event.target.value.trim();
    console.log(value)
    let matches = 
    !value||value.length<3?[]:
    options.filter(
      (option) => option.text.toLowerCase().includes(value.toLowerCase()) || option.key.toString().toLowerCase().includes(value.toLowerCase())
    );
    setMatchingOptions(matches);
    
    if (value.length && matches.length < 1) {
      setCustomSearch(undefined);
    } else {
      setCustomSearch(value);
    }
  };
  const onOptionSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
    // console.log(data.optionText)
    const matchingOption = data.optionText;
    if (matchingOption) {
      setCustomSearch(undefined);
    } else {
      setCustomSearch(data.optionText);
    }
  //   if(!data.optionText)
  //   setCustomSearch(props.airports)
  // else{
  //   setCustomSearch(props.airports.filter(x=>x.key.toString().includes(data.optionText)||x.text.includes(data.optionText)))
  // }
  };

  return (

    
    <Combobox
        freeform
        //value={customSearch}
        
        onChange={onChange}
        onOptionSelect={onOptionSelect}
        inlinePopup={false}
        open={!!customSearch && customSearch.length>=3}
        
        {...props}
      >

          {customSearch ? (
          <Option key="freeform" text={customSearch}>
            Search for "{customSearch}"
          </Option>
        ) : null}

        {matchingOptions.map((option) => (
          <Option style={{background:"white"}} key={option.key} text={option.text}>{option.text}</Option>
        ))}

        {matchingOptions.length ==0 ?(
          <Option key="No-Results" text=''>
            No results found
          </Option>
        ):null}

      </Combobox>
  );
};

export default FuzzyComboBox;
