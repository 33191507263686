import * as React from "react";
import { Link } from "@fluentui/react/lib/Link";
import {
  DetailsHeader,
  DetailsList,
  IGroup,
  IGroupDividerProps,
  IDetailsListProps,
  IDetailsGroupRenderProps,
  SelectionMode,
  CheckboxVisibility,
  DetailsListLayoutMode,
  Selection,
  IColumn,
  IDetailsRowStyleProps,
  IDetailsRowStyles,
  DetailsRow,
  IDetailsListCheckboxProps,
  IDetailsCheckboxProps,
  IDetailsGroupDividerProps,
  SelectAllVisibility,
  IDetailsHeaderProps,
  CollapseAllVisibility,
  IDetailsHeaderBaseProps,
} from "@fluentui/react/lib/DetailsList";
import {
  createTheme,
    getTheme,
  mergeStyles,
  mergeStyleSets,
  } from "@fluentui/react/lib/Styling";
import '../App.scss';
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import * as PortalModels from "../Contexts/Models";
import {
  Checkbox,
  FontIcon,
  IStackTokens,
  PrimaryButton,
  Separator,
  Stack,
  ThemeProvider,
  initializeIcons,
} from "@fluentui/react";
import { callbackify } from "util";
import { PaymentHelp } from "./HelpModal";

initializeIcons();

const theme = getTheme();
const ROW_HEIGHT: number = 42; // from DEFAULT_ROW_HEIGHTS in DetailsRow.styles.ts
const GROUP_HEADER_AND_FOOTER_SPACING: number = 8;
const GROUP_HEADER_AND_FOOTER_BORDER_WIDTH: number = 1;
const GROUP_HEADER_HEIGHT: number = 95;


const classNames:(any) = mergeStyles({
fileIconCell: {
  textAlign: "center",
},
fileIconImg: {
  verticalAlign: "middle",
},
})

const iconClass = mergeStyles({
  fontSize: 50,
  height: 50,
  width: 50,
  margin: '0 25px',
});
const classIcons = mergeStyleSets({
  deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
  greenYellow: [{ color: 'greenyellow' }, iconClass],
  salmon: [{ color: 'salmon' }, iconClass],
});

const className:(any) = mergeStyles({
  display: "flex",
  flexWrap: "wrap",
  fileIconCell: {
    textAlign: "center",
  },
  fileIconImg: {
    verticalAlign: "middle",
  },

  headerAndFooter: {
    borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
    borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
    padding: GROUP_HEADER_AND_FOOTER_SPACING,
    margin: `${GROUP_HEADER_AND_FOOTER_SPACING}px 0`,
    background: theme.palette.neutralLighterAlt,
    
    // Overlay the sizer bars
    position: 'relative',
    zIndex: 100,
  },
  headerTitle: [
    theme.fonts.xLarge,
    {
      padding: '10px',
      fontSize: "20px",
      fontWeight: "semibold",
    },
  ],
  headerLinkSet: {
    margin: '4px -8px',
  },
  headerLink: {
    margin: '0 8px',
  },
});


const PaymentTableTheme = createTheme({
  fonts: {
    small: {
      fontSize: "15px",
      
    },
    medium: {
      fontSize: "17px",
      
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
      
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
      
      
    },
  },
  palette: {
    themePrimary: "#c5b373",
    themeLighterAlt: "#070603",
    themeLighter: "#1d190d",
    themeLight: "#362f18",
    themeTertiary: "#6b5e30",
    themeSecondary: "#9d8a47",
    themeDarkAlt: "#baa65e",
    themeDark: "#736a62",
    themeDarker: "#d4c693",
    neutralLighterAlt: "#282828",
    neutralLighter: "#272727",
    neutralLight: "#252525",
    neutralQuaternaryAlt: "#c8c8c8",
    neutralQuaternary: "#605E5C",
    neutralTertiaryAlt: "#b39e51",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "lightgray",
    black: "#f8f8f8",
    white: "#282828",
  },
});

const renderRow: IDetailsListProps["onRenderRow"] = (props) => {

  const rowStyles: Partial<IDetailsRowStyles> = {
    root: {
      borderBottomColor: PaymentTableTheme.semanticColors.buttonBorder,
      fontSize: PaymentTableTheme.fonts.large.fontSize,
      width: "37em",
      textAlign:"start",
      
      
    },
    cell: { paddingLeft: 10 },
  };
  if (!props) return null;
  return <DetailsRow {...props} styles={rowStyles} />;
};



const stackTokens: IStackTokens = { childrenGap: 20 };

export interface IPaymentGroupedTableProps {
  onSelectionChanged(selection: string[]): void;
  payments?: PortalModels.ClientPortalPayment[];
  travelers?: PortalModels.PaymentTravelerInfo[];
  segments?: PortalModels.ClientPortalSegment[];
  caseInfo?: PortalModels.ReservationInfo[];
  addNewPayment(): void;
  }

export interface IPaymentGroupedTableState
  extends React.ComponentState,
    IPaymentGroupedTableProps {
  items: PortalModels.CasePaymentGroupItem[];
  groups: IGroup[];
  selectionDetails: string[];
}

export interface IGroupList {
  key: String;
  name: String;
  count: PortalModels.ClientPortalSegment[];
  level: Number;
  startIndex: Number;
  groupIndex: number;
}

export class PaymentGroupedTable extends React.Component<
  IPaymentGroupedTableProps,
  IPaymentGroupedTableState
> {
  private _selection: Selection;
  private _columns: IColumn[];
  private _columns1: IColumn[];
  
  constructor(props: IPaymentGroupedTableProps) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () =>
        this.props.onSelectionChanged(this._getSelectionDetails()),
        
      canSelectItem: (item: any) => {
        return !item.associatedPaymentId;
      },
    });

    this._columns1 = [
      {
        key: "column1",
        name: "Segment",
        fieldName: "segmentNumber",
        minWidth: 100,
        maxWidth: 100,
      },
      {
        key: "column2",
        name: "Chain Code",
        fieldName: "chainCode",
        minWidth: 200,
        maxWidth: 200,
        isResizable: false,
      },
      {
        key: "column3",
        name: "City Pair",
        fieldName: "cityPair",
        minWidth: 200,
        maxWidth: 200,
        isResizable: false,
      },
    ];

    this._columns = [
      {
        key: "column1",
        name: "Paid",
        fieldName: "iconName",
        className: classNames.fileIconCell,
        minWidth: 50,
        maxWidth: 50,
      },
      {
        key: "column2",
        // name: "Chain Code",
        name: "Traveler",
        // fieldName: "chainCode",
        fieldName: "travelerName",
        minWidth: 400,
        maxWidth: 600,
        isResizable: true,
      },
      // {
      //   key: "column3",
      //   name: "City Pair",
      //   fieldName: "cityPair",
      //   minWidth: 100,
      //   maxWidth: 100,
        
      //   isResizable: true,
      // },
    ];
    this.state = {
      ...props,
      items: [],
      groups: [],
      selectionDetails: this._getSelectionDetails(),
    };
  }

  public componentDidMount() {
    let items: PortalModels.CasePaymentGroupItem[] = [];
    let groups: IGroup[] = [];
    this.props.caseInfo
      ?.sort((a, b) => a.recordLocator.localeCompare(b.recordLocator))
      .forEach((x, i) => {
        let pnrGroup = groups.find((t) => t.key === x.pnrId);
        if (!pnrGroup) {
          let startIdx = i;
          if (startIdx !== 0) {
            startIdx = groups[i - 1].startIndex + groups[i - 1].count;
          }
          if (x.info && x.info.segments && x.info.segments.length > 0) {
            pnrGroup = {
              key: x.pnrId,
              name: x.recordLocator,
              startIndex: startIdx,
              count:
                x.info.clients.length,// * (x.info.segments?.length ?? 0),
              level: 0,
              
              children: [],
            };
          } else if (x.info && x.info.clients) {
            pnrGroup = {
              key: x.pnrId,
              name: x.recordLocator,
              startIndex: startIdx,
              
              count: x.info.clients.length,
              level: 0,
              children: [],
            };
          } else {
            pnrGroup = {
              key: x.pnrId,
              name: x.recordLocator,
              startIndex: startIdx,
              
              count: 0,
              level: 0,
            };
          }
          groups = groups.concat(pnrGroup);
        }
        x.info?.clients
          .sort((c, d) => c.travelerName.localeCompare(d.travelerName))
          .forEach((y, j) => {
            let travelerGroup = groups.find(
              (t) => t.key === `${y.id};${x.pnrId}`
            );
            if (!travelerGroup) {
              var prevLength = items.length;
              if (y.segments && y.segments.length > 0) {
                y.segments
                  .sort((f, g) =>
                    f.segmentNumber.localeCompare(g.segmentNumber)
                  )
                  .forEach((z, k) => {
                    items = items.concat({
                      travelerId: y.id,
                      pnrId: x.pnrId,
                      recordLocator: x.recordLocator,
                      segmentId: z.id,
                      cityPair: z.cityPair,
                      travelerName: y.travelerName,
                      key: `${z.id};${y.id};${x.pnrId}`, //segmentid;travelerid;pnrid;
                      iconName: "",
                      name: z.segmentNumber,
                      segmentNumber: z.segmentNumber,
                      chainCode: z.chainCode, //airline code
                    });
                  });
                var startIdx = pnrGroup?.startIndex ?? 0;
                if (
                  pnrGroup &&
                  pnrGroup.children &&
                  pnrGroup.children.length > 0
                ) {
                  if(pnrGroup.children?.length > 0){
                    startIdx = pnrGroup.children[j - 1].startIndex + pnrGroup.children[j- 1].count;
                  }
                }
                if (pnrGroup && pnrGroup.children) {
                  pnrGroup.children = pnrGroup.children.concat({
                    key: `${y.id};${x.pnrId}`,
                    name: y.travelerName,
                    startIndex: startIdx,
                    count: y.segments?.length,
                    level: 1,
                  });
                }
              } else {
                items = items.concat({
                  travelerId: y.id,
                  associatedPaymentId: y.associatedPaymentId,
                  pnrId: x.pnrId,
                  recordLocator: x.recordLocator,
                  segmentId: "",
                  cityPair: "",
                  travelerName: y.travelerName,
                  key: `${""};${y.id};${x.pnrId}`, //segmentid;travelerid;pnrid;
                  iconName: y.associatedPaymentId?"\u{2714}":"",
                  name: y.travelerName,
                  segmentNumber: "",
                  chainCode: "", //airline code
                });
              }
            }
          });
      });
    this.setState({
      ...this.state,
      items: items,
      groups: groups,
      selectionDetails: this._getSelectionDetails(),
    });
  }

  public render(): JSX.Element {
    const { groups, items } = this.state;

    var ScreenHeight= window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
    var ScreenWidth= window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;


    if ((this.props.segments?.length ?? 0) == 0) {
      return (
        <>
          <ThemeProvider theme={PaymentTableTheme}>
            <Separator />
            
              <br/>
            <Stack  horizontalAlign="center" style={{ whiteSpace:"nowrap"}}>
              <PrimaryButton
                text="Add New Payment"
                style={{ whiteSpace:"nowrap", minWidth:20}}
                disabled={(this._selection.getSelection()?.length ?? 0) == 0}
                iconProps={{ iconName: "PaymentCard" }}
                onClick={(e) => {
                  this.props.addNewPayment();
                }}
              />
              
              
            </Stack>
            {/* <div id ="myModal" className="modal">
                <div className="modal-content">
                  <span className="close">&times;</span>
                  <p>Text for Modal goes here...</p>
                </div>

            </div> */}
            <div 
            style={{maxWidth:(ScreenWidth-30)}}
            >
            <MarqueeSelection selection={this._selection}>
              <DetailsList
                items={this.state.items}
                columns={this._columns}
                groups={this.state.groups}
                compact={false}
                groupProps={{
                  onRenderHeader: this._onRenderGroupHeader,
                  collapseAllVisibility:CollapseAllVisibility.hidden,
                  showEmptyGroups: true,
                  headerProps: {
                    className:'custom-group'
                  }
                }}
                
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                // onRenderDetailsHeader={this._onRenderGroupHeader}
                checkboxVisibility={CheckboxVisibility.always}
                
                onRenderRow={renderRow}
                theme={PaymentTableTheme}
                selectionPreservedOnEmptyClick={true}
                selection={this._selection}
                selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
              />
            </MarqueeSelection>
            </div>
          </ThemeProvider>
        </>
      );
    } else {
      return (
        <>
          <ThemeProvider theme={PaymentTableTheme}>
              {/* <Stack tokens={stackTokens}>
                <Separator alignContent="center">Itinerary</Separator>
              </Stack>
              <DetailsList
                ariaLabel="Itinerary"
                columns={this._columns1}
              //  items ={items} 
                items={
                  this.props.segments
                  ?.sort((x,i)=>x.segmentNumber.localeCompare(i.segmentNumber))
                  .map((x, i) => { 
                    return {
                      key: x.id,
                      segmentNumber: x.segmentNumber,
                      chainCode: x.chainCode, //airline code
                      cityPair: x.cityPair,
                    };
                  }) ?? []
                }
                
                setKey="set"
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selectionMode={SelectionMode.none}
                compact={true}
                checkboxVisibility={2}
                theme={PaymentTableTheme}
              />
              <br /> */}
              {/* <Stack tokens={stackTokens}>
                <Separator alignContent="center">Payments</Separator>
              </Stack> */}
              <br/>
              <Stack  horizontalAlign="center" style={{ whiteSpace:"nowrap"}}>
              <PrimaryButton
                text="Add New Payment"
                // className="ms-Grid-row ms-sm4"
                style={{ whiteSpace:"nowrap", minWidth:20}}
                disabled={(this._selection.getSelection()?.length ?? 0) == 0}
                iconProps={{ iconName: "PaymentCard" }}
                onClick={(e) => {
                  this.props.addNewPayment();
                }}
              />
            </Stack>
            <MarqueeSelection selection={this._selection}>
              <DetailsList
                items={this.state.items}
                columns={this._columns}
                groups={this.state.groups}
                compact={false}
                groupProps={{
                  onRenderHeader: this._onRenderGroupHeader,
                  collapseAllVisibility:CollapseAllVisibility.hidden,
                  showEmptyGroups: false,
                  headerProps: {
                    className:'custom-group',
                    }
                  
                }}
                
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                // onRenderDetailsHeader={this._onRenderGroupHeader}
                checkboxVisibility={CheckboxVisibility.always}
                
                onRenderRow={renderRow}
                theme={PaymentTableTheme}
                selectionPreservedOnEmptyClick={true}
                selection={this._selection}
                selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
              />
            </MarqueeSelection>
          </ThemeProvider>
        </>
      );

    }
  }
  private _getSelectionDetails(): string[] {
    return this._selection.getSelection().map((x) => x.key as string);
  }
  
  private _onRenderGroupHeader: (IDetailsGroupRenderProps['onRenderHeader']) = props => {
   
    var screenWidthHeader = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var screenHeightHeader = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
   
    

    if (props) {
      return (
        
        <div className={className.headerAndFooter}> 
          <br/>
          <div className="RenderHeader" style={{display:'flex', justifyContent:'center'}}>
          <Stack horizontalAlign="space-between" className="RenderHeader"><div className={className.headerTitle}>{`${props.group!.name}`}</div> </Stack>
          </div>
          <Stack horizontalAlign="space-between" className="RenderHeader"><div className={className.headerLinkSet}>
          
            {props.selectionMode !== SelectionMode.none ? (
              
              <Link className={className.headerLink} onClick={this._onToggleSelectGroup(props)}>
                {props.selected ? 'Remove Selections' : 'Select All'}
                
              </Link>
            ) : null}  &nbsp; | &nbsp;
            <Link className={className.headerLink} onClick={this._onToggleCollapse(props)}>
              {props.group!.isCollapsed ? 'Show Group' : 'Hide Group'}
            </Link>
          </div></Stack>
          
        </div>
       
      );
    }

    return null;
  };

  private _onToggleSelectGroup(props: IGroupDividerProps): () => void {
    return () => {
      
      props.onToggleSelectGroup!(props.group!);
      };
  }

  private _onToggleCollapse(props: IGroupDividerProps): () => void {
    return () => {
      props!.onToggleCollapse!(props!.group!);
    };
  }
}


