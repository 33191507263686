import React, { useState } from 'react';
import { Stack, Text, Link, FontWeights, IStackTokens, getPropsWithDefaults, loadTheme, createTheme, initializeIcons, Icon, FontIcon, mergeStyles, Label, KeyCodes } from '@fluentui/react';
import logo from './Global_First_Logo_White.svg';
import {PaymentComponent} from './Components/PaymentComponent';
import { TravelerProfilesComponent } from './Components/TravelerProfilesComponent';
import{InvalidPathComponent} from './Components/InvalidPathComponent'
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from "react-router-dom";

import { TravelerHelp } from './Components/HelpTraveler';
import { PaymentHelp } from './Components/HelpModal';
import { TravelPortal } from './Components/AirShopping/AirShopHome';

const boldStyle = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15 };
const gftTheme = createTheme({
  components:{
    Separator:{styles:{color:"orange"}}
  },
  palette: {
    themePrimary: '#b39e51',
    themeLighterAlt: '#070603',
    themeLighter: '#1d190d',
    themeLight: '#362f18',
    themeTertiary: '#6b5e30',
    themeSecondary: '#9d8a47',
    themeDarkAlt: '#baa65e',
    themeDark: '#c5b373',
    themeDarker: '#d4c693',
    neutralLighterAlt: '#282828',
    neutralLighter: '#272727',
    neutralLight: '#252525',
    neutralQuaternaryAlt: '#232323',
    neutralQuaternary: '#605E5C',
    neutralTertiaryAlt: '#b39e51',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: 'lightgray',
    black: '#f8f8f8',
    white: '#282828',
  }});
loadTheme(gftTheme);
initializeIcons();



interface IExampleProps {
  resetChoice?: () => void;
}





const DetectLand= (): any => {
  const [info, setInfo]=useState(null);
  var height= window.innerHeight;
  var width= window.innerWidth;
  var detectScreen= height>width;
};


  
// document.onkeydown = function(e) {
//   if(KeyCodes.f12|| KeyCodes.ctrl+KeyCodes.i|| KeyCodes.ctrl+KeyCodes.c || KeyCodes.ctrl+KeyCodes.j || KeyCodes.ctrl+KeyCodes.u) {
//   return false;
//   }
// }



document.addEventListener('contextmenu', function(e) {
  e.preventDefault();
});




export const App: React.FunctionComponent = () => {
  var ScreenHeight= window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
  var ScreenWidth= window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  var screencheck=  ScreenWidth<=600 ? ScreenWidth-45 : '';
  var heightCheck= ScreenWidth<=600 ? ScreenHeight-30 : '';
  var logoCheck:number= ScreenWidth-55;

  return (
    <>

      <div className='body'
      
      >
      <div className='wrapper'> 

    <Stack
      horizontalAlign="center"
      verticalAlign="start"
      verticalFill
      styles={{
        root: {
          width: '95vw',
          margin: '0 auto',
          textAlign: 'center',
          color: '#605e5c',
          padding: '2vh 2vw 2vh 2vw',
          // maxHeight: (ScreenHeight-20), 
          // maxWidth: (ScreenWidth-20)
        },
      }}
      tokens={stackTokens}
    >
      
       <Stack 
        horizontalAlign="center"
        verticalAlign="center"
        styles={{
          root: {
            width: '95vw',
            margin: '0 auto',
            justifyContent: 'space-between',
            color: '#605e5c',
            padding: '2vh 2vw 2vh 2vw'
          },
        }}
        tokens={{childrenGap:15}}>
          
          <Stack.Item align="center" style={{maxWidth: screencheck}}>
          
            <img src={logo} alt="First and Business Class Air Specialists" 
            //srcSet={`${logo} 360w, ${logo} 300w`} sizes="(max-width: 360px) 85vw, 360px" 
            width={logoCheck} height="65"/>
          
          </Stack.Item>
          
      </Stack>
      <Router>
        <Switch>
          <Route path="/payments/:id" render={(props)=>{
            return(<Stack
              horizontalAlign="center"
              verticalAlign="start"
              verticalFill
              styles={{
                root: {
                  width: '95vw',
                  margin: '0 auto',
                  textAlign: 'center',
                  color: '#605e5c',
                  padding: '2vh 2vw 2vh 2vw',
                  //maxHeight: (ScreenHeight-20), 
                  //maxWidth: (ScreenWidth-20)
                },
              }}
              tokens={stackTokens}
            >
              <Stack horizontalAlign='center'>
              <Text className="header-text" variant="xxLarge" >
                Secure Payment Portal
              </Text> 
              <div>
              <PaymentHelp/>
              </div>
              </Stack>
              {/* <DefaultButton onClick={showModal}></DefaultButton> */}
              
              
            <PaymentComponent token={props.match.params.id}/>
            </Stack>
            );}}>
          </Route>
          <Route path="/travelers/:id" render={(props)=>{
            return(
            <Stack
              horizontalAlign="center"
              verticalAlign="start"
              
              styles={{
                root: {
                  width: '95vw',
                  height:"auto",
                  margin: '0 auto',
                  textAlign: 'center',
                  color: '#605e5c',
                  padding: '2vh 2vw 2vh 2vw',
                  
                },
              }}
              tokens={stackTokens}
            >
              <Stack horizontalAlign='center'>
              <Text className="header-text" variant="xxLarge">Traveler Profile Portal</Text>
              
              <TravelerHelp/>
              </Stack>
              <TravelerProfilesComponent token={props.match.params.id}/>
              
            </Stack>
            );}}>
          </Route>
          <Route path="/air/:id" render={(props)=>{
            return(<Stack
              horizontalAlign="center"
              verticalAlign="start"
              verticalFill
              styles={{
                root: {
                  width: '95vw',
                  margin: '0 auto',
                  textAlign: 'center',
                  color: '#605e5c',
                  padding: '4vh 4vw 4vh 4vw',
                },
              }}
              tokens={stackTokens}
            >
              <Stack horizontalAlign='center'>
              <Text className="header-text" variant="xxLarge" >
                Airline Booking Portal
              </Text> 
              <div>
              
              </div>
              </Stack>
              
              <TravelPortal 
                token={props.match.params.id} />
            </Stack>
            );}}>
          </Route>
          <Route path="*">
            <InvalidPathComponent displayText="No Path Found. You will be Redirected to the Global First Travel home page"/>
          </Route>
        </Switch>
      </Router>
      
    </Stack>
    </div></div>
    </>
  );
};

/*{ <Text variant="xxLarge" styles={boldStyle}>
        Welcome to Your Fluent UI App
      </Text>
      <Text variant="large">For a guide on how to customize this project, check out the Fluent UI documentation.</Text>
      <Text variant="large" styles={boldStyle}>
        Essential Links
      </Text>
      <Stack horizontal tokens={stackTokens} horizontalAlign="center">
        <Link href="https://developer.microsoft.com/en-us/fluentui#/get-started/web">Docs</Link>
        <Link href="https://stackoverflow.com/questions/tagged/office-ui-fabric">Stack Overflow</Link>
        <Link href="https://github.com/microsoft/fluentui/">Github</Link>
        <Link href="https://twitter.com/fluentui">Twitter</Link>
      </Stack>
      <Text variant="large" styles={boldStyle}>
        Design System
      </Text>
      <Stack horizontal tokens={stackTokens} horizontalAlign="center">
        <Link href="https://developer.microsoft.com/en-us/fluentui#/styles/web/icons">Icons</Link>
        <Link href="https://developer.microsoft.com/en-us/fluentui#/styles/web">Styles</Link>
        <Link href="https://aka.ms/themedesigner">Theme Designer</Link>
      </Stack> }*/
