import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  createTheme,
} from '@fluentui/react';
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'


export interface IIdlerProps{
  closePanel():void;
  closeSearchPanel():void;
  hideModal(): void;
  submitInfo(): void;
  autoTimerStart: boolean;
  autoTimerStart2: boolean;
}


const ProfleTheme = createTheme({
  fonts: {
    small: {
      fontSize: '11px',
    },
    medium: {
      fontSize: '13px',
    },
    large: {
      fontSize: '20px',
      fontWeight: 'semibold',
    },
    xLarge: {
      fontSize: '22px',
      fontWeight: 'semibold',
    },
  },
  palette: {
    themePrimary: '#ffffff',
    themeLighterAlt: '#070603',
    themeLighter: '#1d190d',
    themeLight: '#362f18',
    themeTertiary: '#6b5e30',
    themeSecondary: '#9d8a47',
    themeDarkAlt: '#baa65e',
    themeDark: '#c5b373',
    themeDarker: '#d4c693',
    neutralLighterAlt: '#282828',
    neutralLighter: '#272727',
    neutralLight: '#252525',
    neutralQuaternaryAlt: '#232323',
    neutralQuaternary: '#605E5C',
    neutralTertiaryAlt: '#b39e51',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: 'lightgray',
    black: '#f8f8f8',
    white: '#282828',
  }

});

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    {
      flex: '1 1 auto',
      borderTop: `4px solid #ffffff`,
      color: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  heading: {
    color: '#ffffff',
    fontWeight: FontWeights.semibold,
    fontSize: 30,
    margin: '0',
  },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});

const dialogStyles = { main: { maxWidth: 450 } };

export const Idler=(props:IIdlerProps)=> {
  
  const [state, setState] = useState<string>('Active')
  const [remaining, setRemaining] = useState<number>(0)
  //const [open, setOpen] = useState<boolean>(false)
  const promptBeforeIdle=30000;
  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'
  

  
  const onIdle = () => {
    setState('Idle')
    //setOpen(false)
  }

  const onActive = () => {
    setState('Active')
    //setOpen(false)
  }
  const onPrompt = () => {
  if (((props.autoTimerStart||props.autoTimerStart2)==true)){
  
    setState('Prompted');
    props.closePanel();
    props.closeSearchPanel();
    props.hideModal();
    props.submitInfo();
    
  }
}

  

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    promptBeforeIdle,
    timeout: 120000,
    
  })

  useEffect(() => {
    
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1))
    }, 1)

    return () => {
      clearInterval(interval)
    }
  }
)

  const handleStillHere = () => {
    activate()
  }

  
  return (
    <>
    {/* <ThemeProvider theme={ProfleTheme}>
    <Stack horizontal tokens={{childrenGap:40}} styles={{root:{marginBottom:20}}}>
      <Modal
      isOpen={open}
      isBlocking={true}
      containerClassName={contentStyles.container}
      >
        <Stack horizontal horizontalAlign='center'>
          <div className={contentStyles.header}>
            <div className={contentStyles.heading}>
            Are you still here?
            </div>
          </div>
        </Stack>
        <Stack>
          <p>Logging out in {remaining} seconds.</p>
        </Stack>
          
        <Stack horizontal horizontalAlign='center'> 
          <div className={contentStyles.body}>
          <PrimaryButton theme={ProfleTheme} onClick={handleStillHere}>Continue?</PrimaryButton>
          </div>
        </Stack>
      </Modal>
    </Stack> 
    </ThemeProvider> */}
    </>
  )
}
