import { AxiosInstance } from "axios";
import { GetCaseFromTokenResponse, TravelerSearch } from "./Models";




export default class CrmService {
  ITest:any = {
    "agencyId": "ad04eb46-9066-eb11-a812-00224808078c",
    "agentId": "88cb2de1-6d51-ed11-bba1-002248087148",
    "a": "36b2131a4260eb11",
    "b": "bc2299834160eb11",
    "c": "5576d7f84260eb11",
    "ticketNumber": "CAS-39732-T7C6T7",
    "caseId": "d016d0db-8b24-4653-9939-fa0e3135a2c7",
    "associatedPNRs": [
        {
            "recordLocator": "IPGART",
            "pnrId": "1a7ff7e3-1a07-ef11-9f8a-6045bd026dc7",
            "info": {
                "clients": [
                    {
                        "travelerName": "GRECO/DIANNE MARIE",
                        "id": "86ceefe9-1a07-ef11-9f8a-6045bd026dc7",
                        "associatedPaymentId": "",
                        "segments": null
                    },
                    {
                        "travelerName": "FINK/CHRISTOPHER NATHAN",
                        "id": "8fceefe9-1a07-ef11-9f8a-6045bd026dc7",
                        "associatedPaymentId": "",
                        "segments": null
                    },
                    {
                        "travelerName": "CANNING/RUSSELL ANTHONY",
                        "id": "92ceefe9-1a07-ef11-9f8a-6045bd026dc7",
                        "associatedPaymentId": "",
                        "segments": null
                    },
                    {
                        "travelerName": "CANNING/ANTONINA FAE",
                        "id": "99ceefe9-1a07-ef11-9f8a-6045bd026dc7",
                        "associatedPaymentId": "",
                        "segments": null
                    }
                ],
                "payments": [],
                "segments": null
            }
        }
    ]
}
  private _headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  private _axios: AxiosInstance;

  constructor() {
    this._axios = require("axios");
    this._axios.defaults.headers = this._headers;
  }

  getPNRInfo(id: string): Promise<any> {
    const postUrl =
      "https://prod-62.westus.logic.azure.com:443/workflows/87bae8e15beb494a9c32b03cba3ad5b6/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=HaI5WKoo83nxKJ9s2un8nmJj5BhJRzrgC3-e9jLNAD4";
    return this._axios.post(postUrl, { token: id });
  }

  putPNRInfo(dataPacket: string): Promise<any> {
    const postUrl =
      "https://prod-122.westus.logic.azure.com:443/workflows/7ce06999b04a49a8bae2d4e5bc1bd61c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ffWEOJlFcNGMguWHf2RB0BhU6RC7W9YX_K7Cf1iwVIU";
    // this.http.post(postUrl, { data: dataPacket});
    return this._axios.post(postUrl, { data: dataPacket });
  }
  getCaseInfo(id: string): Promise<any> {
    const postUrl =
      "https://prod-01.westus.logic.azure.com:443/workflows/d4b62f90ac3547dd883398ba4bbe64a1/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=HIP_p9BKqavLJymShi2YcSzGHlpAeeFcvZBVT5SoUOU";
    return this._axios.post(postUrl, { token: id });
  }

  putCaseInfo(dataPacket: GetCaseFromTokenResponse): Promise<any>{
    console.log(dataPacket)
    const postUrl =
    "https://prod-136.westus.logic.azure.com:443/workflows/c84ea4d156b6494ebed570b892aa2a48/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=itKyHtc2KSR9RJG4_0SdgwzoeL_bJvu6UEKDEuGNczg";
    
        // this.http.post(postUrl, { data: dataPacket});
    return this._axios.post(postUrl, dataPacket);
  }
  getTravelerInfo(id: TravelerSearch): Promise<any> {
    const postUrl =
      "https://prod-39.westus.logic.azure.com:443/workflows/933a378072a04da4ba64733eb0543134/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=mrNvxkAQ3DDSe_US0pKsQod1d7CaDiRcBHazd56W9FQ";
    return this._axios.post(postUrl, id); //id json object
  }
  getCasePaymentInfo(id: string): Promise<any> {
    const postUrl =
      "https://prod-115.westus.logic.azure.com:443/workflows/9dff6b165d504679aa87348ba4402ded/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=4Xf4Y6jZw-rakYR6AGy5A7XdvNAlSTzXlW_CmCtvsBE";
    
      return this._axios.post(postUrl, { token: id });
  }
  addCasePayment(dataPacket: string): Promise<any> {
    const postUrl =
      "https://prod-148.westus.logic.azure.com:443/workflows/7aa84e5c1d78401786f0f48d048e7f14/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=yNRFCAwhDrIVk4Q8jNcieHwRPGCTU0PmM1osWSrr7EE";
    // this.http.post(postUrl, { data: dataPacket});
    return this._axios.post(postUrl, { data: dataPacket });
  }
  getAirlineInfo(dataPacket: string): Promise<any> {
    const postUrl =
      "https://prod-187.westus.logic.azure.com:443/workflows/b5321fcae5df484184b21fc52a686ccd/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=UP0ecFDKyeqt1BpPv_msaJd632dRdNqdV7Ve3FDyNAc";
    return this._axios.get(postUrl);
  }
  saveSelectedQuote(dataPacket:string): Promise<any>{
    const postUrl =
    "https://prod-19.westus.logic.azure.com:443/workflows/36bb18192bdd465b9265177e5abe61e5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=qrH5-2rbPARdSwGUR1X_3EQyxTq4Vo6t4Dt1Pco8qE4"
    return this._axios.post(postUrl, {data:dataPacket});
  }

  getShoppingInfofromToken (dataPacket: string): Promise<any> {
    const postUrl =
      "https://prod-88.westus.logic.azure.com:443/workflows/121cfa4db2d4460b85623fd2a26832b1/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=gjwb4F_c_oNOifyGv_VLrxtPCMvVzJxc4s6TMHvnSsw";
    return this._axios.post(postUrl, { token: dataPacket });
  }

  getflightsInfo(dataPacket: string): Promise<any> {
    const postUrl =
    "https://prod-190.westus.logic.azure.com:443/workflows/a6a60b42c4164cb892117afccc6cb843/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=oi86GsVstDozeJT3ivgjyJZIu1V-OYkbtx17-VrdC2Q"
    return this._axios.get(postUrl);
  }

  

}
