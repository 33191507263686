import React, { Component } from "react";
import CrmService from '../../../Contexts/CrmService';
import { Announced } from '@fluentui/react/lib/Announced';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { DetailsList, IDetailsListProps,CheckboxVisibility, DetailsRow, IDetailsRowStyles,DetailsListLayoutMode, Selection, IColumn, IDetailsListCheckboxProps, SelectAllVisibility, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { createTheme, FontWeights, getTheme, IconButton, IIconProps, mergeStyles, mergeStyleSets, Modal, PrimaryButton, Spinner, SpinnerSize, ThemeProvider } from '@fluentui/react';
import {
    Stack,
  IStackProps,
  IStackStyles} from "@fluentui/react"; 
import { Text } from '@fluentui/react/lib/Text';
import * as PortalModels from '../../../Contexts/Models';
import { useId, useBoolean } from "@fluentui/react-hooks";
import { eSearchStatus } from "../../../Contexts/Enums";
import { SearchPanel } from "../SearchPanel";


//result table for the modal surface
  const cancelIcon: IIconProps = { iconName: "Cancel" };

const theme = getTheme();
const SearchTableTheme = createTheme ({
  fonts: {
    small: {
      fontSize: "10px",
      fontWeight: "semibold",
    },
    medium: {
      fontSize: "17px",
      fontWeight: "semibold",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },
  
    palette: {
      themePrimary: "#b39e51",
      themeLighterAlt: "#eff6fc",
      themeLighter: "#deecf9",
      themeLight: "#c7e0f4",
      themeTertiary: "#71afe5",
      themeSecondary: "#2b88d8",
      themeDarkAlt: "#b39e51",
      themeDark: "#005a9e",
      themeDarker: "#004578",
      neutralLighterAlt: "#faf9f8",
      neutralLighter: "#f3f2f1",
      neutralLight: "#edebe9",
      neutralQuaternaryAlt: "#e1dfdd",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c6c4",
      neutralTertiary: "#a19f9d",
      neutralSecondary: "#605e5c",
      neutralPrimaryAlt: "#323130",
      neutralPrimary: "#323130",
      neutralDark: "#201f1e",
      black: "#000000",
      white: "#ffffff",
    },
    semanticColors: {
      bodyText: "#323130",
  
  },
});
  
export interface IDetailsListBasicExampleItem {
  agentId: string;
  agencyId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date | null | undefined;
  gender: number | undefined;
  email: string;
  telephone: string;
  passportNumber: string;
  passportIssuingCountry: string;
  globalEntry: string;
  redressNumber: string;
  knownTraveler: string;
  tsaPrecheck: string;
}

export interface IDetailsListBasicExampleState {
  items: IDetailsListBasicExampleItem[];
  selectionDetails: string;
}
// interface is the properties of an object

export interface ISearchPanelProps {
  searchResults?: PortalModels.TravelerProfiles[];
  isOpen: boolean;
  addTravelers(traveler: PortalModels.TravelerProfiles[]): void;
  closePanel(): void;
}

const LoadingStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  header: [
    SearchTableTheme.fonts.large,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${SearchTableTheme.palette.themePrimary}`,
      color: SearchTableTheme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],

  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },  
});
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  header: [
    SearchTableTheme.fonts.large,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${SearchTableTheme.palette.themePrimary}`,
      color: SearchTableTheme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],

  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});


const renderRow: IDetailsListProps['onRenderRow'] = props => {
    const rowStyles: Partial<IDetailsRowStyles> = {
      root: {
        borderBottomColor: SearchTableTheme.semanticColors.buttonBorder,
        fontSize: SearchTableTheme.fonts.large.fontSize,
        width: '37em'
      },
      cell: { paddingLeft: 0, },
    }
    if (!props) return null
    return <DetailsRow {...props} styles={rowStyles} />
  }

export interface ISearchListItems {
  key: number;
  name: string;
  gender: string;
  dob: string;
}

export interface IProfileTableProps {
  searchResults?: PortalModels.TravelerProfiles[];
  isOpen: boolean;
  searchState: eSearchStatus;
  onDismiss():void;
  addTravelers(traveler: PortalModels.TravelerProfiles): void;
  closePanel(): void;
  
}


export interface IProfileTableState extends React.ComponentState, IProfileTableProps {
    // items: ISearchListItems[];
    selectionDetails: PortalModels.TravelerProfiles[];
    
  }
  
export class ResultTable extends React.Component<IProfileTableProps, IProfileTableState,ISearchPanelProps> {
    private _selection: Selection;
    private _columns: IColumn[];

    constructor(props: IProfileTableProps) {
        super(props);
          this._columns = [
            { key: 'column1', name: 'Name', fieldName: 'name', minWidth: 200, maxWidth: 300, isResizable: true },
            { key: 'column2', name: 'Gender', fieldName: 'gender', minWidth: 100, maxWidth: 200, isResizable: true },
            { key: 'column3', name: 'Date of Birth', fieldName: 'dob', minWidth: 100, maxWidth: 200, isResizable: true },
          ];

        this._selection = new Selection({
        onSelectionChanged: () => {
            this.setState({
            selectionDetails: this._getSelectionDetails(),
            });
        },
        });

        this.state = {
            ...props,
          items: [],
          selectionDetails: this._getSelectionDetails(),
        };
    }

    public render() {
        if(this.props.searchState != eSearchStatus.Results){
          return (<Modal
            isOpen={this.state.isOpen}
            onDismiss={(e)=>{this.setState({...this.state,isOpen:false})}}
            isBlocking={false}
            containerClassName={contentStyles.container}
          ><br></br><br></br><Spinner size={SpinnerSize.large} label="Searching For Travelers..." /></Modal>)
        }
        const columnProps: Partial<IStackProps> = {
          tokens: { childrenGap: 2 },
          styles: { root: [{ width: '80%', 
            padding: 2 } ]},
          
        };
    return (
    <div>
         <>
         <ThemeProvider theme={SearchTableTheme}>
         <Modal
        isOpen={this.state.isOpen}
        onDismiss={(e)=>{this.setState({...this.state,isOpen:false})}}
        isBlocking={false}
        containerClassName={LoadingStyles.container}
      >
        <div style={{display:'flex',justifyContent:'end'}}>
        <IconButton
            iconProps={cancelIcon}
            ariaLabel="Close modal"
            onClick={(e)=>{this.setState({...this.state,isOpen:false})}}
            
          />
          </div>
        <div className={contentStyles.header}>
          <span style={{color:'black'}}>Search Results</span>
          
        </div>
        <div className={contentStyles.body}>
          <p>Please select the correct profile.</p>
        </div>
         <MarqueeSelection selection={this._selection}>
           <DetailsList
             items={this.props.searchResults?.map((x)=> { return ({
                 key: x.id,
                 name: x.lastName +"/ " +x.firstName +" "+ (x.middleName ?? ''),
                 gender: (x.gender==1 ? "M" : x.gender == 2?"F" : x.gender==283240002?"X":"U"),
                 dob: new Date(x.dateOfBirth??new Date()).toLocaleDateString().split("T")[0]
               })})??[]}
             columns={this._columns}
             setKey="set"
             layoutMode={DetailsListLayoutMode.justified}
             selection={this._selection}
             selectionPreservedOnEmptyClick={true}
             checkboxVisibility={CheckboxVisibility.always}
             onItemInvoked={this._onItemInvoked}
             onRenderRow={renderRow}
             selectionMode={SelectionMode.single}
           />
         </MarqueeSelection>
         <p></p>
         <Stack
          horizontal
          horizontalAlign="space-around"
          tokens={columnProps.tokens}
         
        >
         <PrimaryButton
          text="Add Traveler"
          onClick={() => {
            console.log("Adding searched Traveler ");
            this.props.addTravelers(this.state.selectionDetails[0]);
            this.setState({...this.state,isOpen:false})
            
          }}
        /></Stack>
        <p></p>
      </Modal>
      </ThemeProvider>
       </>
    </div>
    );
}

private _onItemInvoked(item: any): void {
    alert(`Item invoked: ${item.name}`);
}

private _getSelectionDetails(): PortalModels.TravelerProfiles[] {
    
    return this.props.searchResults?.filter(x=>this._selection.getSelection().map(y=>y.key as string).includes(x.id))??[];
    
}
}
