import * as React from "react";
import { Announced } from "@fluentui/react/lib/Announced";
import { TextField, ITextFieldStyles } from "@fluentui/react/lib/TextField";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IColumn,
  CheckboxVisibility,
  SelectionMode,
  
} from "@fluentui/react/lib/DetailsList";
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import { createTheme, mergeStyles } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text";
import * as PortalEnums from "../../Contexts/Enums";
import * as PortalModels from "../../Contexts/Models";
import { Label, PrimaryButton, ThemeProvider, TooltipHost } from "@fluentui/react";

const travelPanelTheme = createTheme({
  fonts: {
    small: {
      fontSize: "12px",
    },
    medium: {
      fontSize: "15px",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },
  palette: {
    themePrimary: "#454545",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#b39e51",
    themeDark: "#918e2a",
    themeDarker: "#004578",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#323130",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    bodyText: "#323130",
  
  },
});

const calloutProps= {gapSpace:0}

const exampleChildClass = mergeStyles({
  display: "block",
  marginBottom: "10px",
});

export interface IFrequentFlyerProps {
  flyerInfo?: PortalModels.FFInfo[];
  removeFF(selection:PortalModels.FFInfo):void
}

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: { maxWidth: "300px" },
};

export interface IDetailsListItem {
  key: number;
  name: string;
  value: number;
}

export interface IDetailsListState {
  items: IDetailsListItem[];
  selectionDetails?: PortalModels.FFInfo;
}



export class AirlineDetailsList extends React.Component<
  IFrequentFlyerProps,
  IDetailsListState
> {
  private _selection: Selection;
  private _columns: IColumn[];

  constructor(props: IFrequentFlyerProps) {
    super(props);
    this._selection = new Selection({
      onSelectionChanged: () =>
        this.setState({ selectionDetails: this._getSelectionDetails() }),
    });

    this._columns = [
      {
        key: "column1",
        name: "Airline",
        fieldName: "airline",
        minWidth: 200,
        maxWidth: 200,
        isResizable: false,
      },
      {
        key: "column2",
        name: "Number",
        fieldName: "number",
        minWidth: 200,
        maxWidth: 200,
        isResizable: false,
      },
    ];

    this.state = {
      ...props,
      items: [],
      selectionDetails: this._getSelectionDetails(),
    };
  }

  public render(): JSX.Element {
    const { items, selectionDetails } = this.state;
    var ScreenWidth= window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var screencheck=  ScreenWidth<=600 ? ScreenWidth-30 : '';

    if (this.props.flyerInfo?.length == 0) {
      return <Label>No Frequent Flyer Airlines added.<p/></Label>;
    }

    return (
      <div style={{width:(screencheck)}}>
        
        <ThemeProvider theme={travelPanelTheme}>
        <MarqueeSelection selection={this._selection}>
          <DetailsList
            // items={items}
            items={
              this.props.flyerInfo?.map((x: any, i) => {
                return {
                  key: i,
                  airline: x.chainCode,
                  number: x.guestNumber,
                };
              }) ?? []
            }
            columns={this._columns}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            selection={this._selection}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={CheckboxVisibility.always}
            checkButtonAriaLabel="select row"
            onItemInvoked={this._onItemInvoked}
            selectionMode={SelectionMode.single}
          />
        </MarqueeSelection>
            <br/>
            <TooltipHost
              content="Only available for new entries"
              calloutProps={calloutProps}

              >
        <PrimaryButton
          disabled={!this.state.selectionDetails || (!!this.state.selectionDetails.id && this.state.selectionDetails.id !='')}
          text="Delete Entry"
          aria-label="Only available for new entries"
          onClick={(e)=>{if(selectionDetails)this.props.removeFF(selectionDetails);}}
        />
        </TooltipHost>
        </ThemeProvider>
      </div>
    );
  }

  private _getSelectionDetails(): PortalModels.FFInfo | undefined {
    const selectionCount = this._selection.getSelectedCount();

    if (this._selection.getSelectedCount() == 1) {
      if (this.props.flyerInfo )
        return this.props.flyerInfo[
          this._selection.getSelection()[0].key as number
        ];
    }

    return undefined;
  }

  private _onItemInvoked = (item: IDetailsListItem): void => {
    alert(`Item invoked: ${item.name}`);
  };
}
