//to search profiles in

import React, { useState, useEffect, useCallback } from "react";
import "./NewTraveler.css";
import * as PortalModels from "../../../Contexts/Models";
import {
  createTheme,
  TextField,
  Stack,
  IStackProps,
  IStackStyles,
  Dropdown,
  mergeStyles,
  mergeStyleSets,
  IDropdownStyles,
  IDropdownOption,
  PrimaryButton,
  Text,
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton,
  DatePicker,
  defaultDatePickerStrings,
  IDatePicker,
  MaskedTextField,
  Spinner,
  SpinnerSize,
  Label,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { Console, debug } from "console";
import { debuglog } from "util";
import { useBoolean } from "@fluentui/react-hooks";
import CrmService from "../../../Contexts/CrmService";
import { Component } from "react";

import useAxios from "../../Hooks/useAxios";
import { eSearchStatus } from "../../../Contexts/Enums";
import  ModalResult  from "./SearchResultModal";
import axios, { AxiosResponse } from "axios";
import { ResultTable } from "./ResultTable";
import { FontWeights, getTheme, IconButton, IIconProps,IButtonStyles, Modal, ThemeProvider } from '@fluentui/react';

//make sure to adding loading state to show its loading
//useEffect for axios search?

const travelPanelTheme = createTheme({
  fonts: {
    small: {
      fontSize: "15px",
    },
    medium: {
      fontSize: "17px",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },
  palette: {
    themePrimary: "#454545",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#b39e51",
    themeDark: "#918e2a",
    themeDarker: "#004578",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#323130",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    bodyText: "#323130",
  },
});

export interface ISearchProps {
  agentId:string;
  agencyId:string;
  onSearchTraveler(traveler: PortalModels.TravelerProfiles): void;
}
export interface ISearchState {
  travelerInfo: PortalModels.TravelerSearch;
  searchStatus?: eSearchStatus;
  searchResults?: PortalModels.TravelerProfiles[];
}

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  header: [
    travelPanelTheme.fonts.large,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${travelPanelTheme.palette.themePrimary}`,
      color: travelPanelTheme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],

  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});

const onFormatDate = (date?: any): string => {
  if(!date){
    return '';
  }
  else{
    return (date?.getMonth() + 1) + '/' + date?.getDate() +  '/' + (date?.getFullYear() );
  }
};

const onFormatPassDate = (date?:any): string => {
  if(!date){
    return ''
  }else{
  return (date?.getMonth() + 1) +  '/' + (date?.getFullYear() );}
};

const SearchTraveler = (props: ISearchProps) => {
  const [enteredSearchInfo, setEnteredSearchInfo] = useState<ISearchState>({
    searchStatus: eSearchStatus.NotSearching,
    searchResults: [],
    travelerInfo: {
      agentId: props.agentId,
      agencyId: props.agencyId,
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: undefined,
      gender: undefined,
      email: "",
      telephone: "",
      passportNumber: "",
      passportIssuingCountry: "",
      globalEntry: "",
      redressNumber: "",
      knownTraveler: "",
      tsaPrecheck: "",
    },
  });
  const cancelIcon: IIconProps = { iconName: "Cancel" };
  const HandleSearch = (event: any) => {
    console.log(`${event.target.name}:${event.target.value}`);
    var newState: any = {
      ...enteredSearchInfo,
    };
    newState.travelerInfo[event.target.name] = event.target.value;
    setEnteredSearchInfo(newState);
  };

  const handleSearchSubmit = (event: any) => {
    event?.preventDefault(); //prevents the submit button from refreshing the page
      console.log("search submitted")
      setEnteredSearchInfo({
      ...enteredSearchInfo,
      searchStatus: eSearchStatus.Searching,
    });
    axios
      .post(
        "https://prod-39.westus.logic.azure.com:443/workflows/933a378072a04da4ba64733eb0543134/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=mrNvxkAQ3DDSe_US0pKsQod1d7CaDiRcBHazd56W9FQ"
      ,enteredSearchInfo.travelerInfo)
      .then(
        (response: AxiosResponse<PortalModels.TravelerProfiles[]>) => {
          if (response.status == 404 || response.data.length == 0) {
            setEnteredSearchInfo({
              ...enteredSearchInfo,
              searchStatus: eSearchStatus.NoResults,
            });
          } else {
            setEnteredSearchInfo({
              ...enteredSearchInfo,
              searchStatus: eSearchStatus.Results,
              searchResults: response.data,
            });
          }
        }
      )
      .catch((r) => {
        setEnteredSearchInfo({
          ...enteredSearchInfo,
          searchStatus: eSearchStatus.ServerError,
          searchResults: [],
        });
      });
  };
  const LoadingStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    header: [
      travelPanelTheme.fonts.large,
      {
        flex: "1 1 auto",
        borderTop: `4px solid ${travelPanelTheme.palette.themePrimary}`,
        color: travelPanelTheme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "12px 12px 14px 24px",
      },
    ],
  
    body: {
      flex: "4 4 auto",
      padding: "0 24px 24px 24px",
      overflowY: "hidden",
      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
    },  
  });

  const datePickerRef = React.useRef<IDatePicker>(null);

  const styles = mergeStyleSets({
    root: { selectors: { "> *": { marginBottom: 15 } } },
    control: { minWidth: 175, maxWidth: 175, marginBottom: 15 },
    display: "flex",
  });

  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [showCalendar, { toggle: toggleShowCalendar, setFalse: hideCalendar }] =
    useBoolean(false);

  const onSelectDate = React.useCallback(
    (date: Date, dateRangeArray: Date[]): void => {
      setSelectedDate(date);
      hideCalendar();
    },
    [hideCalendar]
  );
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const stackStyles: Partial<IStackStyles> = {
    root: [{ width: 700, height: "100%", padding: 25, display: "flex" }],
  };

  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 2 },
    styles: { root: [{ width: "80%", padding: 2 }] },
  };

  const stackItemStyles = mergeStyles({
    alignItems: "center",
    display: "flex",
    height: 50,
    justifyContent: "center",
    width: 50,
  });

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 200 },
  };

  const options: IDropdownOption[] = [
    { key: 1, text: "Male" },
    { key: 2, text: "Female" },
    { key: 283240002, text: "Unspecified" },
    { key: 283240001, text: "Undisclosed" },
  ];

  const enteredFirstNameIsValid = enteredSearchInfo.travelerInfo.firstName.trim() !=='';
  const enteredLastNameIsValid = enteredSearchInfo.travelerInfo.lastName.trim() !=='';
  const dobIsvalid= enteredSearchInfo.travelerInfo.dateOfBirth !== undefined;
  
  
  let formIsValid = false;

  if (enteredFirstNameIsValid && enteredLastNameIsValid &&dobIsvalid ){
    formIsValid=true;
  }


  const searchPanel = (props: ISearchProps): JSX.Element => {
    return (
      <>
        {/* <form onSubmit={handleSearchSubmit}> */}
          <Stack
            horizontal
            horizontalAlign="space-around"
            tokens={columnProps.tokens}
            // disableShrink
            // wrap
          >
            <Stack {...columnProps}>
              <TextField
                theme={travelPanelTheme}
                label="First Name"
                name="firstName"
                value={enteredSearchInfo.travelerInfo.firstName}
                required
                onChange={HandleSearch}
                className="payment-info-field"
              />

              <TextField
                theme={travelPanelTheme}
                label="Middle Name"
                value={enteredSearchInfo.travelerInfo.middleName}
                name="middleName"
                onChange={HandleSearch}
              />

              <TextField
                theme={travelPanelTheme}
                label="Last Name"
                value={enteredSearchInfo.travelerInfo.lastName}
                name="lastName"
                required
                onChange={HandleSearch}
              />
              <Label> Date of Birth <span className="star"> *</span>
              </Label>
              <DatePicker
                placeholder="Date of Birth"
                //isRequired
                theme={travelPanelTheme}
                componentRef={datePickerRef}
                allowTextInput
                showGoToToday={false}
                showMonthPickerAsOverlay={true}
                maxDate={new Date()}
                formatDate={onFormatDate}
                value={enteredSearchInfo.travelerInfo.dateOfBirth ?? undefined}
                onSelectDate={(v) =>
                  setEnteredSearchInfo({
                    ...enteredSearchInfo,
                    travelerInfo: {
                      ...enteredSearchInfo.travelerInfo,
                      dateOfBirth: v,
                    },
                  })
                }
                style={styles}
               strings={defaultDatePickerStrings}
              />
            </Stack>
          </Stack>
          <br />
          <br />
          <div>
            <Stack horizontal horizontalAlign="space-evenly" disableShrink wrap>
              <PrimaryButton disabled= {!formIsValid} text="Search for Profile" onClick={handleSearchSubmit}/>
            </Stack>
          </div>
      </>
    );
  };
  if (enteredSearchInfo.searchStatus == eSearchStatus.Searching) {
    return (
      <>
        <ResultTable 
          searchState={enteredSearchInfo.searchStatus} 
          searchResults={[]} isOpen={true} 
          addTravelers={(p) => props.onSearchTraveler(p)} 
          closePanel={()=>setEnteredSearchInfo({
            ...enteredSearchInfo,
            searchStatus: enteredSearchInfo.searchStatus == eSearchStatus.NoResults? eSearchStatus.NoResults: eSearchStatus.NotSearching,
            searchResults: [],})} 
          onDismiss={()=>setEnteredSearchInfo({
            ...enteredSearchInfo,
            searchStatus: enteredSearchInfo.searchStatus == eSearchStatus.NoResults? eSearchStatus.NoResults: eSearchStatus.NotSearching,
            searchResults: [],})}/>
          
        {searchPanel(props)}
      </>
    );
  } else if (enteredSearchInfo.searchStatus == eSearchStatus.Results) {
    return (
      <>
        <ResultTable searchState={enteredSearchInfo.searchStatus} searchResults={enteredSearchInfo.searchResults}isOpen={true} addTravelers={(p)=>props.onSearchTraveler(p)} onDismiss={()=>setEnteredSearchInfo({
          ...enteredSearchInfo,
          searchStatus: eSearchStatus.NotSearching,
          searchResults: [],})} 
          closePanel={()=>setEnteredSearchInfo({
            ...enteredSearchInfo,
            searchStatus: eSearchStatus.NotSearching,
            searchResults: [],})}
          
        />
        {searchPanel(props)}
      </>
    );
    //Show Modal
  } else if (enteredSearchInfo.searchStatus == eSearchStatus.ServerError) {
    return (
      <>
  
        <ThemeProvider theme={travelPanelTheme}>
        <Modal
        isOpen={enteredSearchInfo.searchStatus == eSearchStatus.ServerError}
        onDismiss={()=>setEnteredSearchInfo({
          ...enteredSearchInfo,
          searchStatus: eSearchStatus.NotSearching,
          searchResults: [],})}
        isBlocking={false}
        containerClassName={LoadingStyles.container}
      >
        <div style={{display:'flex',justifyContent:'end'}}>
        <IconButton
            iconProps={cancelIcon}
            ariaLabel="Close modal"
            onClick={()=>setEnteredSearchInfo({
              ...enteredSearchInfo,
              searchStatus: eSearchStatus.NotSearching,
              searchResults: [],})}
            
          />
          </div>
        <div className={contentStyles.header}>
          <span style={{color:'black'}}>Error! </span>
          
        </div>
        <div className={contentStyles.body}>
          <p>We ran into an issue. Please close modal and try again. Do not refresh browser or data will be lost. </p>
        </div>
         
        <p></p>
        </Modal>
      </ThemeProvider>
   {searchPanel(props)}</>);
  } else if (enteredSearchInfo.searchStatus == eSearchStatus.NoResults) {
    return (
      <>
  
         
        <ThemeProvider theme={travelPanelTheme}>
        <Modal
        isOpen={enteredSearchInfo.searchStatus == eSearchStatus.NoResults}
        onDismiss={()=>setEnteredSearchInfo({
          ...enteredSearchInfo,
          searchStatus: eSearchStatus.NotSearching,
          searchResults: [],})}
        isBlocking={false}
        containerClassName={LoadingStyles.container}
      >
        <div style={{display:'flex',justifyContent:'end'}}>
        <IconButton
            iconProps={cancelIcon}
            ariaLabel="Close modal"
            onClick={()=>setEnteredSearchInfo({
              ...enteredSearchInfo,
              searchStatus: eSearchStatus.NotSearching,
              searchResults: [],})}
            
          />
          </div>
        <div className={contentStyles.header}>
          <span style={{color:'black'}}>No Traveler Found </span>
          
        </div>
        <div className={contentStyles.body}>
          <p>The traveler you searched for wasn't found. Please close this window and click on "Add New Traveler".</p>
        </div>
         
        <p></p>
        </Modal>
      </ThemeProvider>
   {searchPanel(props)}</>);
  } 
  
  
  else {
    return (
      <>
{/*       //show error modal to let them know that the search isn't working?*/}
      
      
   {searchPanel(props)}</>);
  }
};

export default SearchTraveler;
