import * as React from 'react';
import { DefaultButton, PrimaryButton, Label, ThemeProvider, IComboBoxOption } from '@fluentui/react';
import { Panel, PanelType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Dialog, DialogFooter, DialogType, DetailsList, createTheme } from '@fluentui/react';
import NewTraveler from './Form/NewTraveler';
import CrmService from '../../Contexts/CrmService';
import * as PortalModels from '../../Contexts/Models';
import * as PortalEnums from '../../Contexts/Enums';

const travelPanelTheme = createTheme({
  fonts: {
    small: {
      fontSize: "12px",
    },
    medium: {
      fontSize: "15px",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },
  palette: {
    themePrimary: "#454545",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#b39e51",
    themeDark: "#918e2a",
    themeDarker: "#004578",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#323130",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    bodyText: "#323130",
  
  },
});

//might set up a ternery expression for the panel. 
const dialogContentProps = {
  type: DialogType.normal,
  title: 'Are you sure you want to close the panel?',
};
const dialogModalProps = {
  isBlocking: true,
  styles: { main: { maxWidth: 450 } },
};

export interface ITravelerPanelProps{
  crmService:CrmService;
  isOpen:boolean;
  openAsAdd:boolean;
  addTravelers(profiles: PortalModels.TravelerProfiles[]):void;
  editTraveler?:PortalModels.TravelerProfiles;
  closePanel():void;
  mealGuestOptions?:IComboBoxOption[];
  wheelchairGuestOptions?:IComboBoxOption[];
  frequentGuestOptions?:IComboBoxOption[]
  autoTimerStart: boolean;
}


export const TravelerPanel: React.FunctionComponent<ITravelerPanelProps> = (props:ITravelerPanelProps) => {
  const [isOpen, setIsPanelOpen] = React.useState(false);
  const [isDialogVisible, setIsDialogVisible] = React.useState(false);
  const [pendTravel,setPendTravel] = React.useState(false);
  const hideDialog = React.useCallback(() => setIsDialogVisible(false), []);
  const [pageNumber,setPageNumber]=React.useState(1);

  const hideDialogAndPanel = React.useCallback(() => {
    
    props.closePanel();
    setIsDialogVisible(false);
  }, []);
  
  const onSaveTraveler=(enteredTravelerInfo:any)=>{
    const pendTravel={
      ...enteredTravelerInfo,
      //pendKey: true,
    }
    console.log(pendTravel)

  }
  

  const onDismiss = React.useCallback((ev?: React.SyntheticEvent | KeyboardEvent) => {
    if (ev) {
      // Instead of closing the panel immediately, cancel that action and show a dialog
      ev.preventDefault();
      setIsDialogVisible(true);
    }
  }, []);

  
  return (

    
    <div>
      <ThemeProvider theme={travelPanelTheme}>
      <Panel
      // theme={travelPanelTheme}
        headerText={props.editTraveler?"Update Profile": "Add New Traveler"}
        isOpen={props.isOpen}
        onDismiss={onDismiss}
        hasCloseButton={pageNumber==1? true : false}
        type={PanelType.medium}
        closeButtonAriaLabel="Close"// You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        
      >
          
              <NewTraveler 
              page={pageNumber}
              onIncreasePage={()=>{setPageNumber(pageNumber+1)}}
              onDecreasePage={()=>{setPageNumber(pageNumber-1)}}
              onSaveTraveler={(t: PortalModels.TravelerProfiles)=>{
                console.log(`PANEL Adding: ${t}`);
                props.addTravelers([t])
                props.closePanel();
                setIsDialogVisible(false);
              }}
              editTraveler={props.editTraveler}
              autoTimerStart={props.autoTimerStart}
              
              frequentGuestOptions={props.frequentGuestOptions}
              mealGuestOptions={props.mealGuestOptions}
              wheelchairGuestOptions={props.wheelchairGuestOptions}
              />
       
      </Panel>
     
      <Dialog
        hidden={!isDialogVisible}
        onDismiss={hideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={dialogModalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={hideDialogAndPanel} text="Yes" />
          <DefaultButton onClick={hideDialog} text="No" />
        </DialogFooter>
      </Dialog>
      </ThemeProvider>
    </div>
  );

};
