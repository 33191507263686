import React, { Component, useEffect, useRef, useState } from "react";
import { Label, PrimaryButton, Stack,  } from "@fluentui/react";
import "./DisplayImage.css";

export interface  IPassportImageProps{
  setPassportImage(n:string|undefined|null,t:string|undefined|null,r:string|undefined|null):void;
}

function validateSize (input:any){
  const maxFileSize= input.files[0].size/ 1024/1024
}



export default function PassportImage(props:IPassportImageProps) {
  const [image, setImage]=useState<File |null>();
  const [preview,setPreview]= useState<string|null>();
  const fileInputRef = useRef<HTMLInputElement|null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState<number|any>(null);
  
  useEffect(()=>{
    if(image){
      const reader = new FileReader();
      reader.onloadend=() => {
        props.setPassportImage(image.name,image.type,reader.result as string)
        setPreview(reader.result as string);
      }
      reader.readAsDataURL(image);

    } else {
      props.setPassportImage("","","" as string)
      setPreview(null);
    }
  },[image]);

 
  
  return(
    <>
    <Stack
          horizontal
          horizontalAlign="space-evenly"
          disableShrink
          wrap
          >
    {preview && image?.type.includes('image') ? 
    (<>
    
    <img src={preview} alt="Passport Preview" style={{objectFit:'cover'}} height="200px" width="300px "onClick={()=>{
      props.setPassportImage("","","" as string)
      
     }}/>
     <PrimaryButton text="Remove Passport" onClick={(event)=>{
      event.preventDefault();
      setImage(null)
    }}/>
     </>
    ):
    preview && image?.type.includes('pdf') ? 
    (<>
      <iframe src={preview} id='pdf'/>
       <PrimaryButton text="Remove Passport" onClick={(event)=>{
      event.preventDefault();
      setImage(null)
    }}/>
    </>):
    (
    <PrimaryButton onClick={(event)=>{
      event.preventDefault();
      fileInputRef.current?.click();
    }}
      text='Upload Passport'/>
    )}<Label style={{fontSize: 12}}>Image must be less than 5mb. Accepted files: PNG, JPG, JPEG, PDF.</Label>
    <input type ="file"
    id="file"
    value=""
    style={{display:"none"}} 
    accept= "image/png, image/jpg, application/pdf, image/jpeg" 
    
    ref={fileInputRef} 
    onChange={(event)=>{
      const files = event.target.files;
      const file = files?.item(0);
      if((file && file.type.substring(0,5)==="image") || (file && file?.type.includes('pdf'))){
        setImage(file);
      } else {
        setImage(null)
      }
    }}/>
    </Stack>
    </>
  )
}