import { IDropdownOption } from "@fluentui/react";
import { eColumnType, eGridOperation, PreferLevelType,  FlightTypeType  } from "./Enums";


//traveler

export interface SRDocs {
  id: string;
  fullName: string;
  travelerFOP: IDropdownOption;
  travelerType: IDropdownOption;
  dateOfBirth: Date;
  preferredSeating: IDropdownOption;
  travelerEmail: string;
  travelerPhone: string;
  globalEntry: string;
  tsaPrecheck: string;
  redressNumber: string;
  gender: IDropdownOption;
  passportNumber: string;
  passportExpiry: string;
  passportIssueCountry: string;
  specialMeal: string;
  wheelchair: string;
  notes: string;
}

export interface FrequentCarrier {
  id: number;
  passenger: IDropdownOption;
  carrierCode: string;
  ffNumber: string;
}
export interface FFPacket {
  passenger: string;
  carrierCode: string;
  ffNumber: string;
}

export interface SimpleTravelerProfile {
  id: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  dateOfBirth: Date;
  title?: string;
}

export interface SRDocsPacket {
  id: string;
  fullName: string;
  travelerFOP: string;
  travelerType: string;
  dateOfBirth: Date;
  preferredSeating: string;
  travelerEmail: string;
  travelerPhone: string;
  globalEntry: string;
  tsaPrecheck: string;
  redressNumber: string;
  gender: string;
  passportNumber: string;
  passportExpiry: string;
  passportIssueCountry: string;
  specialMeal: string;
  wheelchair: string;
  notes: string;
}
export interface CrmPacket {
  pnrId: string;
  pInfo: PaymentPacket[];
  tInfo: SRDocsPacket[];
  fInfo: FFPacket[];
}

export interface EditTableColumns {
  name: string;
  editable: boolean;
  display: string;
  maxChars: number;
  fieldType: eColumnType;
  visible: boolean;
  required: boolean;
  options: IDropdownOption[];


}

export interface FFInfo {
  id: string;
  chainCode: string;
  guestNumber: string;
}

export interface TravelerProfiles {
  index?: number;
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth:  Date|undefined;
  gender: number | undefined;
  email: string;
  telephone: string;
  citizenship: string | undefined;
  passportNumber: string;
  passportIssuingCountry: string | undefined;
  passportExpiration?:  Date|undefined;
  globalEntry: string;
  redressNumber: string;
  knownTraveler: string;
  tsaPrecheck: string;
  preferredAirport: string; //default?
  preferredClassOfService?: number; //default?
  preferredSeating?: number; //default?
  seatingRequests: string;
  meals?: string | undefined; //default?
  mealOther: string | undefined;
  wheelchair: string | undefined;
  preferredRoomType: string;
  preferredBedType: string;
  htlSpecialRequests: string;
  carTypePreference: string;
  carSpecialRequests: string;
  frequentGuestInfo: FFInfo[];
  PassportScan?:string|undefined|null;
  passportScanMimeType?: string|undefined|null;
  passportScanFileName?: string|undefined|null;
  dateOfBirthText?: string|undefined|null;
}

export interface TravelerSearch {
  agentId: string;
  agencyId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date | null | undefined;
  gender: number | undefined;
  email: string;
  telephone: string;
  passportNumber: string;
  passportIssuingCountry: string;
  globalEntry: string;
  redressNumber: string;
  knownTraveler: string;
  tsaPrecheck: string;
}

//payment

export interface PaymentInfo {
  id: string;
  gridDataType: string; // Used if we need to send the FOP back to dynamics or not
  paymentType: IDropdownOption;
  paymentNumber: IDropdownOption;
  paymentCode: IDropdownOption;
  expiration: Date;
  holderName: string;
  billingStreet: string;
  billingCity: string;
  billingState: string;
  billingCountry: string;
  billingZip: string;
  billingPhone: string;
  billingEmail: string;
  keepOnFile: IDropdownOption;
  customerSignature?: string;
}

export interface PaymentPacket {
  paymentData: string;
}

export interface GridOperation {
  operation: eGridOperation;
  data: any;
}

export interface GetFromTokenResponse {
  a: string;
  b: string;
  c: string;
  pnrId: string;
  info: PaymentPortalInfo;
}

export interface PaymentTravelerInfo {
  travelerName: string;
  id: string;
  associatedPaymentId?: string | null;
  segments?:ClientPortalSegment[];
}
export interface ClientPortalPayment {
  id: string;
  fopName: string;
  fopId: string;
  newInfo?: PaymentInfoUpdate;
  associatedClients: string[];
  associatedSegments?:string[];
}

export interface PaymentPortalInfo {
  clients: PaymentTravelerInfo[];
  payments?: ClientPortalPayment[];
  segments?: ClientPortalSegment[];
}

export interface PaymentInfoUpdate {
  cardType?: number;
  holder?: string;
  cardNo?: string;
  expiration?: Date | null;
  cardCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  airlinePhone?: string;
  email?: string;
  leadTraveler?: string;
  specialRequests?: string;
}

export interface PortalPayment {
  pnrId: string;
  payments: ClientPortalPayment[];
}

export interface GetCaseFromTokenResponse {
  caseId: string;
  agentId: string;
  agencyId: string;
  a: string;
  b: string;
  c: string;
  travelers: TravelerProfiles[];
  ticketNumber:string;
}

export interface ClientPortalSegment {
  [x: string]: any;
  id: string;
  segmentNumber:string;
  chainCode:string; //airline code
  cityPair:string; //the airports arrival/departure 
}

export interface CasePaymentInfo {
  agencyId: string;
  agentId: string;
  a: string;
  b:string;
  c:string;
  ticketNumber:string;
  caseId:string;
  associatedPNRs: ReservationInfo[];  
}

export interface ReservationInfo {
  recordLocator:string;
  pnrId: string;
  info: PaymentPortalInfo;
}

export interface CasePayment {
  agencyId: string;
  agentId: string;
  caseId:string;
  payments: PortalPayment[];
}

export interface CasePaymentGroupItem {
  key: string;//segmentid;travelerid;pnrid;
  associatedPaymentId?: string|null;
  iconName: string;
  name: string;
  segmentNumber:string;
  chainCode:string; //airline code
  cityPair:string; //the airports arrival/departure
  travelerId:string;
  travelerName:string;
  pnrId:string;
  recordLocator:string;
  segmentId:string;
}

export interface PassportImage {
  
}


//AirPortal

export enum AirTripType {
  RoundTrip = 0,
  OneWay = 1,
  MultiCity = 2
}



export interface GetAirFromTokenResponse {
  caseId: string;
  agentId: string;
  agencyId: string;
  shopRequest: [];
  storedRequest: BargainFinderRequest; 
  cityInfo: AiportList;
}

export interface IDiction {
  FlightTypeType: string;
}

export interface BargainFinderRequest {
  agencyId: string;
  agentId: string;
  caseId: string;
  quotedPccs: string[];
  shoppingInfo: ShoppingInfo[];
  tripType: AirTripType;
  vendorPreferences: []//{ [key: string]: PreferLevelType; };
  flightPreferences: string;
  cabinInfo: {}//IDictioner;
  travelerTypes: {};//{ [key: string]: number; };
  interlineOnly: boolean | null;
}

export interface BargainFinderResponse {
  RecordLocator: string;
  numInParty: number;
  possibleItineraries: ItineraryOption[];
  }

export interface ItineraryOption {
  itineraries: OriginDestinationInfo[];
  pricingOptions: FareInfo[];
}

export interface OriginDestinationInfo {
  elapsedTime: number;
  departureCountry: string;
  arrivalCountry: string;
  details: FlightSegmentDetails[];
}

export interface FareInfo {
  minSeats: number;
  seatsRemaining: number[];
  validatingCarrier: string;
  itinBaseFare: number;
  itinTotalTax: number;
  itinTotalFare: number;
  deviationFromLowestFare: number;
  baseFare: { [key: string]: number; };
  tax: { [key: string]: number; };
  totalFare: { [key: string]: number; };
  commissions: { [key: string]: CommissionDetails; };
  cat35CommissionAmount: number;
  endorsement: string;
  cabinCode: string[];
  fareBasisCodes: FareBasisCode[];
  brandDetails: BrandInfo[];
  classOfService: string[];
  meals: string[];
  baggage: BaggageInfo[];
}

export interface BaggageInfo {
  associatedSegments: number[];
  chainCode: string;
  numBags: number;
  bagType: string;
  baggageFee: number;
  description: string;
}
export interface CommissionDetails {
  commissionAmount: number;
  commissionPercentage: number;
  contractQualifier: string;
}



export interface SaveSelectedQuoteRequest {
  
}



export interface AiportList {
  id: string;
  city: string;
  country: string;
  IATA: string;
  Region: string;
}

export interface FlightSegmentDetails {
  segNum: number;
  startCity: string;
  endCity: string;
  startDate: string;
  endDate: string;
  marketingChain: string;
  marketingFlightNum: string;
  operatingChain: string;
  operatingFlightNum: string;
  operatingCompanyName: string;
  marriageGroup: string;
  startTerminal: string;
  endTerminal: string;
  equipmentType: string;
  funnelFlight: boolean;
  stopQuantity: number;
  hiddenCities: string[];
  travelTimeMinutes: number;
  miles: number;
  startCityTimezoneOffset: number;
  endCityTimezoneOffset: number;
}


export interface FareBasisCode {
  code: string;
  appliesToSegment: number;
}


export interface BrandInfo {
  appliesToSegments: number[];
  brandId: string;
  brandName: string;
  programID: string;
  programCode: string;
  programDescription: string;
  programSystemCode: string;
  
}

export interface OriginDestinationInfo {
  elapsedTime: number;
  departureCountry: string;
  arrivalCountry: string;
  details: FlightSegmentDetails[];
}


export interface ShoppingInfo {
  originCity: string;
  allAirportsInOrigin: boolean;
  requireOriginAsAirport: boolean;
  destinationCity: string;
  requireDestinationAsAirport: boolean;
  allAirportsInDestination: boolean;
  tripDate: string;
  tripRangeStart: string;
  tripRangeEnd: string;
  //tripDateType: ItemChoiceType;
  //connections: { [key: string]: AllowedExcludedPreferLevelType; };
}

export interface ShoppingRequest {
  tripType:TripType;
  travelers:TravelerTypes;
  preferredCarriers?:{[key:string]:PreferenceLevel}|null;
  itin?:ItineraryRequest[];
}
export interface TravelerTypes
{
  aDT?: number|null;
  cHD?: number|null;
  iNF?: number|null;
}
export interface ItineraryRequest{
  classOfService?:ClassOfService|null;
  orig:string;
  dest:string;
  departureDate:string|Date;
  departureTime?:string|null;
  departureWindow?:string|null;
  maxConnections?:number|null;
  preferredConnections?:{[key:string]:PreferenceLevel}|null;
}
export interface ClassOfService
{
  [key: string]: PreferenceLevel | null;
  // premiumFirst?:PreferenceLevel|null;
  // first?:PreferenceLevel|null;
  // premiumBusiness?:PreferenceLevel|null;
  // business?:PreferenceLevel|null;
  // premiumEconomy?:PreferenceLevel|null;
  // economy?:PreferenceLevel|null;
  // y?:PreferenceLevel|null;
  // s?:PreferenceLevel|null;
  // c?:PreferenceLevel|null;
  // j?:PreferenceLevel|null;
  // f?:PreferenceLevel|null;
  // p?:PreferenceLevel|null;
}
export enum PreferenceLevel
{
  None = 0,
  Preferred = 1,
  Only = 2
}
export enum TripType
{
  RoundTrip = 0,
  OneWay = 1,
  MultiCity = 2
}

export interface ShoppingResponse{
  errorMessage?:string|null;
  itineraries:ShoppedItinerary[];
}

export interface ShoppedItinerary{
  segments:ShoppedSegment[];
  quotes:ItineraryQuote[];
}
export interface ShoppedSegment{
  operatingFlightNumber:string;
  operatingChain:string;
  marketingChain:string;
  marketingFlightNumber: string;

  origin:string;
  destination:string;
  departureDateTime?:Date|null;
  arrivalDateTime?:Date|null;
  operatingEquipment:OperatingEquipment;
  duration?:string|null;
  miles?:string|null;
  classOfService?:string| null;
  cabin?: string|null;
  fareBasisCode?: string|null;
}
export interface OperatingEquipment{
  code:string;
  typeForFirstLeg:string;
  typeForLastLeg:string;
  aircraftCode: string;
  name: string;
}
export interface ItineraryQuote{
  quotedCity:string;
  isNDC?:boolean|null;
  totalItin:number;
  fares:ItineraryQuotedFare[];

}
export interface ItineraryQuotedFare{
  totalTax: number;
  bags: number;
  systemId: string;
  offerItemId: string;
  pricePerPassengerTypes:PricePerPassenger
}
export interface PricePerPassenger{
  passengerType: string;
  totalPrice: number;
}
export interface FrequentCarrier {
  key: string;
  text: string;
}

export interface SelectedItineraryAndQuote {
  segments: {
    operatingFlightNumber: string| undefined;
    operatingChain: string| undefined;
    marketingChain: string| undefined;
    marketingFlightNumber: string| undefined;
    origin: string| undefined;
    destination: string| undefined;
    departureDateTime?: Date | null;
    arrivalDateTime?: Date | null;
    operatingEquipment: OperatingEquipment| undefined;
    duration?: string | null;
    miles?: string | null;
    classOfService?: string | null;
    cabin?: string | null;
    fareBasisCode?: string | null;
  }[];
  quotes: {
    quotedCity: string| undefined;
    isNDC?: boolean | null;
    totalItin: number| undefined;
    fares: {
      totalTax: number| undefined;
      bags: number| undefined;
      systemId: string| undefined;
      offerItemId: string| undefined;
      pricePerPassengerTypes: {
        passengerType: string| undefined;
        totalPrice: number| undefined;
      }[];
    }[];
  }[];
}

export interface ItineraryModalProps {
  selectedData: SelectedItineraryAndQuote;
  isOpen: boolean;
  onClose: () => void;
}
