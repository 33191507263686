import React, { Component } from "react";
import {
  createTheme,
  MaskedTextField,
  Text,
  Link,
  Label,
  Checkbox,
  PrimaryButton,
  Panel,
  PanelType,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  TextField,
  Spinner,
  SpinnerSize,
  IDropdownStyles,
  WindowContext,
  ThemeProvider,
  IStackTokens,
  ITextFieldProps,
  IconButton,
  Stack,
  Callout,
  IStackStyles,
  mergeStyleSets,
  FontIcon,
  Modal,
  values,
  KeyCodes,
  StackItem,
} from "@fluentui/react";
import * as PortalModels from "../Contexts/Models";
import * as PortalEnums from "../Contexts/Enums";
import CrmService from "../Contexts/CrmService";
import PaymentService from "../Contexts/PaymentService";
import "../App.scss";
import { InvalidPathComponent } from "./InvalidPathComponent";
import { CreditCardType,CcCheksum } from "./CardType";
import { PaymentTable } from "./PaymentTable";
import moment from "moment";
import { useState,useEffect } from "react";
import { PaymentGroupedTable } from "./PaymentTableGrouped";
// import { cardExpirValidation } from "./creditCardValid";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { PaymentHelp } from "./HelpModal";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { Value } from "sass";
 //http://localhost:3000/payments/Y2FzZWlkPW54U045SjZUQTE5VmllcEFOWG55cXhFVzVTQU9QaUh3WiswR1dkbDN0QUZvUlR6Tllva0tkTVgyNU1RdmNNbHImY3VzdG9tZXI9N1VoYVlZL2FuTFdoYjB3VW5kMTdEU0F3TTZOVWc3b3A3ZW9FOE1zdG5WVjFHNEttWVZDeDZ2Yk5IQ1FLUi9IRCZ0b2tlbj1FS2lNMHlLSVlkQjBpU1lTU2tXWmdRQTVVbDZoOXF1clhuYzIzbU5nRFhrPQ==
  

export interface IPaymentComponentProps {
  token: string;
  }








export interface IPaymentComponentState
  extends React.ComponentState,
    IPaymentComponentProps {
  crmData?: PortalModels.CasePaymentInfo;
  errorState: boolean;
  loading: boolean;
  errorMessage?: string;
  selectedTravelers: string[];
  selectedPayment?: string;
  createFOPEnabled: boolean;
  newFop?: PortalModels.PaymentInfoUpdate;
  newPayments: PortalModels.CasePayment;
  displayFieldErorrs: boolean;
  tempDate?: string;
  dateErorr?: string;
  leadTraveler?: string;
  specialRequests?: string;
  showNameError: boolean;
  showNumberError: boolean;
  showExpError: boolean;
  showCVVError: boolean;
  showPhoneError: boolean;
  inadequateInfo:boolean;
  showLeadTraveler: boolean;
  acceptedTerms: boolean;
  clickedHyperlink:boolean;
  submittingInfo: boolean;
  hideDialog:boolean;
}
const paymentPanelTheme = createTheme({
  fonts: {
    small: {
      fontSize: "15px",
    },
    medium: {
      fontSize: "17px",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },
  palette: {
    themePrimary: "#0078d4",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#106ebe",
    themeDark: "#005a9e",
    themeDarker: '#d4c693',
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#323130",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    bodyText: "#323130",
  },
});


const options: IDropdownOption[] = [
  { key: PortalEnums.eCardChoice.AX, text: "American Express" },
  { key: PortalEnums.eCardChoice.VI, text: "Visa" },
  { key: PortalEnums.eCardChoice.CA, text: "Master Card" },
  { key: PortalEnums.eCardChoice.DS, text: "Discover" },
  { key: PortalEnums.eCardChoice.DC, text: "Diners Club" },
  { key: PortalEnums.eCardChoice.NS, text: "Not Supported Card Type"}
];
const getErrorMessage = (value: string): string => {
  return 1 > value.length ? "Field cannot be blank" : "";
};

const getCardError = (value: string): string => {
  if (1 > value.length){
    return "Field cannot be blank";
  } else if (!CcCheksum(value)){
    return "Please enter a valid card"
}
  else {
    return "";
  }
};






// 3 cvv visa, mastercard, discover, diners club
// 4 cvv american express


const labelCalloutStackStyles: Partial<IStackStyles> = { root: { padding: 20 } };
const stackTokens: IStackTokens = {
  childrenGap: 4,
  maxWidth: 300,
};
// const labelId: string = useId('label');
// const classNames = mergeStyleSets({
//   deepSkyBlue: [{ color: '#b39e51' }],
  
// });
// const iconProps = { iconName: 'AddIcon' };
// const CustomLabel = (props: ITextFieldProps): JSX.Element => {
//   const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
//   const descriptionId: string = useId('description');
//   const iconButtonId: string = useId('iconButton');

//   return (
//     <>
//     <ThemeProvider theme={paymentPanelTheme}>
//       <Stack horizontal verticalAlign="center" tokens={stackTokens}>
//         <Label id={props.id}>{props.label}{props.required? <span style={{color:'rgb(164, 38, 44)'}}>*</span>:''}</Label>
//         <IconButton
//           id={iconButtonId}
//           iconProps={iconProps}
//           title="Recommended"
//           ariaLabel="Recommended"
//           onClick={toggleIsCalloutVisible}
//           className={classNames.deepSkyBlue}
//         />
//       </Stack>
//       {isCalloutVisible && (
//         <Callout
//           target={'#' + iconButtonId}
//           setInitialFocus
//           onDismiss={toggleIsCalloutVisible}
//           ariaDescribedBy={descriptionId}
//           role="alertdialog"
//         >
//           <Stack tokens={stackTokens} horizontalAlign="start" styles={labelCalloutStackStyles}>
//             <span id={descriptionId}>It is recommended to fill this field out, but no required.<p/></span>
//             <DefaultButton onClick={toggleIsCalloutVisible}>Close</DefaultButton>
//           </Stack>
//         </Callout>
//       )}
//       </ThemeProvider>
//     </>
//   );
// };





export class PaymentComponent extends React.Component<IPaymentComponentProps, IPaymentComponentState> {
  private crmService: CrmService;
  private paymentService: PaymentService;
  private cardTypes: IDropdownOption[];
  private dropdownStyles: Partial<IDropdownStyles> = {
    root: { backgroundColor: "white" },
    callout: { backgroundColor: "white" },
    title: { color: "black" },
    caretDown: {},
    caretDownWrapper: {},
    dropdown: {},
    dropdownDivider: {},
    dropdownItem: { color: "black" },
    dropdownItemDisabled: {},
    dropdownItemHeader: { color: "black" },
    dropdownItemHidden: {},
    dropdownItemSelected: { backgroundColor: "b39e51" },
    dropdownItemSelectedAndDisabled: {},
    dropdownItems: { color: "black" },
    dropdownItemsWrapper: { backgroundColor: "white" },
    dropdownOptionText: { color: "black" },
    errorMessage: {},
    label: { color: "black" },
    panel: { backgroundColor: "white" },
    subComponentStyles: { label: {}, multiSelectItem: {}, panel: {} },
  };

  constructor(props: IPaymentComponentProps) {
    super(props);

    this.state = {
      ...props,
      showNameError: false,
      showNumberError: false,
      showExpError: false,
      showCVVError: false,
      showPhoneError: false,
      showLeadTraveler: false,
      acceptedPhone: false,
      acceptedTerms: false,
      createFOPEnabled: false,
      loading: true,
      displayFieldErorrs: false,
      newPayments: { caseId: "", agencyId: '', agentId:'', payments: [] },
      errorState: false,
      inadequateInfo: false,
      submittingInfo:false,
      selectedTravelers: [],
      clickedHyperlink: false,
      expirationTemp:false,
      hideDialog:true,
    }; 
    //{...props, displayFieldErorrs:false, newPayments:{pnrId:"6360f4246fadeb118236000d3a323151", payments:[]},selectedTravelers:[],errorState:false,loading:false, createFOPEnabled:false,crmData:{"pnrId":"6360f4246fadeb118236000d3a323151","info":{"clients":[{"travelerName":"GRECO/DIANNE MARIE","id":"b8e310276fadeb118236000d3a323f9d","associatedPaymentId":null},{"travelerName":"CANNING/RUSSELL ANTHONY","id":"bfe310276fadeb118236000d3a323f9d","associatedPaymentId":null}],"payments":[]}}};
    this.crmService = new CrmService();
    this.cardTypes = [];
    this.paymentService = new PaymentService("", "", "");
    const dropdownStyles: Partial<IDropdownStyles> = {
      dropdown: { width: 300 },
    };
    // this.crmService.getCasePaymentInfo(props.token).then(
    //   (result) => {
    //     var newState = { ...this.state };
    //     newState.crmData = this.crmService.ITest as PortalModels.CasePaymentInfo;
    //     this.paymentService = new PaymentService(
    //       newState.crmData.a,
    //       newState.crmData.b,
    //       newState.crmData.c
    //     );
    //     newState.newPayments.agencyId = newState.crmData.agencyId;
    //     newState.newPayments.agentId = newState.crmData.agentId;
    //     newState.newPayments.caseId = newState.crmData.caseId;
    //     newState.loading = false;
    //       if((!newState.crmData||!newState.crmData.associatedPNRs||newState.crmData?.associatedPNRs.length<1)){
    //         this.setState({...newState, inadequateInfo:true});
    //       }else {this.setState(newState);}

    this.crmService.getCasePaymentInfo(props.token).then(
      (result) => {
        var newState = { ...this.state };
        newState.crmData = result.data as PortalModels.CasePaymentInfo;
        this.paymentService = new PaymentService(
          newState.crmData.a,
          newState.crmData.b,
          newState.crmData.c
        );
        newState.newPayments.agencyId = newState.crmData.agencyId;
        newState.newPayments.agentId = newState.crmData.agentId;
        newState.newPayments.caseId = newState.crmData.caseId;
        newState.loading = false;
          if((!newState.crmData||!newState.crmData.associatedPNRs||newState.crmData?.associatedPNRs.length<1)){
            this.setState({...newState, inadequateInfo:true});
          }else {this.setState(newState);}

      },
      (error) => {
        var newState = {
          ...this.state,
          errorState: true,
            loading: false,
            errorMessage:
            "Invalid Payment URL. You are being routed to the Global First Travel Home Page",
        };
        this.setState(newState);
      }
    );
    }
   private getCvvError = (value: string): string => {
    if (1 > value.length){
      return "Field cannot be blank";
    } else if (value.length>=5 ){
      return "Please Check CVV Number"
    }
    else if (((CreditCardType(this.state.newFop?.cardNo??"")==PortalEnums.eCardChoice.CA)|| (CreditCardType(this.state.newFop?.cardNo??"")==PortalEnums.eCardChoice.DC)|| 
    (CreditCardType(this.state.newFop?.cardNo??"")==PortalEnums.eCardChoice.DS)|| (CreditCardType(this.state.newFop?.cardNo??"")==PortalEnums.eCardChoice.VI) )&& value.length != 3 ){
      return "Please Check CVV Number";
    }
    else if ((CreditCardType(this.state.newFop?.cardNo??"")==PortalEnums.eCardChoice.AX) && (value.length !=4)){
      return "Please Check CVV Number";
    }
    else {
      return ""
    }
  };
  
 

  private ccExpError =(value:string):string =>{
    var year= parseInt(new Date().getFullYear().toString());
    var month= parseInt(new Date().getMonth().toString());
    
    if(1>value.length || this.state.tempDate=='__/____'){
      this.setState({...this.state, showExpError:true})
      return (
        "Field cannot be blank.")
    } else 
    {
    var exMonth = parseInt(value?.split("/")[0]); 
    var exYear = parseInt(value?.split("/")[1]);

    if (year > exYear|| (year===exYear && month>=exMonth)|| (value?.split("/")[0])=="____"||(value?.split("/")[1])=="____"||
    (parseInt(value?.split("/")[0])>12) || (parseInt(value?.split("/")[0])==0)){
        this.setState({...this.state, showExpError:true})
      return "Please enter a valid date."
    }   
     else {
      if (value?.length==7){
      this.setState({...this.state,showExpError:false})
      return"";
      } else {
        this.setState({...this.state, showExpError:true})
      return "Please enter a valid date."
      }

    }

    
  }
  }

  
  
  private phoneIsValid = (value:string): string =>{
    if(1>value.length){
      return "Field cannot be blank";
    } 
    // else if(value.length>10) {
    //   return "Please enter valid phone number"}
    else {
      return ""
    }
  }

  public dialogContentProps = {
      type: DialogType.normal,
      title: 'Success!',
      subText: 'Payment has been submitted for the selected traveler(s).',
  };

  private closeDialog = (): void => {
    this.setState({
      ...this.state,
      hideDialog: true,
    });
  };

 
    
  
  

  public render(): JSX.Element {
    var ScreenWidth= window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (this.state.loading) {
      return <Spinner size={SpinnerSize.large} label="Retrieving Info..." />;
    } else if (this.state.errorState) {
      return (
        <>
          <InvalidPathComponent
            displayText={ 
              this.state.errorMessage ??
              "An error has occurred. You are being routed to the home page"
            }
            waitTime={5000}
          />
        </>
      );
    } else if (this.state.inadequateInfo) {
      return (
        <>
          <InvalidPathComponent
            displayText={ 
              this.state.errorMessage ??
              "Reservations are missing from this request. Please contact your Travel representative regarding case: " + this.state.crmData?.ticketNumber
            }
            waitTime={30000}
          />
        </>
      );
    }else if (this.state.submittingInfo) {
      return (
        <>
          <Spinner size={SpinnerSize.large} label="Submitting Payment..." />
          
        </>
      );
      } else {
        
      return (
        <>
        <br></br>
           <div 
           style={{maxWidth:'90%', overflow:'auto'}}
           >
           <Stack style={{ textSizeAdjust: "auto", 
           wordWrap: "normal", whiteSpace:"nowrap"}}>

            <div 
            style={{maxWidth: (ScreenWidth-40)}}
            >
            <PaymentGroupedTable
            payments={this.state.crmData?.associatedPNRs[0].info?.payments}
            travelers={this.state.crmData?.associatedPNRs[0].info?.clients}
            onSelectionChanged={this.setSelectedTravelers}
            segments={this.state.crmData?.associatedPNRs[0].info?.segments}
            caseInfo={this.state.crmData?.associatedPNRs}
            addNewPayment={this.addNewPayment}
            />
          </div>
          <br></br>
          <>
          <Stack verticalAlign="center" horizontalAlign="center">
        <Dialog
          onDismiss={this.closeDialog}
          hidden={this.state.hideDialog}
          dialogContentProps={this.dialogContentProps}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
            dragOptions:undefined
          }}
        >
        <DialogFooter>
          <Stack horizontalAlign="center">
          <PrimaryButton onClick={this.closeDialog} text="Ok"/>
          </Stack>
        </DialogFooter>
      </Dialog>
      </Stack>
      </>
         
          <Panel
            theme={paymentPanelTheme}
            headerText="Add New Form Of Payment"
            isOpen={this.state.createFOPEnabled}
            onDismiss={this.discardNewPayment}
            closeButtonAriaLabel="Close"
            type={PanelType.medium}
            onRenderFooter={this.renderFooter}
            isFooterAtBottom={true}
          >
            <Label className="payment-info-field">
              Please fill out all fields.
            </Label>

            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="Cardholder Name"
              required
              autoComplete="cc-name"
              maxLength={85}
              onGetErrorMessage={getErrorMessage}
              validateOnLoad={false}
              validateOnFocusOut={true}
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, holder: v },
                });
              }}
            />
            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="Card Number"
              required
              
              onGetErrorMessage={getCardError}
              validateOnLoad={false}
              autoComplete="cc-number"
              validateOnFocusOut
              onChange={(e, v) => {
                var nonNumericRegex = /[^0-9]+/g
                this.setState({
                  ...this.state,
                  newFop: {
                    ...this.state.newFop,
                    cardNo: v,
                    cardType: CreditCardType(v),
                  },
                });
              }}
            />
            <Dropdown
              styles={this.dropdownStyles}
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="Card Type"
              required
              options={options}
              placeholder="Please Enter Card Number"
              disabled
              
              selectedKey={this.state.newFop?.cardType}
            />
            <TextField
              label="Expiration Date"
              className="payment-info-field"
              theme={paymentPanelTheme}
              placeholder="MM/YYYY"
              required
              name="tempDate"
              validateOnLoad={false}
              maxLength={7}
              value={this.state.tempDate}
              //validateOnFocusOut
              onGetErrorMessage={this.ccExpError}
              autoComplete="cc-exp"
              
                // if (onkeydown(this:window, ev:(KeyCodes.backspace||KeyCodes.del))){
                //   this.setState({...this.state, tempDate: v})
                //   return false;
                //   }
                //else {
              onChange={(e, v) => {
                const input =v|| "";
                const regexedInput = input
                    .replace(/\D/g, '') // Remove non-digit characters
                    .slice(0, 6); 
                    let formattedInput = regexedInput.replace(
                      /^(\d{2})(\d{0,4})/,
                      (match, month, year) => {
                        
                        return month + (year ? '/' + year : '');// Format the month and year
                      }
                    );
                  
                    // Update the state with formatted input
                    this.setState({ tempDate: formattedInput });
                  }}

                //   this.setState ({...this, tempDate:v?.replace(
                //   /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
                // ).replace(
                //   /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
                // ).replace(
                //   /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
                // ).replace(
                //   /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
                // ).replace(
                //   /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
                // ).replace(
                //   /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
                // ).replace(
                //   /\/\//g, '/' // Prevent entering more than 1 `/`
                // )})}
                
                //}
              //}
             
            />

            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="CVV Code"
              type="password"
              canRevealPassword
              revealPasswordAriaLabel="Show password"
              required
              autoComplete="cc-csc"
              validateOnLoad={false}
              validateOnFocusOut
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, cardCode: v },
                });
              }}
              onGetErrorMessage={this.getCvvError}
             />
            
            
             <Label className="payment-info-field">Billing Address <span color="rgb(164, 38, 44)" className="star">*</span></Label>
            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              placeholder="Address Line 1"
              validateOnLoad={false}
              ariaLabel="addressLine1"
              autoComplete={"street-address"|| "address-line1"}
              id="addressLine1"
              required={true}
              maxLength={85}
              validateOnFocusOut
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, addressLine1: v },
                });
              }}
            />
            <p></p>
            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              placeholder="Address Line 2"
              autoComplete="address-line2"
              id="addressLine2"
              maxLength={85}
              ariaLabel="addressLine2"
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, addressLine2:v },
                });
              }}
            />
            
            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="City"
              placeholder=""
              required
              maxLength={85}
              validateOnLoad={false}
              autoComplete="address-level2"
              validateOnFocusOut
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, city: v },
                });
              }}
            />
            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="State/Country"
              placeholder=""
              required
              validateOnLoad={false}
              autoComplete="country-name"
              validateOnFocusOut
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, state: v },
                });
              }}
            />
            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="Zip"
              
              maxLength={10}
              onGetErrorMessage={getErrorMessage}
              validateOnLoad={false}
              autoComplete="postal-code"
              validateOnFocusOut
              required={true}
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, zip: v },
                });
              }}
            />
            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="Cardholder Phone"
              validateOnLoad={false}
              validateOnFocusOut
              autoComplete="tel"
              // required={this.state.newFop && !this.state.newFop.phone}
              required={true}
              onGetErrorMessage={this.phoneIsValid}
              // description='Recommended'
              // aria-label="Recommended"
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, phone: v },
                });
              }}
            />
            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="Email"
              autoComplete="email"
              placeholder=""
              maxLength={85}
              validateOnLoad={false}
              validateOnFocusOut
              description='Recommended'
              aria-label="Recommended"
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, email: v },
                });
              }}
            />
            {/* <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="Lead Traveler Name"
              placeholder=""
              validateOnLoad={false}
              validateOnFocusOut
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, leadTraveler: v },
                });
              }}
            /> */}
            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="Traveler Mobile Number for Airline"
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, airlinePhone: v },
                });
              }}
            />
            <Checkbox
              className="payment-info-field"
              onRenderLabel={() => (
                <Label className="payment-info-field">
                  Traveler declined to provide.
                </Label>
              )}
              onChange={(e, v) => {
                this.setState({ ...this.state, acceptedPhone: v ?? false });
              }}
            />
            <TextField
              className="payment-info-field"
              theme={paymentPanelTheme}
              label="Special Requests"
              autoAdjustHeight
              rows={4}
              multiline
              placeholder=""
              onChange={(e, v) => {
                this.setState({
                  ...this.state,
                  newFop: { ...this.state.newFop, specialRequests: v },
                });
              }}
            />
            {/* Terms and conditions removed 5/19 */}
            {/* <Label className="payment-info-field">Terms and Conditions must be viewed before checkbox is clickable.</Label> */}
            <Checkbox
              className="payment-info-field payment-info-light"
              // disabled={!this.state.clickedHyperlink}
              onRenderLabel={() => (
                <Label className="payment-info-field">
                  I have read and agree to the{" "}
                  <Link
                    href="https://globalfirsttravel.com/terms-conditions-cancellation-refund-policy/"
                    target="_blank"
                    onClick={(e)=>this.setState({...this.state,clickedHyperlink:true})}
                  >
                    Terms and Conditions
                  </Link>
                </Label>
              )}
              onChange={(e, v) => {
                this.setState({ ...this.state, acceptedTerms: v ?? false });
              }}
            />
            
          </Panel>
          </Stack>
          </div>
          

          
        </>
      );
    }
  }

  private selectAll = (): void => {
    if (
      this.state.selectedTravelers.length !=
      this.state.crmData?.associatedPNRs[0].info?.clients.filter((x) => !x.associatedPaymentId)
        .length
    ) {
      this.setState({
        ...this.state,
        selectedTravelers:
          this.state.crmData?.associatedPNRs[0].info?.clients
            .filter((x) => !x.associatedPaymentId)
            .map((x) => x.id) ?? [],
      });
    } else {
      this.setState({ ...this.state, selectedTravelers: [] });
    }
  };
  private selectTraveler = (id: string): void => {
    var newState = { ...this.state };

    if (newState.selectedTravelers?.find((x) => x == id)) {
      newState.selectedTravelers = newState.selectedTravelers.filter(
        (x) => x != id
      );
    } else {
      newState.selectedTravelers.push(id);
    }
    this.setState(newState);
  };
  private setSelectedTravelers = (selection: string[]): void => {
    this.setState({ ...this.state, selectedTravelers: selection });
  };
  private addNewPayment = (): void => {
    this.setState({
      ...this.state,
      createFOPEnabled: true,
      newFop: {
        cardType: undefined,
        cardNo: "",
        expiration: new Date(),
        cardCode: "",
        holder: "",
        addressLine1: "",
        // addressLine2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: "",
      },
    });
  };
  private addFOP = (): void => {
    this.setState({
      ...this.state,
      createFOPEnabled: true,
      newFop: {
        cardType: undefined,
        cardNo: "",
        expiration: new Date(),
        cardCode: "",
        holder: "",
        addressLine1: "",
        // addressLine2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: "",
      },
    });
  };
  private discardNewPayment = (): void => {
    this.setState({
      ...this.state,
      newFop: undefined,
      createFOPEnabled: false,
    });
  };
  private saveFOP = (): void => {
    var validCard = CcCheksum(this.state.newFop?.cardNo??"");
    if (
      !validCard||
      !this.state.newFop?.cardCode ||
      !this.state.newFop?.holder ||
      !this.state.newFop?.phone
    ) {
      this.setState({
        ...this.state,
        showNameError: true,
        showNumberError: true,
        showExpError: true,
        showCVVError: true,
        showPhoneError: true,
        showLeadTraveler: true,
      });
      return;
    }
    var newState = { ...this.state };
    var newPayments = this.state.newPayments.payments;
    var newFop = this.state.newFop;
    
    // var expMonth = newState.tempDate?.split("/")[0];
    var expYear =
      newState.tempDate?.split("/")[1] ?? new Date().getFullYear().toString();
    if (expYear?.length < 4) {
      var thisYear = new Date().getFullYear().toString().substring(0, 2);
      expYear = thisYear + expYear;
    }
    var expiration = new Date(
      parseInt(expYear),
      parseInt(newState.tempDate?.split("/")[0] ?? "12"),
      0
    );
    
    newFop.expiration = expiration;
    var parentPayment: PortalModels.PortalPayment = {pnrId:"",payments:[{
      id: `${newPayments.length}`,
      fopName: "",
      fopId: "",
      newInfo: newFop,
      associatedClients: this.state.selectedTravelers,
    }]};
    if (this.state.newFop) {
      var encryptFop = this.paymentService.immediateEncrypt(
        newFop,
        parentPayment.payments[0],
        newPayments.length
      );
      
      newPayments.push(parentPayment);
      var appendPayment = this.state.newPayments;
      //if (this.state.crmData) appendPayment.pnrId = this.state.crmData?.associatedPNRs[0].pnrId;
      appendPayment.payments = newPayments;
      newState.newPayments = appendPayment;
      newState.createFOPEnabled = false;
      // newState.crmData?.associatedPNRs
      //   .filter((x) => newState.selectedTravelers.find((y) => y == x.id))
      //   .forEach((x) => (x.associatedPaymentId = parentPayment.payments[0].id));
      newState.selectedTravelers = [];
      this.setState(newState);
      this.submitInfo();
    }
  };

  
  private renderFooter = (): JSX.Element => {
    return (
      <>
      <div
        className="ms-Grid"
        dir="rtl"
        style={{
          marginTop: "1vh",
          marginBottom: "1vh",
          borderTop: "1px solid rgb(210, 210, 210)",
          paddingTop: "5px",
        }}
      >
        <DefaultButton
          className="ms-Grid-col ms-sm1"
          style={{ marginLeft: ".25vw" }}
          text="Cancel"
          onClick={this.discardNewPayment}
        />
        <PrimaryButton
          className="ms-Grid-col ms-sm3"
          style={{ marginRight: ".25vw" }}
          text="Submit Payment"
          disabled={!this.state.acceptedTerms || (this.state.newFop?.cardType == PortalEnums.eCardChoice.NS)|| !CcCheksum(this.state.newFop?.cardNo)|| this.getCvvError(this.state.newFop?.cardCode??"")!="" || 
            (this.state.tempDate??"")=="" || (this.state.newFop?.holder??"")=="" || (this.state.newFop?.phone??"")==""|| (this.state.newFop?.addressLine1??"")==""|| (this.state.newFop?.city??"")==""|| (this.state.newFop?.zip??"")=="" ||(this.state.showExpError)||(this.state.tempDate=="")}
          onClick={this.saveFOP}
        />
      </div>
      <Stack style={{paddingLeft:15 }}tokens={{childrenGap:2}} >
      <br/>
      
      <StackItem style={{color:'red', padding:1, fontSize: 15}} hidden ={this.state.newFop?.holder!=""}>
        Please check Cardholder Name field. 
      </StackItem>
      <StackItem style={{color:'red', padding:1, fontSize: 15}} hidden ={(this.state.newFop?.cardNo!="") && (this.state.newFop?.cardType!= PortalEnums.eCardChoice.NS)}>
        Please check Card Number field. 
      </StackItem>
      <StackItem style={{color:'red', padding:1, fontSize: 15}} hidden ={((!this.state.showExpError)&&(this.state.tempDate!=""))&& (this.ccExpError.toString()!=("Field cannot be blank."||"Please enter a valid date."))}>
        Please check Expiration Date field. 
      </StackItem>
      <StackItem style={{color:'red', padding:1, fontSize: 15}} hidden ={this.state.newFop?.cardCode!=""}>
        Please check CVV field. 
      </StackItem>
      <StackItem style={{color:'red', padding:1, fontSize: 15}} hidden ={this.state.newFop?.addressLine1!="" }>
        Please check Address field. 
      </StackItem>
      <StackItem style={{color:'red', padding:1, fontSize: 15}} hidden ={this.state.newFop?.city!="" }>
        Please check City field. 
      </StackItem>
      <StackItem style={{color:'red', padding:1, fontSize: 15}} hidden ={this.state.newFop?.state!="" }>
        Please check State field. 
      </StackItem>
      <StackItem style={{color:'red', padding:1, fontSize: 15}} hidden ={this.state.newFop?.zip!="" }>
        Please check Zip Code field. 
      </StackItem>
      <StackItem style={{color:'red', padding:1, fontSize: 15}} hidden ={this.state.newFop?.phone!=""}>
        Please check Phone field. 
      </StackItem>
      <StackItem style={{color:'red', padding:1, fontSize: 15}} hidden={this.state.acceptedTerms}>
        Please accept Terms and Conditions.  
      </StackItem>
      <br/>
       </Stack>  
      </>
    );
  };
  private submitInfo = (): void => {
    var toSubmit = this.paymentService.encryptCasePayment(this.state.newPayments);
    console.log(toSubmit);
    setTimeout(()=>{this.setState({
      ...this.state,
      submittingInfo: false,
      createFOPEnabled:false,
      
    });},5000);
    this.setState({
      ...this.state,
      submittingInfo: true
      
    });
    this.crmService.addCasePayment(toSubmit).then(
      (result) => {
        var newState = { ...this.state, hideDialog:false };
        newState.crmData = result.data as PortalModels.CasePaymentInfo;
        newState.newPayments = { caseId: newState.crmData.caseId, agentId: newState.crmData.agentId, agencyId: newState.crmData.agencyId, payments: [] };
        newState.loading = false;
        
        this.setState(newState);
      },
      (error) => {
        var newState = {
          ...this.state,
          errorState: true,
          loading: false,
          errorMessage:
            "Invalid Payment URL. You are being routed to the Global First Travel Home Page",
        };
        this.setState(newState);
      }
    );
    //   if(this.state.crmData?.info.clients.filter(x=>!x.associatedPaymentId).length == 0)
    //     this.setState({...this.state,errorState:true,errorMessage:"All payments have been recieved. You will be redirected to the Global First Travel Home Page"})
  };
}
