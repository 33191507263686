import React from "react";
import { useState } from "react";

//import OutboundDisplay from "../Components/UserInt/outbound";

import {
  addDays,
  addMonths,
  Checkbox,
  ComboBox,
  createTheme,
  DatePicker,
  FontWeights,
  IComboBoxOption,
  IComboBoxStyles,
  Icon,
  IDatePicker,
  IDatePickerStyles,
  ILabelStyles,
  IStackStyles,
  IStackTokens,
  IStyleSet,
  Label,
  Pivot,
  PivotItem,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  ThemeProvider,
} from "@fluentui/react";
import { eAirSearchStatus, TravelerTypes, TripType } from "../../Contexts/Enums";
import axios, { AxiosResponse } from "axios";
import * as PortalModels from "../../Contexts/Models";
import CrmService from "../../Contexts/CrmService";
import { OutboundDisplay } from "./ResultPage";
import OneWaySearch from "./OneWayShop";
import { MultiTripSearch } from "./MultiShop";
import { RoundWaySearch } from "./RoundShop";
// import SearchResult from "./Search";

//http://localhost:3000/air/Y2FzZWlkPW54U045SjZUQTE5VmllcEFOWG55cXhFVzVTQU9QaUh3WiswR1dkbDN0QUZvUlR6Tllva0tkTVgyNU1RdmNNbHImY3VzdG9tZXI9N1VoYVlZL2FuTFdoYjB3VW5kMTdEU0F3TTZOVWc3b3A3ZW9FOE1zdG5WVjFHNEttWVZDeDZ2Yk5IQ1FLUi9IRCZ0b2tlbj1FS2lNMHlLSVlkQjBpU1lTU2tXWmdRQTVVbDZoOXF1clhuYzIzbU5nRFhrPQ==

//styles
const airTravelTheme = createTheme({
  fonts: {
    small: {
      fontSize: "10px",
      fontWeight: "semibold",
    },
    medium: {
      fontSize: "17px",
      fontWeight: "semibold",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "25px",
      fontWeight: "semibold",
    },
  },

  palette: {
    themePrimary: "#b39e51",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#b39e51",
    themeDark: "#918e2a",
    themeDarker: "#004578",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#b39e51",
    neutralPrimaryAlt: "#918e2a",
    neutralPrimary: "#eff6fc",
    neutralDark: "#eff6fc",
    black: "#000000",
    white: "#282828",
  },
});

const labelStyles: Partial<ILabelStyles> = {
  root: { marginTop: 10 },
};
const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };

const horizontalGapStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

//interfaces or enums

export interface INewTravelRequestProps {
  onSearchRequest: void;
}

export interface IAirShopState
  extends React.ComponentState,
    IAirShopComponentProps {
  crmData?: PortalModels.GetAirFromTokenResponse;
  errorState: boolean;
  loading: boolean;
  responeData?: PortalModels.GetAirFromTokenResponse;
  submittingInfo: boolean;
  aiportLocations: IComboBoxOption[];
  oneWaySearchState: boolean;
  roundWaysearchState: boolean;
  multiTripSearchState: boolean;
  searchStatus: eAirSearchStatus;
  tripInfo?: PortalModels.ShoppingRequest;
}

export interface IAirShopComponentProps {
  token: string;
}

export enum PreferenceLevel {
  None = 0,
  Preferred = 1,
  Only = 2,
}
let endpoints=[
  "https://prod-187.westus.logic.azure.com:443/workflows/b5321fcae5df484184b21fc52a686ccd/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=UP0ecFDKyeqt1BpPv_msaJd632dRdNqdV7Ve3FDyNAc"
   ,
   "https://prod-190.westus.logic.azure.com:443/workflows/a6a60b42c4164cb892117afccc6cb843/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=oi86GsVstDozeJT3ivgjyJZIu1V-OYkbtx17-VrdC2Q"           
]

export class TravelPortal extends React.Component<
  IAirShopComponentProps,
  IAirShopState
> {
  private crmService: CrmService;
  constructor(props: IAirShopComponentProps) {
    super(props);
    this.state = {
      ...props,
      crmAdminError: false,
      loading: true,
      submittingInfo: false,
      searching: false,
      errorState: false,
      results: false,
      searchStatus: eAirSearchStatus.NotSearching,
      isToggleOn: false,
      oneWaySearchState: false,
      roundWaysearchState: false,
      roundTripData: null,
      multiTripSearchState: false,
      receivedItinerary: null,
      airlineListPacks:[],
      aiportLocations: [],
      frequentCarrierOptions:[],
    };

    this.crmService = new CrmService();
    this.crmService.getShoppingInfofromToken(props.token).then(
      (result) => {
        var newState = { ...this.state };
        newState.crmData = result.data as PortalModels.GetAirFromTokenResponse;
        newState.responseData = {
          ...(result.data as PortalModels.GetAirFromTokenResponse),
        };

        if (
          !newState.crmData ||
          !newState.responseData ||
          newState.responseData.agentId == "a911f5a67638ed119db1000d3a34e023"
        ) {
          this.setState({ ...newState, crmAdminError: true });
        } else {
          this.setState(newState);
        }
        this.crmService.getAirlineInfo("").then((r) =>
          this.setState({
            ...this.state,
            loading: false,
            aiportLocations: r.data as IComboBoxOption[],
          })
        );
        
        
          Promise.all([
            axios.get("https://prod-187.westus.logic.azure.com:443/workflows/b5321fcae5df484184b21fc52a686ccd/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=UP0ecFDKyeqt1BpPv_msaJd632dRdNqdV7Ve3FDyNAc").then((t)=> this.setState({...this.state, aiportLocations: t.data as IComboBoxOption[]})),
            axios.get("https://prod-50.westus.logic.azure.com:443/workflows/8379d7f25757455abb744f1131fc5005/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=cbJzlb0csDdtlhtIrLi0MGfxz-ulp6Qd4VSjEp8TjM4").then((p) => this.setState({...this.state, frequentCarrierOptions: p.data.frequentCarrier as PortalModels.FrequentCarrier[] }))
          ])

        // axios.get(
        //   "https://prod-187.westus.logic.azure.com:443/workflows/b5321fcae5df484184b21fc52a686ccd/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=UP0ecFDKyeqt1BpPv_msaJd632dRdNqdV7Ve3FDyNAc"
          //Airline info
          //)
        //axios.get(
          //"https://prod-50.westus.logic.azure.com:443/workflows/8379d7f25757455abb744f1131fc5005/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=cbJzlb0csDdtlhtIrLi0MGfxz-ulp6Qd4VSjEp8TjM4"
          //get airline info
         // )
        .then(() => 
          this.setState({
            ...this.state,
            //aiportLocations: res[1].data as IComboBoxOption[],
            //frequentCarrierOptions: r.data.frequentCarrier as PortalModels.FrequentCarrier[],
            loading: false,
            
          })
        )
          
      },
      (error) => {
        var newState = {
          ...this.state,
          errorState: true,
          loading: false,
          errorMessage:
            "Invalid Traveler URL. You are being routed to the Global First Travel Home Page",
        };
        this.setState(newState);
    }

    );
  }

  private updateParent = (): any => {
    if (this.state.roundWaysearchState == true) {
      console.log("roundWay Parent update triggered");
      this.setState({
        ...this.state,
        eAirSearchStatus: eAirSearchStatus.Searching,
      });
    } else if (this.state.oneWaySearchState == true) {
      console.log("oneWay Parent update triggered");
      this.setState({
        ...this.state,
        eAirSearchStatus: eAirSearchStatus.Searching,
      });
    } else if (this.state.multiTripSearchState == true) {
      console.log("multiWay Parent update triggered");
      this.setState({
        ...this.state,
        eAirSearchStatus: eAirSearchStatus.Searching,
      });
    } else if (
      this.state.roundWaysearchState == false &&
      this.state.oneWaySearchState == false &&
      this.state.multiTripSearchState == false
    ) {
      console.log("updateParent triggered, but state is false");
    } else {
      return console.log("I ain't found shit");
    }
  };
  // private handleItineraryChange = (
  //   property: keyof PortalModels.ItineraryRequest,
  //   value: any
  // ) => {
  //   this.setState((prevItinerary) => ({
  //     ...prevItinerary,
  //     [property]: value,
  //   }));
  // };
  

  toggleState = () => {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
      roundWaysearchState: !prevState.roundWaysearchState,
    }));
  };

 
  // handleOnewayData = (data: PortalModels.ItineraryRequest) => {
  //   this.setState({...this.state.tripInfo, itin: data });
  //   this.setState({...this.state.tripInfo,tripType:1})
  // };
  // handleTravelerCountChange = (travelerCounts: PortalModels.TravelerTypes) => {
  //   console.log(travelerCounts)
  //   // this.setState((prevCounts)=>({
  //   //   ...prevCounts,
  //   //   [this.state.travelerType]: (prevCounts[this.state.travelerType] ?? 0) + countChange,
  //   // }));
  //   this.setState({ ...this.state,tripInfo:{...this.state.tripInfo,travelerCounts: travelerCounts}
  //   });
  // };
  // handleUpdateItinerary = (updatedShoppingRequest: PortalModels.ItineraryRequest):void => {
  //  this.setState({ ...this.state,tripInfo:{...this.state.tripInfo, itin: updatedShoppingRequest}
  //   });
  // console.log(updatedShoppingRequest)
  // };


  //callback to update the state in parent 
  handleTripInfoChange  = (tripInfo: PortalModels.ShoppingRequest) => {
      this.setState({ ...this.state, 
      tripInfo:{
        ...this.state.tripInfo,
        tripType:tripInfo.tripType??this.state.tripInfo?.tripType,
        travelers: tripInfo.travelers??this.state.tripInfo?.travelers,
        itin:tripInfo.itin??this.state.tripInfo?.itin,
        preferredCarriers:null,
      }});
  };

  

  handleSubmit = async (event: any) => {
    
    console.log("search Submitted (handleSubmit.airshop)");
    console.log(this.state.tripInfo);
    console.log(this.state.tripInfo?.travelers)

    try {
      const { onewayData, roundtripData, multiselectData } = this.state;
      this.setState({...this.state, searching:true})
      const response = await axios.post(
        "https://prod-190.westus.logic.azure.com:443/workflows/a6a60b42c4164cb892117afccc6cb843/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=oi86GsVstDozeJT3ivgjyJZIu1V-OYkbtx17-VrdC2Q",
        this.state.tripInfo
      );
      this.setState({...this.state, receivedItinerary:response.data})
      this.setState({ responseData: response.data});
      this.setState({...this.state, searching:false, results: true})
      
    } catch (error) {
      console.error("Axios crit fail:", error);
      this.setState({...this.state,
        searching:false,
        errorState: true       
      })
    }
  };

 

  handleOneWayData = (data: any) => {
    this.setState({ oneWayData: data });
  };

  handleRoundWayData = (data: any) => {
    this.setState({ roundWayData: data });
  };

  handleMultiData = (data: any) => {
    this.setState({ multiData: data });
  };

  // Lifecycle method to clear response data when onwayData, roundWayData, or multiData changes
  // componentDidUpdate(
  //   prevProps: Readonly<IAirShopComponentProps>,
  //   prevState: Readonly<IAirShopState>,
  //   snapshot?: IAirSearchProps | undefined
  // ): void {
  //   const { oneWayData, roundWayData, multiData } = this.state;
  //   const { responseData } = prevState;

  //   if (oneWayData !== prevState.oneWayData && responseData) {
  //     this.setState({ responseData: null });
  //   } else if (roundWayData !== prevState.roundWayData && responseData) {
  //     this.setState({ responseData: null });
  //   } else if (multiData !== prevState.multiData && responseData) {
  //     this.setState({ responseData: null });
  //   }
  // }

  // Callback functions to update the state from child components
  

  public render(): JSX.Element {
    const { travelerCounts } = this.state;
    const { receivedItinerary } = this.state;
    const { responseData } = this.state;
    
    if (this.state.loading) {
      return <Spinner size={SpinnerSize.large} label="Retrieving Info..." />;
    } else if (this.state.errorState) {
      return (
        <>
          <Label>There was an error. Please try again. </Label>
        </>
      );
    } else if (
      this.state.searching
    ) {

      return (
        <>
          <Spinner size={SpinnerSize.large} label="Searching..." />
        </>
      );
    } else if (this.state.NoResults) {
      return (
        <>
          <Spinner size={SpinnerSize.large} label="Sorry! Nothing found." />
        </>
      );
    } else if (this.state.results) {
      return (
        <>
          <Label>"Behold! Stuff!"</Label>
          <OutboundDisplay 
          responseData={receivedItinerary} 
          frequentGuestOptions={this.state.frequentCarrierOptions}
          />
        </>
      );
    } else {
      return (
        <>
          <ThemeProvider theme={airTravelTheme}>
            <form onSubmit={console.log}>
              <Pivot aria-label="Basic Pivot Example">
                 <PivotItem
                  headerText="Round-Trip"
                  headerButtonProps={{
                    "data-order": 1,
                    "data-title": "Round-Trip",
                  }}
                >
                  {/* Round Trip */}
                  <div
                    style={{
                      borderStyle: "solid",
                      borderColor: "#baa65e",
                      borderWidth: 1,
                      padding: 20,
                      margin: 10,
                    }}
                  >
                    <Label styles={labelStyles}>Round Trip</Label>
                    
                    <RoundWaySearch //child comp
                      airportLocations={this.state.aiportLocations}
                      onTripInfoChange={this.handleTripInfoChange} 
                      travelers={this.state.tripInfo?.travelers||travelerCounts} 
                      tripType={this.state.tripInfo?.tripType||0}                  
                      onSubmitSearch={this.handleSubmit}
                    />
                  </div>
                </PivotItem> 

                <PivotItem
                  headerText="One-Way"
                  headerButtonProps={{
                    "data-order": 1,
                    "data-title": "One-Way",
                  }}
                >
                  {/* One-Way */}
                  <div
                    style={{
                      borderStyle: "solid",
                      borderColor: "#baa65e",
                      borderWidth: 1,
                      padding: 20,
                      margin: 10,
                    }}
                  >
                    <Label styles={labelStyles}>One-Way Trip</Label>
                    {/* <PassengerSelector
                      onCountChange={this.handleTravelerCountChange}
                    /> */}
                    <OneWaySearch
                      airportLocations={this.state.aiportLocations}
                      onTripInfoChange={this.handleTripInfoChange} 
                      travelers={this.state.tripInfo?.travelers||travelerCounts} 
                      tripType={this.state.tripInfo?.tripType||0}                  
                      onSubmitSearch={this.handleSubmit}
                    />
                  </div>
                </PivotItem>
                {/* <PivotItem
                  headerText="Multi-Stop"
                  headerButtonProps={{
                    "data-order": 1,
                    "data-title": "Multi-Stop",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      borderColor: "#baa65e",
                      borderWidth: 1,
                      padding: 20,
                      margin: 10,
                    }}
                  >
                    <Label styles={labelStyles}>Multi-Stop Trip</Label>

                    <Stack horizontalAlign="center">
                      <StackItem style={{ padding: 20 }}>
                        {/* Multi Stop 
                      </StackItem>
                    </Stack>
                    
                    <MultiTripSearch
                      airportLocations={this.state.aiportLocations}
                    />
                  </div>
                </PivotItem> */}
              </Pivot>
            </form>
          </ThemeProvider>
        </>
      );
    }
  }
}
