import React from "react";
import {
  createTheme,
  Stack,
  IStackTokens,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Selection,
  TooltipHost,
  IComboBoxOption,
  MarqueeSelection,
  DetailsList,
  DetailsListLayoutMode,
  CheckboxVisibility,
  SelectionMode,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
  Separator,
  ThemeProvider,
  FontSizes,
  DefaultButton,
  noWrap,
  DialogType,
  Dialog,
  DialogFooter,
} from "@fluentui/react";
import * as PortalModels from "../Contexts/Models";
import CrmService from "../Contexts/CrmService";
import "../App.scss";
import { InvalidPathComponent } from "./InvalidPathComponent";
import { TravelerPanel } from "./Panel/Panel";
import { SearchPanel } from "./Panel/SearchPanel";
import { FrequentFlyPanel } from "./Panel/FrequentPanel";
import axios from "axios";
import { ConfirmationModal } from "./ConfirmationModal";
import { TravelerHelp } from "./HelpTraveler";
import { Idler } from "./IdleTimer";

//http://localhost:3000/travelers/Y2FzZWlkPW54U045SjZUQTE5VmllcEFOWG55cXhFVzVTQU9QaUh3WiswR1dkbDN0QUZvUlR6Tllva0tkTVgyNU1RdmNNbHImY3VzdG9tZXI9N1VoYVlZL2FuTFdoYjB3VW5kMTdEU0F3TTZOVWc3b3A3ZW9FOE1zdG5WVjFHNEttWVZDeDZ2Yk5IQ1FLUi9IRCZ0b2tlbj1FS2lNMHlLSVlkQjBpU1lTU2tXWmdRQTVVbDZoOXF1clhuYzIzbU5nRFhrPQ==

const ProfleTheme = createTheme({
  fonts: {
    small: {
      fontSize: "11px",
    },
    medium: {
      fontSize: "13px",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "22px",
      fontWeight: "semibold",
    },
  },
  palette: {
    themePrimary: "#ffffff",
    themeLighterAlt: "#070603",
    themeLighter: "#1d190d",
    themeLight: "#362f18",
    themeTertiary: "#6b5e30",
    themeSecondary: "#9d8a47",
    themeDarkAlt: "#baa65e",
    themeDark: "#c5b373",
    themeDarker: "#d4c693",
    neutralLighterAlt: "#282828",
    neutralLighter: "#272727",
    neutralLight: "#252525",
    neutralQuaternaryAlt: "#232323",
    neutralQuaternary: "#605E5C",
    neutralTertiaryAlt: "#b39e51",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "lightgray",
    black: "#f8f8f8",
    white: "#282828",
  },
});
const SubTheme = createTheme({
  fonts: {
    small: {
      fontSize: "11px",
    },
    medium: {
      fontSize: "13px",
    },
    large: {
      fontSize: "20px",
      fontWeight: "semibold",
    },
    xLarge: {
      fontSize: "22px",
      fontWeight: "semibold",
    },
  },
  palette: {
    themePrimary: "#9d8a47",
    themeLighterAlt: "#070603",
    themeLighter: "#1d190d",
    themeLight: "#362f18",
    themeTertiary: "#6b5e30",
    themeSecondary: "#9d8a47",
    themeDarkAlt: "#baa65e",
    themeDark: "#c5b373",
    themeDarker: "#d4c693",
    neutralLighterAlt: "#282828",
    neutralLighter: "#272727",
    neutralLight: "#252525",
    neutralQuaternaryAlt: "#232323",
    neutralQuaternary: "#605E5C",
    neutralTertiaryAlt: "#b39e51",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "lightgray",
    black: "#f8f8f8",
    white: "#282828",
  },
});

export interface ITravelerProfilesComponentProps {
  token: string;
}

export interface ISearchPanelProps {
  token: string;
}

export interface IFlyerPanelProps {
  token: string;
}

const stackTokens: IStackTokens = { childrenGap: 15 };

export interface ITravelerProfilesComponentState
  extends React.ComponentState,
    ITravelerProfilesComponentProps,
    ISearchPanelProps,
    IFlyerPanelProps {
  crmData?: PortalModels.GetCaseFromTokenResponse;
  errorState: boolean;
  loading: boolean;
  errorMessage?: string;
  crmAdminError: boolean;
  responseData?: PortalModels.GetCaseFromTokenResponse;
  travelerPanel: TravelerPanelState;
  searchPanel: SearchPanelState;
  frequentFlyPanel: FrequentFlyPanelState;
  submittingInfo: boolean;
  selectedTraveler: PortalModels.TravelerProfiles[];
  frequentCarrierOptions: IComboBoxOption[];
  mealCode: IComboBoxOption[];
  wheelchair: IComboBoxOption[];
  submitClicked: boolean;
}
export interface TravelerPanelState {
  isOpen: boolean;
  openAsAdd: boolean;
  travelerAdded: boolean;
  autoTimerStart: boolean;
}

export interface SearchPanelState {
  isSearchOpen: boolean;
  openSearchAsAdd: boolean;
  travelerAdded2: boolean;
  autoTimerStart2: boolean;
}

export interface FrequentFlyPanelState {
  isFlyerOpen: boolean;
  openFlyerAsAdd: boolean;
}

const calloutProps = { gapSpace: 0 };

let submitClicked = false;

window.addEventListener("beforeunload", function (e) {
  if (!submitClicked) {
    e.preventDefault();
    //e.returnValue= '';
  }
});

//loadTheme(paymentPanelTheme);
export class TravelerProfilesComponent extends React.Component<
  ITravelerProfilesComponentProps,
  ITravelerProfilesComponentState,
  ISearchPanelProps
> {
  private crmService: CrmService;
  private _selection: Selection;

  constructor(props: ITravelerProfilesComponentProps) {
    super(props);
    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          ...this.state,
          selectedTraveler: this._getSelectionDetails(),
        });
      },
    });
    this.state = {
      ...props,
      loading: true,
      displayFieldErorrs: false,
      errorState: false,
      submittingInfo: false,
      showConfirmSuccessModal: false,
      hideDialog: true,
      crmAdminError: false,
      travelerPanel: {
        isOpen: false,
        openAsAdd: false,
        travelerAdded: false,
        autoTimerStart: false,
      },
      searchPanel: {
        isSearchOpen: false,
        openSearchAsAdd: false,
        travelerAdded2: false,
        autoTimerStart2: false,
      },
      frequentFlyPanel: {
        isFlyerOpen: false,
        openFlyerAsAdd: false,
      },
      confirmationModal: {
        isModalOpen: false,
      },
      frequentCarrierOptions: [],
      selectedTraveler: [],
      mealCode: [],
      wheelchair: [],
      submitClicked: false,
    };

    this.crmService = new CrmService();
    this.crmService.getCaseInfo(props.token).then(
      (result) => {
        var newState = { ...this.state };
        newState.crmData = result.data as PortalModels.GetCaseFromTokenResponse;
        //newState.loading = false;
        newState.responseData = {
          ...(result.data as PortalModels.GetCaseFromTokenResponse),
        };
        newState.responseData.a = "";
        newState.responseData.b = "";
        newState.responseData.c = "";
        if (
          !newState.crmData ||
          !newState.responseData ||
          newState.responseData.agentId == "a911f5a67638ed119db1000d3a34e023"
        ) {
          this.setState({ ...newState, crmAdminError: true });
        } else {
          this.setState(newState);
        }
        axios
          .get(
            "https://prod-50.westus.logic.azure.com:443/workflows/8379d7f25757455abb744f1131fc5005/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=cbJzlb0csDdtlhtIrLi0MGfxz-ulp6Qd4VSjEp8TjM4"
          )
          .then((r) =>
            this.setState({
              ...this.state,
              loading: false,
              frequentCarrierOptions: r.data
                .frequentCarrier as IComboBoxOption[],
              mealCodeOptions: r.data.meal as IComboBoxOption[],
              wheelchairOptions: r.data.wheelchair as IComboBoxOption[],
              // otherAssistance: r.data.assist as IComboBoxOption[]
            })
          );
      },
      (error) => {
        var newState = {
          ...this.state,
          errorState: true,
          loading: false,
          errorMessage:
            "Invalid Traveler URL. You are being routed to the Global First Travel Home Page",
        };
        this.setState(newState);
      }
    );
  }

  public render(): JSX.Element {
    if (this.state.loading) {
      return <Spinner size={SpinnerSize.large} label="Retrieving Info..." />;
    } else if (this.state.errorState) {
      return (
        <>
          <InvalidPathComponent
            displayText={
              this.state.errorMessage ??
              "An error has occurred. You are being routed to the home page. Please contact your Travel representative."
            }
            waitTime={5000}
          />
        </>
      );
    } else if (this.state.crmAdminError) {
      return (
        <>
          <InvalidPathComponent
            displayText={
              this.state.errorMessage ??
              "You are not authorized to view this page. Please contact your Travel representative regarding case: " +
                this.state.crmData?.ticketNumber
            }
            waitTime={30000}
          />
        </>
      );
    } else if (this.state.submittingInfo) {
      return (
        <>
          <Spinner size={SpinnerSize.large} label="Submitting Profiles..." />
        </>
      );
    } else {
      return (
        <>
          <ThemeProvider theme={ProfleTheme}>
            <Stack
              horizontal
              tokens={stackTokens}
              horizontalAlign={"center"}
              style={{
                textSizeAdjust: "auto",
                wordWrap: "normal",
                whiteSpace: "nowrap",
              }}
            >
              <PrimaryButton
                theme={ProfleTheme}
                text="Add New Traveler"
                //style={{flex:1}}
                disabled={this.state.selectedTraveler.length != 0}
                // className= "ms-Grid-col ms-lg3"
                iconProps={{ iconName: "AddFriend" }}
                onClick={(e) =>
                  this.setState({
                    ...this.state,
                    travelerPanel: {
                      isOpen: true,
                      openAsAdd: true,
                      travelerAdded: true,
                      autoTimerStart: false,
                    },
                  })
                }
              />
              <PrimaryButton
                theme={ProfleTheme}
                text="Find Traveler Profile"
                disabled={this.state.selectedTraveler.length != 0}
                // className= "ms-Grid-col ms-lg3"
                iconProps={{ iconName: "ProfileSearch" }}
                //style={{flex:1}}
                onClick={(e) =>
                  this.setState({
                    ...this.state,
                    searchPanel: {
                      isSearchOpen: true,
                      openSearchAsAdd: true,
                      travelerAdded2: false,
                      autoTimerStart2: false,
                    },
                  })
                }
              />
            </Stack>

            {/* <ProfileTable
            travelers={this.state.responseData?.travelers}
            currentSelection={this.state.selectedTraveler}
            setSelectedTraveler={(p: PortalModels.TravelerProfiles[]) => {
              console.log(p);
              this.setState({ ...this.state, selectedTraveler: p });
            }}
          /> */}
            <Stack tokens={stackTokens} horizontalAlign={"center"}>
              <br />
              {this.getTravelerTable()}
            </Stack>
            <Separator />
            <Stack
              horizontal
              tokens={stackTokens}
              horizontalAlign={"center"}
              style={{
                textSizeAdjust: "auto",
                wordWrap: "normal",
                whiteSpace: "nowrap",
              }}
            >
              <TooltipHost
                content="Only available for new entries"
                calloutProps={calloutProps}
              >
                <PrimaryButton
                  text="Remove Traveler"
                  theme={ProfleTheme}
                  disabled={
                    this.state.selectedTraveler.length === 0 ||
                    this.state.crmData?.travelers.filter(
                      (x) => this.state.selectedTraveler[0]?.id === x.id
                    ).length != 0
                  }
                  aria-label="Only available for new entries"
                  //className= "ms-Grid-col ms-sm2 ms-md2 ms-lg3"
                  iconProps={{ iconName: "BlockContact" }}
                  onClick={(e) => {
                    this.removeTraveler();
                    this.setState({ ...this.state, selectedTraveler: [] });
                  }}
                />
              </TooltipHost>
              <PrimaryButton
                text="Edit Traveler"
                theme={ProfleTheme}
                disabled={this.state.selectedTraveler.length == 0}
                iconProps={{ iconName: "EditContact" }}
                //className= "ms-Grid-col ms-lg3"
                onClick={(e) =>
                  this.setState({
                    ...this.state,
                    travelerPanel: {
                      isOpen: true,
                      openAsAdd: true,
                      travelerAdded: true,
                      autoTimerStart: false,
                    },
                  })
                }
              />
              {/* <PrimaryButton
              text="Add Frequent Flyer"
              theme={ProfleTheme}
              disabled={this.state.selectedTraveler.length == 0}
              iconProps={{ iconName: "Airplane" }}
              //className= "ms-Grid-col ms-lg3"
              onClick={(e) => {
                this.setState({
                  ...this.state,
                  frequentFlyPanel: { isFlyerOpen: true, openFlyerAsAdd: true },
                });
              }}
            /> */}
            </Stack>
            <Separator />

            <ThemeProvider theme={SubTheme}>
              <PrimaryButton
                text="Submit Profiles"
                wrap="false"
                disabled={this.state.selectedTraveler.length != 0}
                //className= "ms-Grid-col ms-lg6"
                iconProps={{ iconName: "AddGroup" }}
                onClick={
                  (e) =>
                    this.setState({
                      ...this.state,
                      confirmationModal: { isModalOpen: true },
                    })
                  // this.submitInfo();
                }
              />
            </ThemeProvider>
          </ThemeProvider>
          <ThemeProvider theme={ProfleTheme}>
            <TravelerPanel
              crmService={this.crmService}
              mealGuestOptions={this.state.mealCodeOptions}
              wheelchairGuestOptions={this.state.wheelchairOptions}
              isOpen={this.state.travelerPanel.isOpen}
              frequentGuestOptions={this.state.frequentCarrierOptions}
              openAsAdd={this.state.travelerPanel.openAsAdd}
              addTravelers={this.addTravelers}
              closePanel={this.closeTravelerPanel}
              autoTimerStart={this.state.autoTimerStart}
              editTraveler={
                this.state.selectedTraveler.length > 0
                  ? this.state.selectedTraveler[0]
                  : undefined
              }
            />
            <SearchPanel
              crmService={this.crmService}
              agentId={this.state.crmData?.agentId ?? ""}
              agencyId={this.state.crmData?.agencyId ?? ""}
              addTravelers={this.addTravelers}
              isSearchOpen={this.state.searchPanel.isSearchOpen}
              openSearchAsAdd={this.state.searchPanel.openSearchAsAdd}
              closeSearchPanel={this.closeSearchPanel}
              autoTimerStart2={this.state.autoTimerStart2}
            />
            {/* <FrequentFlyPanel
            frequentGuestOptions={this.state.frequentCarrierOptions}
            crmService={this.crmService}
            addTravelers={this.addTravelers}
            editTraveler={
              this.state.selectedTraveler.length > 0
                ? this.state.selectedTraveler[0]
                : undefined
            }
            isFlyerOpen={this.state.frequentFlyPanel.isFlyerOpen}
            openFlyerAsAdd={this.state.frequentFlyPanel.openFlyerAsAdd}
            closeFlyerPanel={this.closeFlyerPanel}
          /> */}
            <ConfirmationModal
              profiles={this.state.responseData?.travelers ?? []}
              submitInfo={this.submitInfo}
              isModalOpen={this.state.confirmationModal.isModalOpen}
              hideModal={this.hideModal}
              meals={this.state.mealCodeOptions}
              wheelchair={this.state.wheelchairOptions}
            />

            <Idler
              closePanel={this.closeTravelerPanel}
              closeSearchPanel={this.closeSearchPanel}
              hideModal={this.hideModal}
              submitInfo={this.submitInfo}
              autoTimerStart={this.state.travelerPanel.autoTimerStart}
              autoTimerStart2={this.state.searchPanel.autoTimerStart2}
            />
          </ThemeProvider>
        </>
      );
    }
  }

  private getTravelerTable = (): JSX.Element => {
    var ScreenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    var ScreenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    var screencheck = ScreenWidth <= 600 ? ScreenWidth - 30 : "";
    console.log(
      window.innerWidth,
      document.documentElement.clientWidth,
      document.body.clientWidth
    );
    if (this.state.responseData?.travelers?.length == 0) {
      return (
        <>
          <Label>
            No travelers added. Please either Add New Traveler or Search for a
            previous traveler.{" "}
          </Label>
        </>
      );
    }
    return (
      <>
        <ThemeProvider theme={ProfleTheme}>
          <Stack
            horizontalAlign="center"
            style={{
              textSizeAdjust: "80%",
              wordWrap: "normal",
              whiteSpace: "normal",
            }}
          >
            To submit profiles, please add all new travelers. Once complete,
            click the "Submit Profiles" button to transmit the data.
          </Stack>
        </ThemeProvider>
        <div
          //className="travel"
          style={{ width: screencheck }}
        >
          <MarqueeSelection selection={this._selection}>
            <DetailsList
              items={
                this.state.responseData?.travelers?.map((x, i) => {
                  return {
                    key: x.id ? x.id : x.index,
                    name:
                      x.lastName +
                      "/ " +
                      x.firstName +
                      " " +
                      (x.middleName ?? ""),
                    gender:
                      x.gender == 1
                        ? "M"
                        : x.gender == 2
                        ? "F"
                        : x.gender == 283240002
                        ? "X"
                        : "U",
                    dob: new Date(x.dateOfBirth ?? new Date())
                      .toLocaleDateString()
                      .split("T")[0],
                  };
                }) ?? []
              }
              columns={[
                {
                  key: "column1",
                  name: "Name",
                  fieldName: "name",
                  minWidth: 175,
                  maxWidth: 300,
                  isResizable: true,
                },
                {
                  key: "column2",
                  name: "Gender",
                  fieldName: "gender",
                  minWidth: 50,
                  maxWidth: 50,
                  isResizable: true,
                },
                {
                  key: "column3",
                  name: "Date of Birth",
                  fieldName: "dob",
                  minWidth: 100,
                  maxWidth: 200,
                  isResizable: true,
                },
              ]}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={this._selection}
              selectionPreservedOnEmptyClick={true}
              checkboxVisibility={CheckboxVisibility.always}
              onRenderRow={this.renderRow}
              selectionMode={SelectionMode.single}
            />
            <Dialog
              onDismiss={this.closeDialog}
              hidden={this.state.hideDialog}
              dialogContentProps={this.dialogContentProps}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 450 } },
                dragOptions: undefined,
              }}
            >
              <DialogFooter>
                <Stack horizontalAlign="center">
                  <PrimaryButton onClick={this.closeDialog} text="Ok" />
                </Stack>
              </DialogFooter>
            </Dialog>
          </MarqueeSelection>
        </div>
      </>
    );
  };

  private addTravelers = (profiles: PortalModels.TravelerProfiles[]): void => {
    console.log(`DRIVER Adding: ${JSON.stringify(profiles)}`);
    var newState = { ...this.state, selectedTraveler: [] };
    if (!newState.responseData) {
      console.log("OVERRIDE RESPONSE DATA");
      newState.responseData = {
        travelers: profiles ?? [],
        agencyId: this.state.crmData?.agencyId ?? "",
        agentId: this.state.crmData?.agentId ?? "",
        caseId: this.state.crmData?.caseId ?? "",
        ticketNumber: this.state.crmData?.ticketNumber ?? "",
        a: "",
        b: "",
        c: "",
      };
    } else {
      // profiles = profiles.filter(
      //   (x) => !newState.responseData?.travelers.find((y) => y.id == x.id)
      // );
      var newProfiles: PortalModels.TravelerProfiles[] =
        newState.responseData.travelers; // profiles;
      var y = profiles[0];
      //if (y.dateOfBirth) y.dateOfBirth = Date.parse(y.dateOfBirth);
      let added = false;
      newState.responseData.travelers.forEach((x, i) => {
        if (
          !added &&
          newState.responseData &&
          newState.responseData.travelers &&
          (y.id === x.id ||
            y.index === x.index ||
            (x.firstName == y.firstName &&
              x.lastName == y.lastName &&
              ((this.isDate(x.dateOfBirth) &&
                this.isDate(y.dateOfBirth) &&
                x.dateOfBirth?.toLocaleString("en-US") ==
                  y.dateOfBirth?.toLocaleString("en-US")) ||
                (!this.isDate(x.dateOfBirth) &&
                  this.isDate(y.dateOfBirth) &&
                  x.dateOfBirth ==
                    y.dateOfBirth?.toLocaleString("en-US")) ||
                (this.isDate(x.dateOfBirth) &&
                  !this.isDate(y.dateOfBirth) &&
                  x.dateOfBirth?.toLocaleString("en-US") ==
                    y.dateOfBirth) ||
                (!this.isDate(x.dateOfBirth) &&
                  !this.isDate(y.dateOfBirth) &&
                  x.dateOfBirth == y.dateOfBirth))))
        ) {
          newState.responseData.travelers[i] = y;
          added = true;
        }
      });
      if (!added) {
        y.index = newState.responseData.travelers.length;
        newState.responseData.travelers =
          newState.responseData.travelers.concat(y);
      }
      newState.responseData.travelers.forEach((x) => {
        if (x.telephone?.endsWith("_")) {
          x.telephone = x.telephone.substring(0, x.telephone.length - 1);
        }
      });
      // newProfiles.forEach(x=> {
      //   if(x.telephone?.endsWith('_')){x.telephone = x.telephone.substring(0,x.telephone.length-1);}});
      //   newState.responseData?.travelers.forEach((x,i) => {
      //     if (
      //       !newProfiles.find((y) => {
      //         if (x.id != "") return y.id == x.id;
      //         else if (x.index !== null && x.index !== undefined) return y.index == x.index;
      //         else {
      //           return (
      //             x.firstName == y.firstName &&
      //             x.lastName == y.lastName &&
      //             x.dateOfBirth?.toLocaleDateString("en-US") == y.dateOfBirth?.toLocaleDateString("en-US")
      //           );
      //         }
      //       })
      //   ) {
      //     newProfiles = newProfiles.concat(x);
      //   }
      // });
      // newProfiles.forEach((x,i)=>{
      //   x.index = i;
      // });
      //newState.responseData.travelers = newProfiles;
    }
    console.log(
      `NEW PROFILE COUNT: ${newState.responseData.travelers.length}}`
    );
    this.setState({ ...newState }, () => {
      this._selection.setAllSelected(false);
    });
  };
  private isDate = (item: any): boolean => {
    if (!item) return false;
    return Object.prototype.toString.call(item) === "[object Date]";
  };
  private closeTravelerPanel = (): void => {
    this.setState({
      ...this.state,
      travelerPanel: {
        isOpen: false,
        openAsAdd: false,
        travelerAdded: true,
        autoTimerStart: true,
      },
    });
  };
  private closeSearchPanel = (): void => {
    this.setState({
      ...this.state,
      searchPanel: {
        isSearchOpen: false,
        openSearchAsAdd: false,
        travelerAdded2: true,
        autoTimerStart2: true,
      },
    });
  };
  private closeFlyerPanel = (): void => {
    this.setState({
      ...this.state,
      frequentFlyPanel: { isFlyerOpen: false, openFlyerAsAdd: false },
    });
  };
  private hideModal = (): void => {
    this.setState({
      ...this.state,
      confirmationModal: { isModalOpen: false, hideModal: true },
    });
  };
  private idValid = (id: string): boolean => {
    var check = new RegExp(
      "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$"
    );
    if (check.test(id)) {
      return true;
    }
    return false;
  };

  public dialogContentProps = {
    type: DialogType.normal,
    title: "Success!",
    subText: "Traveler profile(s) successfully submitted.",
  };

  private closeDialog = (): void => {
    this.setState({
      ...this.state,
      hideDialog: true,
    });
  };

  private submitInfo = (): void => {
    if (this.state.responseData) {
      this.setState({
        ...this.state,
        submittingInfo: true,
        submitClicked: true,
        confirmationModal: { isModalOpen: false, hideModal: true },
        travelerPanel: {
          isOpen: false,
          openAsAdd: false,
          travelerAdded: false,
          autoTimerStart: false,
        },
        searchPanel: {
          isSearchOpen: false,
          openSearchAsAdd: false,
          travelerAdded2: false,
          autoTimerStart2: false,
        },
      });
      this.state.responseData.travelers.forEach((x) => {
        if (!this.idValid(x.id)) x.id = "";
      });

      this.crmService.putCaseInfo(this.state.responseData).then(
        (s) => {
          this.setState({
            ...this.state,
            crmData: s.data,
            responseData: s.data,
          });
          setTimeout(() => {
            this.setState({
              ...this.state,
              submittingInfo: false,
              showConfirmSuccessModal: true,
              hideDialog: false,
            });
          }, 2000);
        },
        (e) =>
          this.setState({
            ...this.state,
            submittingInfo: false,
            travelerPanel: {
              isOpen: false,
              openAsAdd: false,
              travelerAdded: false,
              autoTimerStart: false,
            },
            searchPanel: {
              isSearchOpen: false,
              openSearchAsAdd: false,
              travelerAdded2: false,
              autoTimerStart2: false,
            },
          })
      );
    }
    // if (this.state.responseData)
    //   this.setState({
    //     ...this.state,
    //     submitMessage:
    //     "Traveler info has been submitted. You will be redirected to the Global First Travel Home Page",

    //   }
    //   );
  };

  private removeTraveler(): void {
    var newState = { ...this.state };
    if (newState.responseData) {
      newState.responseData.travelers = newState.responseData?.travelers.filter(
        (x) => x.index !== this.state.selectedTraveler[0].index
      );
      newState.responseData?.travelers.forEach((x, i) => (x.index = i));
      this.setState(newState);
    }
  }
  private _getSelectionDetails(): PortalModels.TravelerProfiles[] {
    const selectionCount = this._selection.getSelectedCount();
    console.log(this._selection.getSelection());
    return (
      this.state.responseData?.travelers?.filter((x) =>
        this._selection
          .getSelection()
          .map((y) => y.key)
          .includes(x.id ? x.id : x.index)
      ) ?? []
    );
  }
  private renderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const rowStyles: Partial<IDetailsRowStyles> = {
      root: {
        // borderBottomColor: TravlerTableTheme.semanticColors.buttonBorder,
        fontSize: FontSizes.mediumPlus,
        width: "auto",
        minWidth: "30em",
        maxWidth: "40em",
      },
      cell: { paddingLeft: 0 },
    };
    if (!props) return null;
    return <DetailsRow {...props} styles={rowStyles} />;
  };
}
