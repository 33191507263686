import React, { useState } from 'react';
import { DatePicker, IDatePickerStrings, Stack, StackItem, addDays } from '@fluentui/react';

interface FlightDateSelectorProps {
  onSelectDepartureDate: (selectedDate: Date | null | undefined) => void;
  value: any;
}

const OneWayDateSelector: React.FC<FlightDateSelectorProps> = ({
  onSelectDepartureDate
}) => {
  const [departureDate, setDepartureDate] = useState<Date | null | undefined>(undefined);
  const today = new Date(Date.now());
  const maxDate = new Date(addDays(today, 330));
  const datePickerStrings: IDatePickerStrings = {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortDays: ['S', 'M', 'T', 'W', 'R', 'F', 'S'],
    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
  };

  const handleDepartureDateSelect = (date: Date | null | undefined) => {
    var utcDate = date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : undefined;
    setDepartureDate(utcDate);
    onSelectDepartureDate(utcDate);
  };

  const onFormatDate = (date?: any): string => {
    if (!date) {
      return "";
    } else {
      return (
        date?.getMonth() + 1 + "/" + date?.getDate() + "/" + date?.getFullYear()
      );
    }
  };


  return (
    <>
    <div>
    <Stack wrap horizontal enableScopedSelectors horizontalAlign="center">
        <StackItem style={{ padding: 20 }}>
        <DatePicker
            label="Departure Date"
            allowTextInput={true}
            openOnClick={false}
            maxDate={maxDate}
            onSelectDate={handleDepartureDateSelect}
            placeholder='Departure Date'
            strings={datePickerStrings}
            showGoToToday={false}
            showMonthPickerAsOverlay={true}
            onInvalid={undefined}
            value={departureDate? departureDate : new Date()}
            formatDate={onFormatDate}        
        />
        </StackItem>
    </Stack>
    </div>
    </>
  );
};

export default OneWayDateSelector;