import { IComboBoxOption } from "@fluentui/react";
import React from "react";

export const CountryOptions: IComboBoxOption[] = [
    { key:'US', text:'United States'},
    { key:'AD', text:'Andorra'}, 
    { key:'AE', text:'United Arab Emirates'},
    { key:'AF', text:'Afghanistan'},
    { key:'AG', text:'Antigua and Barbuda'},
    { key:'AI', text:'Anguilla'},
    { key:'AL', text:'Albania'},
    { key:'AM', text:'Armenia'},
    { key:'AO', text:'Angola'},
    { key:'AQ', text:'Antarctica'},
    { key:'AR', text:'Argentina'},
    { key:'AS', text:'American Samoa'},
    { key:'AT', text:'Austria'},
    { key:'AU', text:'Australia'},
    { key:'AW', text:'Aruba'},
    { key:'AX', text:'Aland Islands'},
    { key:'AZ', text:'Azerbaijan'},
    { key:'BA', text:'Bosnia and Herzegovina'},
    { key:'BB', text:'Barbados'},
    { key:'BD', text:'Bangladesh'},
    { key:'BE', text:'Belgium'},
    { key:'BF', text:'Burkina Faso'},
    { key:'BG', text:'Bulgaria'},
    { key:'BH', text:'Bahrain'},
    { key:'BI', text:'Burundi'},
    { key:'BJ', text:'Benin'},
    { key:'BL', text:'Saint Barthelemy'},
    { key:'BM', text:'Bermuda'},
    { key:'BN', text:'Brunei'},
    { key:'BO', text:'Bolivia'},
    { key:'BQ', text:'Bonaire, Saint Eustatius and Saba '},
    { key:'BR', text:'Brazil'},
    { key:'BS', text:'Bahamas'},
    { key:'BT', text:'Bhutan'},
    { key:'BV', text:'Bouvet Island'},
    { key:'BW', text:'Botswana'},
    { key:'BY', text:'Belarus'},
    { key:'BZ', text:'Belize'},
    { key:'CA', text:'Canada'},
    { key:'CC', text:'Cocos Islands'},
    { key:'CD', text:'Democratic Republic of the Congo'},
    { key:'CF', text:'Central African Republic'},
    { key:'CG', text:'Republic of the Congo'},
    { key:'CH', text:'Switzerland'},
    { key:'CI', text:'Ivory Coast'},
    { key:'CK', text:'Cook Islands'},
    { key:'CL', text:'Chile'},
    { key:'CM', text:'Cameroon'},
    { key:'CN', text:'China'},
    { key:'CO', text:'Colombia'},
    { key:'CR', text:'Costa Rica'},
    { key:'CU', text:'Cuba'},
    { key:'CV', text:'Cabo Verde'},
    { key:'CW', text:'Curacao'},
    { key:'CX', text:'Christmas Island'},
    { key:'CY', text:'Cyprus'},
    { key:'CZ', text:'Czechia'},
    { key:'DE', text:'Germany'},
    { key:'DJ', text:'Djibouti'},
    { key:'DK', text:'Denmark'},
    { key:'DM', text:'Dominica'},
    { key:'DO', text:'Dominican Republic'},
    { key:'DZ', text:'Algeria'},
    { key:'EC', text:'Ecuador'},
    { key:'EE', text:'Estonia'},
    { key:'EG', text:'Egypt'},
    { key:'EH', text:'Western Sahara'},
    { key:'ER', text:'Eritrea'},
    { key:'ES', text:'Spain'},
    { key:'ET', text:'Ethiopia'},
    { key:'FI', text:'Finland'},
    { key:'FJ', text:'Fiji'},
    { key:'FK', text:'Falkland Islands'},
    { key:'FM', text:'Micronesia'},
    { key:'FO', text:'Faroe Islands'},
    { key:'FR', text:'France'},
    { key:'GA', text:'Gabon'},
    { key:'GB', text:'United Kingdom'},
    { key:'GD', text:'Grenada'},
    { key:'GE', text:'Georgia'},
    { key:'GF', text:'French Guiana'},
    { key:'GG', text:'Guernsey'},
    { key:'GH', text:'Ghana'},
    { key:'GI', text:'Gibraltar'},
    { key:'GL', text:'Greenland'},
    { key:'GM', text:'Gambia'},
    { key:'GN', text:'Guinea'},
    { key:'GP', text:'Guadeloupe'},
    { key:'GQ', text:'Equatorial Guinea'},
    { key:'GR', text:'Greece'},
    { key:'GS', text:'South Georgia and the South Sandwich Islands'},
    { key:'GT', text:'Guatemala'},
    { key:'GU', text:'Guam'},
    { key:'GW', text:'Guinea-Bissau'},
    { key:'GY', text:'Guyana'},
    { key:'HK', text:'Hong Kong'},
    { key:'HM', text:'Heard Island and McDonald Islands'},
    { key:'HN', text:'Honduras'},
    { key:'HR', text:'Croatia'},
    { key:'HT', text:'Haiti'},
    { key:'HU', text:'Hungary'},
    { key:'ID', text:'Indonesia'},
    { key:'IE', text:'Ireland'},
    { key:'IL', text:'Israel'},
    { key:'IM', text:'Isle of Man'},
    { key:'IN', text:'India'},
    { key:'IO', text:'British Indian Ocean Territory'},
    { key:'IQ', text:'Iraq'},
    { key:'IR', text:'Iran'},
    { key:'IS', text:'Iceland'},
    { key:'IT', text:'Italy'},
    { key:'JE', text:'Jersey'},
    { key:'JM', text:'Jamaica'},
    { key:'JO', text:'Jordan'},
    { key:'JP', text:'Japan'},
    { key:'KE', text:'Kenya'},
    { key:'KG', text:'Kyrgyzstan'},
    { key:'KH', text:'Cambodia'},
    { key:'KI', text:'Kiribati'},
    { key:'KM', text:'Comoros'},
    { key:'KN', text:'Saint Kitts and Nevis'},
    { key:'KP', text:'North Korea'},
    { key:'KR', text:'South Korea'},
    { key:'XK', text:'Kosovo'},
    { key:'KW', text:'Kuwait'},
    { key:'KY', text:'Cayman Islands'},
    { key:'KZ', text:'Kazakhstan'},
    { key:'LA', text:'Laos'},
    { key:'LB', text:'Lebanon'},
    { key:'LC', text:'Saint Lucia'},
    { key:'LI', text:'Liechtenstein'},
    { key:'LK', text:'Sri Lanka'},
    { key:'LR', text:'Liberia'},
    { key:'LS', text:'Lesotho'},
    { key:'LT', text:'Lithuania'},
    { key:'LU', text:'Luxembourg'},
    { key:'LV', text:'Latvia'},
    { key:'LY', text:'Libya'},
    { key:'MA', text:'Morocco'},
    { key:'MC', text:'Monaco'},
    { key:'MD', text:'Moldova'},
    { key:'ME', text:'Montenegro'},
    { key:'MF', text:'Saint Martin'},
    { key:'MG', text:'Madagascar'},
    { key:'MH', text:'Marshall Islands'},
    { key:'MK', text:'North Macedonia'},
    { key:'ML', text:'Mali'},
    { key:'MM', text:'Myanmar'},
    { key:'MN', text:'Mongolia'},
    { key:'MO', text:'Macao'},
    { key:'MP', text:'Northern Mariana Islands'},
    { key:'MQ', text:'Martinique'},
    { key:'MR', text:'Mauritania'},
    { key:'MS', text:'Montserrat'},
    { key:'MT', text:'Malta'},
    { key:'MU', text:'Mauritius'},
    { key:'MV', text:'Maldives'},
    { key:'MW', text:'Malawi'},
    { key:'MX', text:'Mexico'},
    { key:'MY', text:'Malaysia'},
    { key:'MZ', text:'Mozambique'},
    { key:'NA', text:'Namibia'},
    { key:'NC', text:'New Caledonia'},
    { key:'NE', text:'Niger'},
    { key:'NF', text:'Norfolk Island'},
    { key:'NG', text:'Nigeria'},
    { key:'NI', text:'Nicaragua'},
    { key:'NL', text:'Netherlands'},
    { key:'NO', text:'Norway'},
    { key:'NP', text:'Nepal'},
    { key:'NR', text:'Nauru'},
    { key:'NU', text:'Niue'},
    { key:'NZ', text:'New Zealand'},
    { key:'OM', text:'Oman'},
    { key:'PA', text:'Panama'},
    { key:'PE', text:'Peru'},
    { key:'PF', text:'French Polynesia'},
    { key:'PG', text:'Papua New Guinea'},
    { key:'PH', text:'Philippines'},
    { key:'PK', text:'Pakistan'},
    { key:'PL', text:'Poland'},
    { key:'PM', text:'Saint Pierre and Miquelon'},
    { key:'PN', text:'Pitcairn'},
    { key:'PR', text:'Puerto Rico'},
    { key:'PS', text:'Palestinian Territory'},
    { key:'PT', text:'Portugal'},
    { key:'PW', text:'Palau'},
    { key:'PY', text:'Paraguay'},
    { key:'QA', text:'Qatar'},
    { key:'RE', text:'Reunion'},
    { key:'RO', text:'Romania'},
    { key:'RS', text:'Serbia'},
    { key:'RU', text:'Russia'},
    { key:'RW', text:'Rwanda'},
    { key:'SA', text:'Saudi Arabia'},
    { key:'SB', text:'Solomon Islands'},
    { key:'SC', text:'Seychelles'},
    { key:'SD', text:'Sudan'},
    { key:'SS', text:'South Sudan'},
    { key:'SE', text:'Sweden'},
    { key:'SG', text:'Singapore'},
    { key:'SH', text:'Saint Helena'},
    { key:'SI', text:'Slovenia'},
    { key:'SJ', text:'Svalbard and Jan Mayen'},
    { key:'SK', text:'Slovakia'},
    { key:'SL', text:'Sierra Leone'},
    { key:'SM', text:'San Marino'},
    { key:'SN', text:'Senegal'},
    { key:'SO', text:'Somalia'},
    { key:'SR', text:'Suriname'},
    { key:'ST', text:'Sao Tome and Principe'},
    { key:'SV', text:'El Salvador'},
    { key:'SX', text:'Sint Maarten'},
    { key:'SY', text:'Syria'},
    { key:'SZ', text:'Eswatini'},
    { key:'TC', text:'Turks and Caicos Islands'},
    { key:'TD', text:'Chad'},
    { key:'TF', text:'French Southern Territories'},
    { key:'TG', text:'Togo'},
    { key:'TH', text:'Thailand'},
    { key:'TJ', text:'Tajikistan'},
    { key:'TK', text:'Tokelau'},
    { key:'TL', text:'Timor Leste'},
    { key:'TM', text:'Turkmenistan'},
    { key:'TN', text:'Tunisia'},
    { key:'TO', text:'Tonga'},
    { key:'TR', text:'Turkey'},
    { key:'TT', text:'Trinidad and Tobago'},
    { key:'TV', text:'Tuvalu'},
    { key:'TW', text:'Taiwan'},
    { key:'TZ', text:'Tanzania'},
    { key:'UA', text:'Ukraine'},
    { key:'UG', text:'Uganda'},
    { key:'UM', text:'United States Minor Outlying Islands'},
    { key:'UY', text:'Uruguay'},
    { key:'UZ', text:'Uzbekistan'},
    { key:'VA', text:'Vatican'},
    { key:'VC', text:'Saint Vincent and the Grenadines'},
    { key:'VE', text:'Venezuela'},
    { key:'VG', text:'British Virgin Islands'},
    { key:'VI', text:'U.S. Virgin Islands'},
    { key:'VN', text:'Vietnam'},
    { key:'VU', text:'Vanuatu'},
    { key:'WF', text:'Wallis and Futuna'},
    { key:'WS', text:'Samoa'},
    { key:'YE', text:'Yemen'},
    { key:'YT', text:'Mayotte'},
    { key:'ZA', text:'South Africa'},
    { key:'ZM', text:'Zambia'},
    { key:'ZW', text:'Zimbabwe'},
    { key:'CS', text:'Serbia and Montenegro'},
    { key:'AN', text:'Netherlands Antilles'},
    
    
  ];

export const CountryISO3: IComboBoxOption[] = [
    { key:'US', text:'USA'},
    { key:'AD', text:'AND'}, 
    { key:'AE', text:'ARE'},
    { key:'AF', text:'AFG'},
    { key:'AG', text:'ATG'},
    { key:'AI', text:'AIA'},
    { key:'AL', text:'ALB'},
    { key:'AM', text:'ARM'},
    { key:'AO', text:'AGO'},
    { key:'AQ', text:'ATA'},
    { key:'AR', text:'ARG'},
    { key:'AS', text:'ASM'},
    { key:'AT', text:'AUT'},
    { key:'AU', text:'AUS'},
    { key:'AW', text:'ABW'},
    { key:'AX', text:'ALA'},
    { key:'AZ', text:'AZE'},
    { key:'BA', text:'BIH'},
    { key:'BB', text:'BRB'},
    { key:'BD', text:'BGD'},
    { key:'BE', text:'BEL'},
    { key:'BF', text:'BFA'},
    { key:'BG', text:'BGR'},
    { key:'BH', text:'BHR'},
    { key:'BI', text:'BDI'},
    { key:'BJ', text:'BEN'},
    { key:'BL', text:'BLM'},
    { key:'BM', text:'BMU'},
    { key:'BN', text:'BRN'},
    { key:'BO', text:'BOL'},
    { key:'BQ', text:'BES'},
    { key:'BR', text:'BRA'},
    { key:'BS', text:'BHS'},
    { key:'BT', text:'BTN'},
    { key:'BV', text:'BVT'},
    { key:'BW', text:'BWA'},
    { key:'BY', text:'BLR'},
    { key:'BZ', text:'BLZ'},
    { key:'CA', text:'CAN'},
    { key:'CC', text:'CCK'},
    { key:'CD', text:'COD'},
    { key:'CF', text:'CAF'},
    { key:'CG', text:'COG'},
    { key:'CH', text:'CHE'},
    { key:'CI', text:'CIV'},
    { key:'CK', text:'COK'},
    { key:'CL', text:'CHL'},
    { key:'CM', text:'CMR'},
    { key:'CN', text:'CHN'},
    { key:'CO', text:'COL'},
    { key:'CR', text:'CRI'},
    { key:'CU', text:'CUB'},
    { key:'CV', text:'CPV'},
    { key:'CW', text:'CUW'},
    { key:'CX', text:'CXR'},
    { key:'CY', text:'CYP'},
    { key:'CZ', text:'CZE'},
    { key:'DE', text:'DEU'},
    { key:'DJ', text:'DJI'},
    { key:'DK', text:'DNK'},
    { key:'DM', text:'DMA'},
    { key:'DO', text:'DOM'},
    { key:'DZ', text:'DZA'},
    { key:'EC', text:'ECU'},
    { key:'EE', text:'EST'},
    { key:'EG', text:'EGY'},
    { key:'EH', text:'ESH'},
    { key:'ER', text:'ERI'},
    { key:'ES', text:'ESP'},
    { key:'ET', text:'ETH'},
    { key:'FI', text:'FIN'},
    { key:'FJ', text:'FJI'},
    { key:'FK', text:'FLK'},
    { key:'FM', text:'FSM'},
    { key:'FO', text:'FRO'},
    { key:'FR', text:'FRA'},
    { key:'GA', text:'GAB'},
    { key:'GB', text:'GBR'},
    { key:'GD', text:'GRD'},
    { key:'GE', text:'GEO'},
    { key:'GF', text:'GUF'},
    { key:'GG', text:'GGY'},
    { key:'GH', text:'GHA'},
    { key:'GI', text:'GIB'},
    { key:'GL', text:'GRL'},
    { key:'GM', text:'GMB'},
    { key:'GN', text:'GIN'},
    { key:'GP', text:'GLP'},
    { key:'GQ', text:'GNQ'},
    { key:'GR', text:'GRC'},
    { key:'GS', text:'SGS'},
    { key:'GT', text:'GTM'},
    { key:'GU', text:'GUM'},
    { key:'GW', text:'GNB'},
    { key:'GY', text:'GUY'},
    { key:'HK', text:'HKG'},
    { key:'HM', text:'HMD'},
    { key:'HN', text:'HND'},
    { key:'HR', text:'HRV'},
    { key:'HT', text:'HTI'},
    { key:'HU', text:'HUN'},
    { key:'ID', text:'IDN'},
    { key:'IE', text:'IRL'},
    { key:'IL', text:'ISR'},
    { key:'IM', text:'IMN'},
    { key:'IN', text:'IND'},
    { key:'IO', text:'IOT'},
    { key:'IQ', text:'IRQ'},
    { key:'IR', text:'IRN'},
    { key:'IS', text:'ISL'},
    { key:'IT', text:'ITA'},
    { key:'JE', text:'JEY'},
    { key:'JM', text:'JAM'},
    { key:'JO', text:'JOR'},
    { key:'JP', text:'JPN'},
    { key:'KE', text:'KEN'},
    { key:'KG', text:'KGZ'},
    { key:'KH', text:'KHM'},
    { key:'KI', text:'KIR'},
    { key:'KM', text:'COM'},
    { key:'KN', text:'KNA'},
    { key:'KP', text:'PRK'},
    { key:'KR', text:'KOR'},
    { key:'XK', text:'XKX'},
    { key:'KW', text:'KWT'},
    { key:'KY', text:'CYM'},
    { key:'KZ', text:'KAZ'},
    { key:'LA', text:'LAO'},
    { key:'LB', text:'LBN'},
    { key:'LC', text:'LCA'},
    { key:'LI', text:'LIE'},
    { key:'LK', text:'LKA'},
    { key:'LR', text:'LBR'},
    { key:'LS', text:'LSO'},
    { key:'LT', text:'LTU'},
    { key:'LU', text:'LUX'},
    { key:'LV', text:'LVA'},
    { key:'LY', text:'LBY'},
    { key:'MA', text:'MAR'},
    { key:'MC', text:'MCO'},
    { key:'MD', text:'MDA'},
    { key:'ME', text:'MNE'},
    { key:'MF', text:'MAF'},
    { key:'MG', text:'MDG'},
    { key:'MH', text:'MHL'},
    { key:'MK', text:'MKD'},
    { key:'ML', text:'MLI'},
    { key:'MM', text:'MMR'},
    { key:'MN', text:'MNG'},
    { key:'MO', text:'MAC'},
    { key:'MP', text:'MNP'},
    { key:'MQ', text:'MTQ'},
    { key:'MR', text:'MRT'},
    { key:'MS', text:'MSR'},
    { key:'MT', text:'MLT'},
    { key:'MU', text:'MUS'},
    { key:'MV', text:'MDV'},
    { key:'MW', text:'MWI'},
    { key:'MX', text:'MEX'},
    { key:'MY', text:'MYS'},
    { key:'MZ', text:'MOZ'},
    { key:'NA', text:'NAM'},
    { key:'NC', text:'NCL'},
    { key:'NE', text:'NER'},
    { key:'NF', text:'NFK'},
    { key:'NG', text:'NGA'},
    { key:'NI', text:'NIC'},
    { key:'NL', text:'NLD'},
    { key:'NO', text:'NOR'},
    { key:'NP', text:'NPL'},
    { key:'NR', text:'NRU'},
    { key:'NU', text:'NIU'},
    { key:'NZ', text:'NZL'},
    { key:'OM', text:'OMN'},
    { key:'PA', text:'PAN'},
    { key:'PE', text:'PER'},
    { key:'PF', text:'PYF'},
    { key:'PG', text:'PNG'},
    { key:'PH', text:'PHL'},
    { key:'PK', text:'PAK'},
    { key:'PL', text:'POL'},
    { key:'PM', text:'SPM'},
    { key:'PN', text:'PCN'},
    { key:'PR', text:'PRI'},
    { key:'PS', text:'PSE'},
    { key:'PT', text:'PRT'},
    { key:'PW', text:'PLW'},
    { key:'PY', text:'PRY'},
    { key:'QA', text:'QAT'},
    { key:'RE', text:'REU'},
    { key:'RO', text:'ROU'},
    { key:'RS', text:'SRB'},
    { key:'RU', text:'RUS'},
    { key:'RW', text:'RWA'},
    { key:'SA', text:'SAU'},
    { key:'SB', text:'SLB'},
    { key:'SC', text:'SYC'},
    { key:'SD', text:'SDN'},
    { key:'SS', text:'SSD'},
    { key:'SE', text:'SWE'},
    { key:'SG', text:'SGP'},
    { key:'SH', text:'SHN'},
    { key:'SI', text:'SVN'},
    { key:'SJ', text:'SJM'},
    { key:'SK', text:'SVK'},
    { key:'SL', text:'SLE'},
    { key:'SM', text:'SMR'},
    { key:'SN', text:'SEN'},
    { key:'SO', text:'SOM'},
    { key:'SR', text:'SUR'},
    { key:'ST', text:'STP'},
    { key:'SV', text:'SLV'},
    { key:'SX', text:'SXM'},
    { key:'SY', text:'SYR'},
    { key:'SZ', text:'SWZ'},
    { key:'TC', text:'TCA'},
    { key:'TD', text:'TCD'},
    { key:'TF', text:'ATF'},
    { key:'TG', text:'TGO'},
    { key:'TH', text:'THA'},
    { key:'TJ', text:'TJK'},
    { key:'TK', text:'TKL'},
    { key:'TL', text:'TLS'},
    { key:'TM', text:'TKM'},
    { key:'TN', text:'TUN'},
    { key:'TO', text:'TON'},
    { key:'TR', text:'TUR'},
    { key:'TT', text:'TTO'},
    { key:'TV', text:'TUV'},
    { key:'TW', text:'TWN'},
    { key:'TZ', text:'TZA'},
    { key:'UA', text:'UKR'},
    { key:'UG', text:'UGA'},
    { key:'UM', text:'UMI'},
    { key:'UY', text:'URY'},
    { key:'UZ', text:'UZB'},
    { key:'VA', text:'VAT'},
    { key:'VC', text:'VCT'},
    { key:'VE', text:'VEN'},
    { key:'VG', text:'VGB'},
    { key:'VI', text:'VIR'},
    { key:'VN', text:'VNM'},
    { key:'VU', text:'VUT'},
    { key:'WF', text:'WLF'},
    { key:'WS', text:'WSM'},
    { key:'YE', text:'YEM'},
    { key:'YT', text:'MYT'},
    { key:'ZA', text:'ZAF'},
    { key:'ZM', text:'ZMB'},
    { key:'ZW', text:'ZWE'},
    { key:'CS', text:'SCG'},
    { key:'AN', text:'ANT'},

  ];
