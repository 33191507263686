import {
  addDays,
  addMonths,
  Checkbox,
  ComboBox,
  DatePicker,
  Dropdown,
  DropdownMenuItemType,
  IComboBox,
  IComboBoxOption,
  IComboBoxProps,
  IComboBoxStyles,
  IDatePicker,
  IDatePickerStyles,
  IDropdownOption,
  ILabelStyles,
  IStackStyles,
  IStackTokens,
  IStyleSet,
  Label,
  Pivot,
  PivotItem,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { Option, shorthands } from "@fluentui/react-components";

import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import "./Booking.css";
import { eAirSearchStatus, eSearchStatus } from "../../Contexts/Enums";
import axios, { AxiosResponse } from "axios";
import * as PortalModels from "../../Contexts/Models";
import { Combobox, ComboboxProps } from "@fluentui/react-components";
import FuzzyComboBox from "./fuzzyAirList";
import DateSelector from "./DateSelector";
import RoundWayDateSelector from "./RoundWayDate";

const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: {
    maxWidth: 200,
    color: "#b39e51"
  
  },
  input: {
    color: "#b39e51",
  },
  

};
const headingStackTokens: IStackTokens = { childrenGap: 30 };

const datePickerStyles: Partial<IDatePickerStyles> = {
  root: { maxWidth: 300, marginTop: 15 },
};

export interface IRoundWayProps {
  airportLocations: IComboBoxOption[];
  onTripInfoChange: (tripInfo: PortalModels.ShoppingRequest) => void;
  onSubmitSearch: any;
}

export interface IRoundTripProps {
  airportLocations: IComboBoxOption[];
}

export const RoundWaySearch = (
  props: PortalModels.ShoppingRequest & IRoundWayProps
) => {
  const [itinerary, setItinerary] = useState<PortalModels.ItineraryRequest[]>([
    {
      orig: "",
      dest: "",
      departureDate: "",
      departureWindow: null,
      maxConnections: null,
      departureTime: null,
      preferredConnections: null,
      classOfService: null,
    },
    {
      orig: "",
      dest: "",
      departureDate: "",
      departureWindow: null,
      maxConnections: null,
      departureTime: null,
      preferredConnections: null,
      classOfService: null,
    },
  ]);

  const [travCount, setTravCount] = useState<PortalModels.TravelerTypes>({
    aDT: 1,
    cHD: 0,
    iNF: 0,
  });

  // Function to update a specific itinerary
  const updateItinerary = (index: number, property: string, value: any) => {
    setItinerary((prevItinerary) => {
      const newItinerary = [...prevItinerary];
      newItinerary[index] = { ...newItinerary[index], [property]: value };
      return newItinerary;
    });
  };


  //handlers example
  // updateItinerary(0, 'departureDate', 'depart');
  // updateItinerary(1, 'departureDate', 'return');

  const handleItineraryRequestChange = (updatedItineraryRequest: PortalModels.ItineraryRequest[]) => {
    setItinerary(updatedItineraryRequest);
  };

  const handleTravelerCountsChange = (
    updatedTravelerCounts: PortalModels.TravelerTypes
  ) => {
    setTravCount(updatedTravelerCounts);
  };

  const handleOriginSelect = (event: any) => {
    console.log(`${event.target.name}: ${event.target.value}`);

    setItinerary((prevItinerary) => {
      const newItinerary = [...prevItinerary];
      newItinerary[0] = {
        ...newItinerary[0],
        orig: event.target.value.slice(0, 3),
      };
      return newItinerary;
    });
    setItinerary((prevItinerary) => {
      const newItinerary = [...prevItinerary];
      newItinerary[1] = {
        ...newItinerary[1],
        dest: event.target.value.slice(0, 3),
      };
      return newItinerary;
    });
  };

  const handleDestinationSelect = (event: any) => {
    console.log(`${event.target.name}: ${event.target.value}`);
    setItinerary((prevItinerary) => {
      const newItinerary = [...prevItinerary];
      newItinerary[0] = {
        ...newItinerary[0],
        dest: event.target.value.slice(0, 3),
      };
      return newItinerary;
    });
    setItinerary((prevItinerary) => {
      const newItinerary = [...prevItinerary];
      newItinerary[1] = {
        ...newItinerary[1],
        orig: event.target.value.slice(0, 3),
      };
      return newItinerary;
    });
  };

  useEffect(() => {
    const tripInfo: PortalModels.ShoppingRequest = {
      tripType: PortalModels.TripType.RoundTrip,
      preferredCarriers: {},
      itin: [...(props.itin ?? []), itinerary[0], itinerary[1]],
      travelers: travCount,
    };
    props.onTripInfoChange(tripInfo);
  }, [itinerary[0], itinerary[1], travCount, props.onTripInfoChange]);

  //Classofservice
  const [selectedClassOfService, setSelectedClassOfService] =
    useState<PortalModels.ClassOfService>({});

  

  const classOfServiceOptions: IComboBoxOption[] = Object.keys(
    PortalModels.PreferenceLevel
    ).map((key) => ({
    key,
    text: key,
  }));


  // Creates a new object with the selected key
  const handleClassOfServiceChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    index?: number | undefined,
    value?: string | undefined
  ) => {
    if (option) {
      const preferenceLevel =
        value === undefined
          ? PortalModels.PreferenceLevel.Only
          : PortalModels.PreferenceLevel.Preferred;

      const updatedClassOfService: PortalModels.ClassOfService = {
        ...selectedClassOfService,
        [option.key as keyof PortalModels.ClassOfService]: preferenceLevel,
      };

      setSelectedClassOfService(updatedClassOfService);
    } else if (option==undefined &&value==undefined){
      return setSelectedClassOfService
    }
  };

  //dates
  const today = new Date(Date.now());

  const handleSelectDepartureDate = (selectedDate: Date | null | undefined) => {
    console.log("Selected Departure Date:", selectedDate);
    let isoDate = selectedDate?.toISOString();

    let formatDate = isoDate?.substring(0, 10);

    updateItinerary(0, 'departureDate', formatDate);
    updateItinerary(0, 'departureTime', '17:00');
    updateItinerary(0, 'departureWindow', '00002359');
    // setItinerary({
    //   ...itinerary,
    //   departureDate: formatDate as string,
    //   departureTime: '17:00',
    //   departureWindow: '00002359'
    // });
  };

  const handleReturnDepartureDate = (selectedDate: Date | null | undefined) => {
    console.log("Selected Departure Date:", selectedDate);
    let isoDate = selectedDate?.toISOString();

    let formatDate = isoDate?.substring(0, 10);

    updateItinerary(1, 'departureDate', formatDate);
    updateItinerary(1, 'departureTime', '17:00');
    updateItinerary(1, 'departureWindow', '00002359');
    // setItinerary({
    //   ...itinerary,
    //   departureDate: formatDate as string,
    //   departureTime: '17:00',
    //   departureWindow: '00002359'
    // });
  };

  //maxConnections

  const maxConnections: IDropdownOption[] = [
    
    { key: 0, text: "Direct Only" },
    { key: 1, text: "1 Stop" },
    { key: 2, text: "2 Stops"},
    { key: 3, text: "3 Stops"},
    { key: 4, text: "4 Stops" },
    
  ];

  //airports
  const airports = (): IComboBoxOption[] => {
    return (
      props.airportLocations?.map((x) => {
        return { key: x.key as string, text: x.text as string };
      }) ?? []
    );
  };

  //passengers
  const PassengerSelector = () => {
    const totalTravelers = Object.values(travCount).reduce(
      (total, count) => total + (count ?? 0),
      0
    );

    return (
      <>
        <Stack style={{ padding: 5 }} horizontal>
          <PrimaryButton
            text="-"
            onClick={() => {
              //setTravCount({aDT:(travCount.aDT??1)-1,cHD:travCount.cHD??0,iNF:travCount.iNF??0})
              setTravCount({ ...travCount, aDT: (travCount.aDT ?? 0) - 1 });
            }}
            disabled={travCount.aDT == 0}
            style={{ height: 15, width: 15, minWidth: 15, maxWidth: 15 }}
          />
          &nbsp;Adult: {travCount.aDT} &nbsp;
          <PrimaryButton
            text="+"
            type="button"
            onClick={() =>
              setTravCount({ ...travCount, aDT: (travCount.aDT ?? 0) + 1 })
            }
            disabled={totalTravelers == 9}
            style={{
              height: 15,
              width: 10,
              minWidth: 10,
              maxWidth: 10,
              textAlign: "center",
              textAnchor: "middle",
            }}
          />
        </Stack>

        <Stack horizontal>
          <PrimaryButton
            text="-"
            type="button"
            onClick={() =>
              setTravCount({ ...travCount, cHD: (travCount.cHD ?? 0) - 1 })
            }
            disabled={travCount.cHD == 0}
            style={{ height: 15, width: 15, minWidth: 15, maxWidth: 15 }}
          />
          &nbsp;Children:{travCount.cHD} &nbsp;
          <PrimaryButton
            text="+"
            type="button"
            onClick={() =>
              setTravCount({ ...travCount, cHD: (travCount.cHD ?? 0) + 1 })
            }
            disabled={totalTravelers == 9}
            style={{ height: 15, width: 15, minWidth: 15, maxWidth: 15 }}
          />
        </Stack>

        <Stack style={{ padding: 5 }} horizontal>
          <PrimaryButton
            text="-"
            type="button"
            onClick={() =>
              setTravCount({ ...travCount, iNF: (travCount.iNF ?? 0) - 1 })
            }
            disabled={travCount.iNF == 0}
            style={{ height: 15, width: 15, minWidth: 15, maxWidth: 15 }}
          />
          &nbsp;Infants: {travCount.iNF} &nbsp;
          <PrimaryButton
            text="+"
            type="button"
            onClick={() =>
              setTravCount({ ...travCount, iNF: (travCount.iNF ?? 0) + 1 })
            }
            disabled={totalTravelers == 9}
            style={{ height: 15, width: 15, minWidth: 15, maxWidth: 15 }}
          />
        </Stack>

        <Stack>Total Passengers = {totalTravelers}</Stack>
      </>
    );
  };

  return (
    <>
      <Stack verticalAlign="center">
        <Stack horizontalAlign="center">
          <PassengerSelector />
        </Stack>
        <Stack wrap horizontal enableScopedSelectors horizontalAlign="center">
          <StackItem style={{ padding: 20 }}>
            {/* <hr style={{ height: 0.5, visibility: "hidden" }} /> */}
            <Label>Origin</Label>
            <FuzzyComboBox
              valueProps={itinerary[0].orig}
              onSelect={handleOriginSelect}
              airports={airports()}
              label="Origin"
            />
          </StackItem>
          <StackItem style={{ padding: 20 }}>
            {/* <hr style={{ height: 0.5, visibility: "hidden" }} /> */}
            <Label>Destination</Label>
            <FuzzyComboBox
              valueProps={itinerary[0].dest}
              airports={airports()}
              onSelect={handleDestinationSelect}
              label="Destination"
            />
          </StackItem>

          <StackItem style={{ padding: 20 }}>
          <Label>Departure Date</Label>
          <RoundWayDateSelector
            placeHolder={'Departure Date'}
            onSelectDepartureDate={handleSelectDepartureDate}
            value={itinerary[0].departureDate}
          />
        </StackItem>
        <StackItem style={{ padding: 20 }}>
        <Label>Return Date</Label>
          <RoundWayDateSelector
            placeHolder={'Return Date'}
            onSelectDepartureDate={handleReturnDepartureDate}
            value={itinerary[1].departureDate}
          />
        </StackItem>
        <Stack wrap horizontal enableScopedSelectors horizontalAlign="center">
          <StackItem style={{ padding: 20 }}>
            <ComboBox
              multiSelect
              label="Select Class of Service"
              allowFreeform={false}
              styles={comboBoxStyles}
              selectedKey={Object.keys(selectedClassOfService)}
              options={classOfServiceOptions}
              onChange={handleClassOfServiceChange}
            />
          </StackItem>
          
          <StackItem style={{ padding: 20 }}>
            
            <Dropdown
              label="Max Connections"
              options={maxConnections}
              styles={{dropdown:{width:150}}}
              onChange={(e, v) =>{
                console.log(`${v?.key} : maxconnect`)
                setItinerary((prevItinerary) => {
                  const newItinerary = [...prevItinerary];
                  newItinerary[0] = {
                    ...newItinerary[0],
                    maxConnections: v?.key as number,
                  };
                  return newItinerary;
                });
                setItinerary((prevItinerary) => {
                  const newItinerary = [...prevItinerary];
                  newItinerary[1] = {
                    ...newItinerary[1],
                    maxConnections: v?.key as number,
                  };
                  return newItinerary;
                });
              }}
              
            />
            </StackItem>
            <StackItem>


            </StackItem>



          </Stack>
        </Stack>
        <Stack horizontal horizontalAlign="center">
          <PrimaryButton
            text="Search"
            disabled={travCount.aDT==0 || itinerary[0].departureDate== null || itinerary[1].orig=="".trim()|| itinerary[0].dest=="".trim()}
            onClick={() => {
              console.log("round search button clicked");
              // handleItineraryRequestChange(itinerary);
              handleItineraryRequestChange(itinerary);
              handleTravelerCountsChange(travCount);
              props.onSubmitSearch();

              // props.onSubmitSearch();
            }}
            styles={{ root: { width: 200 } }}
          />
        </Stack>

        {/* <Stack horizontalAlign="center">
          <StackItem style={{ padding: 20 }}>
            <a
              className="more-link"
              style={{
                color: "#E3E3E3",
                cursor: "pointer",
                textDecorationLine: "underline",
              }}
              onClick={() => setMoarOptions(!moaroptions)}
            >
              {" "}
              {linkName}
            </a>{" "}
            {moaroptions && extraSettings}
          </StackItem>
        </Stack> */}
      </Stack>
    </>
  );
};
