import React from 'react';
import { Stack, Text, Link, FontWeights, IStackTokens, Label } from '@fluentui/react';
import '../App.scss';

export interface IInvalidPathComponentProps {
  displayText:string;
  waitTime?:number;
}
export interface IInvalidPathComponentState extends React.ComponentState, IInvalidPathComponentProps {

}
export class InvalidPathComponent extends React.Component<IInvalidPathComponentProps,IInvalidPathComponentState> {

  constructor(props:IInvalidPathComponentProps){
    super(props);
    this.state = {...props};
  }
  public render():JSX.Element{
  return (
        <Label className="primary-text">{this.state.displayText}</Label>
      );
    }
    public componentDidMount = (): void => {
        if(!this.state.waitTime) {
            window?.top?.location.replace("https://www.globalfirsttravel.com/");
        }
        else {
            setTimeout(()=>{window?.top?.location.replace("https://www.globalfirsttravel.com/")},this.state.waitTime);
        }
    }
};
